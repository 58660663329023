import { fetchIngredients } from 'src/api/rest/ingredient'
import { fetchProducts } from 'src/api/rest/product'
import { fetchSets } from 'src/api/rest/set'
import { notifyError } from 'src/notification'

const SET_PRODUCTS = 'product/SET_PRODUCTS'
const APPEND_PRODUCT = 'product/APPEND_PRODUCT'
const DELETE_PRODUCT = 'product/DELETE_PRODUCT'
const UPDATE_PRODUCT = 'product/UPDATE_PRODUCT'

const SET_SETS = 'sets/SET_SETS'
const APPEND_SET = 'sets/APPEND_SET'
const UPDATE_SET = 'sets/UPDATE_SET'
const DELETE_SET = 'sets/DELETE_SET'

const APPEND_INGREDIENT = 'ingredient/APPEND_INGREDIENT'
const SET_INGREDIENTS = 'ingredient/SET_INGREDIENTS'
const DELETE_INGREDIENT = 'ingredient/DELETE_INGREDIENT'
const UPDATE_INGREDIENT = 'ingredient/UPDATE_INGREDIENT'

const SET_FETCHING_MENU = 'ingredient/SET_FETCHING_MENU'

const initState = {
  ingredients: [],
  products: [],
  sets: [],
  isFetchingMenu: false,
}

export default function (state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SET_FETCHING_MENU]: () => ({ ...state, isFetchingMenu: payload }),
    //Ingredients
    [SET_INGREDIENTS]: () => ({
      ...state,
      ingredients: payload,
    }),

    [APPEND_INGREDIENT]: () => ({
      ...state,
      ingredients: [...state.ingredients, payload],
    }),

    [UPDATE_INGREDIENT]: () => ({
      ...state,
      ingredients: updateItem(state.ingredients, payload),
    }),

    [DELETE_INGREDIENT]: () => ({
      ...state,
      ingredients: removeItem(state.ingredients, payload),
    }),

    //products
    [SET_PRODUCTS]: () => ({
      ...state,
      products: payload,
    }),
    [APPEND_PRODUCT]: () => ({
      ...state,
      products: [...state.products, payload],
    }),
    [DELETE_PRODUCT]: () => ({
      ...state,
      products: removeItem(state.products, payload),
    }),
    [UPDATE_PRODUCT]: () => ({
      ...state,
      products: updateItem(state.products, payload),
    }),

    //Sets
    [SET_SETS]: () => ({
      ...state,
      sets: payload,
    }),
    [APPEND_SET]: () => ({
      ...state,
      sets: [...state.sets, payload],
    }),
    [UPDATE_SET]: () => ({
      ...state,
      sets: updateItem(state.sets, payload),
    }),
    [DELETE_SET]: () => ({
      ...state,
      sets: removeItem(state.sets, payload),
    }),
  }

  return cases[type] ? cases[type]() : state
}

//Ingredients
export const setIngredients = (ingredients) => ({
  type: SET_INGREDIENTS,
  payload: ingredients,
})

export const appendIngredient = (ingredient) => ({
  type: APPEND_INGREDIENT,
  payload: ingredient,
})

export const updateIngredient = (ingredient) => ({
  type: UPDATE_INGREDIENT,
  payload: ingredient,
})

export const removeIngredient = (id) => ({
  type: DELETE_INGREDIENT,
  payload: id,
})

export function getIngredients() {
  return async (dispatch) => {
    try {
      const ingredients = await fetchIngredients()
      dispatch(setIngredients(ingredients))
    } catch (error) {
      notifyError(
        'Error fetching ingredients',
        'Error fetching ingredients',
        error,
      )
    }
  }
}

//Products
export const setProducts = (products) => ({
  type: SET_PRODUCTS,
  payload: products,
})
export const appendProduct = (product) => ({
  type: APPEND_PRODUCT,
  payload: product,
})
export const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product,
})
export const removeProduct = (id) => ({
  type: DELETE_PRODUCT,
  payload: id,
})

export function getProducts() {
  return async (dispatch) => {
    try {
      const products = await fetchProducts()
      dispatch(setProducts(products))
    } catch (error) {
      notifyError('Error fetching products', 'Error fetching products', error)
    }
  }
}

//Sets
export const setSets = (sets) => ({ type: SET_SETS, payload: sets })
export const appendSet = (set) => ({ type: APPEND_SET, payload: set })
export const updateSet = (set) => ({ type: UPDATE_SET, payload: set })
export const removeSet = (id) => ({ type: DELETE_SET, payload: id })

export function getSets() {
  return async (dispatch) => {
    try {
      const sets = await fetchSets()
      dispatch(setSets(sets))
    } catch (error) {
      notifyError('Error fetching sets', 'Error fetching sets', error)
    }
  }
}

export const setIsFetchingMenu = (boolean) => ({
  type: SET_FETCHING_MENU,
  payload: boolean,
})

// helper functions
const updateItem = (list, item) => {
  return list.map((element) => (element.id === item.id ? item : element))
}

function removeItem(list, id) {
  return list.filter((element) => element.id !== id)
}
