import { faRedoAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Checkbox, Icon, Input, List, Table } from "semantic-ui-react"
import { LoadingIcon } from "src/components"
import orderStatus from "src/constants/types/orderStatus"
import { notifyError } from "src/notification"
import { getRowClassName, getStatus } from "src/pages/Home/Helpers/index"
import { editShopOrder } from "src/redux/order/actions"
import { getShopOrders } from "src/redux/shopOrders/actions"
import formatDate from "src/utils/formatDate"
import classes from "./ShopOrders.module.scss"

export default function ShopOrder({ showOrderWindow }) {
  const dispatch = useDispatch()
  const { reservation } = useSelector((state) => state.order.userData)
  const { canceledOrders, waitingOrders, unconfirmedOrders, isLoading } =
    useSelector((state) => state.shop)
  const [sort, setSort] = useState(0)
  const [canceledStatus, setCanceledStatus] = useState(false)
  const [toPayStatus, setToPayStatus] = useState(true)
  const [displayedOrdersStatus, setDisplayedOrdersStatus] =
    useState(waitingOrders)
  const [search, setSearch] = useState("")

  function onEdit(order) {
    if (reservation.isReserved) {
      notifyError("Please unreserved", "Zwolnij blok czasowy")
      return
    }
    dispatch(editShopOrder(order))
    showOrderWindow()
  }

  function onRefresh() {
    dispatch(getShopOrders())
  }

  if (isLoading) {
    return <LoadingIcon />
  }

  function getTotalShopOrders() {
    const total = unconfirmedOrders.reduce((total, order) => {
      if (order.status[0].status === orderStatus.waitingForConfirmation.EN)
        total++
      return total
    }, 0)

    return total > 0 ? total : undefined
  }

  function changeDisplayedList() {
    setToPayStatus(!toPayStatus)
    setCanceledStatus(!canceledStatus)
    !toPayStatus
      ? setDisplayedOrdersStatus(waitingOrders)
      : setDisplayedOrdersStatus(canceledOrders)
  }

  function onLocalSearch(search) {
    setSearch(search)
  }

  function parseDate(dateString) {
    if (!dateString) return new Date(0)
    const [day, month, year] = dateString.split("/").map(Number)
    return new Date(year, month - 1, day)
  }

  function sortOrders(a, b) {
    const dateA =
      sort > 0 ? parseDate(a.indicativeDate) : parseDate(b.indicativeDate)
    const dateB =
      sort > 0 ? parseDate(b.indicativeDate) : parseDate(a.indicativeDate)

    if (dateA < dateB) {
      return 1
    } else if (dateA > dateB) {
      return -1
    } else if (a.createdAt < b.createdAt) {
      return 1
    } else if (a.createdAt > b.createdAt) {
      return -1
    } else {
      return 0
    }
  }

  return (
    <div className={classes.Container}>
      <div className={classes.HeaderWrapper}>
        <h3>Online do potwierdzenia</h3>
        <div>
          <h3>Liczba zamówień: {getTotalShopOrders()}</h3>
          <FontAwesomeIcon
            size={"lg"}
            icon={faRedoAlt}
            onClick={onRefresh}
            className={classes.RefreshIcon}
          />
        </div>
      </div>

      <Table fixed className={classes.Table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={classes.IdItem}>ID </Table.HeaderCell>
            <Table.HeaderCell>Klient</Table.HeaderCell>
            <Table.HeaderCell>Telefon</Table.HeaderCell>
            <Table.HeaderCell>Kwota</Table.HeaderCell>
            <Table.HeaderCell
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSort(1 - sort)
              }}
            >
              <Icon name={`sort amount ${sort > 0 ? "up" : "down"}`} />
              Data
            </Table.HeaderCell>
            <Table.HeaderCell>Akcje</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {unconfirmedOrders &&
            unconfirmedOrders.sort(sortOrders).map((order) => (
              <Table.Row key={order.id}>
                <Table.Cell className={classes.IdItem}>{order.id}</Table.Cell>
                <Table.Cell>{order.clientName}</Table.Cell>

                <Table.Cell>{order.clientPhone}</Table.Cell>

                <Table.Cell>{order.price}zł</Table.Cell>

                <Table.Cell>{order.indicativeDate}</Table.Cell>

                <Table.Cell>
                  <Icon
                    onClick={() => onEdit(order)}
                    className={classes.ActionIcon}
                    name="edit"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      <h3>Pozostałe zamówienia online</h3>

      <Input
        fluid
        icon="users"
        iconPosition="left"
        placeholder="Szukaj..."
        size="small"
        value={search}
        onChange={(e, { value }) => onLocalSearch(value)}
      />

      <div className={classes.CheckboxContainer}>
        <List.Item>
          <Checkbox
            label="Oczekiwanie na płatność"
            onClick={() => changeDisplayedList()}
            checked={toPayStatus}
          />
        </List.Item>
        <List.Item>
          <Checkbox
            label="Anulowane"
            onClick={() => changeDisplayedList()}
            checked={canceledStatus}
          />
        </List.Item>
      </div>
      <Table fixed className={classes.Table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={classes.IdItem}>ID </Table.HeaderCell>
            <Table.HeaderCell>Klient</Table.HeaderCell>
            <Table.HeaderCell>Telefon</Table.HeaderCell>
            <Table.HeaderCell>Kwota</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Akcje</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {displayedOrdersStatus &&
            displayedOrdersStatus
              .filter((item) => {
                if (
                  formatDate(new Date(item.BWT.date))
                    .toLowerCase()
                    .includes(search) ||
                  item.id.toLowerCase().includes(search) ||
                  item.clientName.includes(search)
                )
                  return true
              })
              .map((order) => (
                <Table.Row
                  key={order.id}
                  className={getRowClassName(order.status, order.id)}
                >
                  <Table.Cell className={classes.IdItem}>{order.id}</Table.Cell>
                  <Table.Cell>{order.clientName}</Table.Cell>
                  <Table.Cell>{order.clientPhone}</Table.Cell>
                  <Table.Cell>{order.price}zł</Table.Cell>
                  <Table.Cell>{getStatus(order.status)}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      onClick={() => onEdit(order)}
                      className={classes.ActionIcon}
                      name="edit"
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
        </Table.Body>
      </Table>
    </div>
  )
}
