export default {
  new: { EN: 'new', PL: 'Nowe' },
  paid: { EN: 'paid', PL: 'Opłacone' },
  canceled: { EN: 'canceled', PL: 'Anulowane' },
  confirmed: { EN: 'confirmed', PL: 'Potwierdzone' },
  waitingForPayment: {
    EN: 'waiting for payment',
    PL: 'Oczekiwanie na płatność',
  },
  waitingForConfirmation: {
    EN: 'new-online',
    PL: 'Oczekiwanie na potwierdzenie',
  },
}
