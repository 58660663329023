//@ts-check
import { API } from 'aws-amplify'
import api, { apiName } from './constants'

export async function postStorage(store) {
  try {
    let request = {
      body: {
        start: store.start,
        stop: store.stop,
        location: store.location,
      },
    }
    return await API.post(apiName, api.storage, request)
  } catch (error) {
    throw error
  }
}
