// @ts-check
import productCategory from "src/constants/types/product"
import ingredientCategory from "src/constants/types/ingredient"

export const __prod__ = process.env.REACT_APP_ENV === "prod"
export * from "./types"
export * from "./views"

export const zones = {
  zoneA: "Zone A",
  zoneB: "Zone B",
  pickup: "Pickup",
}

export const restaurantAddress = {
  restaurant: "Kolejowa 45, Warszawa",
}

export const orderModes = {
  add: "ADD",
  edit: "EDIT",
  confirm: "CONFIRM",
}

export const orderTypes = {
  shop: "shop",
  dysza: "dysza",
}

export const ingredientDropdown = [
  { value: ingredientCategory.ingredient, text: "Składniki" },
  { value: ingredientCategory.beverages, text: "Napoje" },
  { value: ingredientCategory.alcohol, text: "Alkohole" },
  { value: ingredientCategory.addons, text: "Dodatki" },
]

export const productDropdown = [
  { value: productCategory.sushi, text: "Sushi" },
  { value: productCategory.przystawki, text: "Przystawki" },
  { value: productCategory.sashimi, text: "Sashimi" },
  { value: productCategory.sosy, text: "Sosy" },
  { value: productCategory.dodatki, text: "Dodatki" },
  { value: productCategory.desery, text: "Desery" },
  { value: productCategory.daniaCieple, text: "Dania ciepłe" },
  { value: productCategory.napojeAlkoholowe, text: "Napoje alkoholowe" },
  { value: productCategory.napojeBezalkoholowe, text: "Napoje bezalkoholowe" },
  { value: productCategory.nigiri, text: "Nigiri" },
  { value: productCategory.polprodukty, text: "Półprodukty" },
  { value: productCategory.zupy, text: "Zupy" },
  { value: productCategory.gratisy, text: "Gratisy" },
]

export const productType = [
  { value: "Ciepłe", text: "Ciepłe" },
  { value: "Zimne", text: "Zimne" },
]
export const boxType = [
  { value: "S", label: "Mały zestaw pakujący" },
  { value: "M", label: "Średni zestaw pakujący" },
  { value: "L", label: "Duży zestaw pakujący" },
  { value: "B", label: "Brak" },
  { value: "N", label: "Niestandardowy" },
]
