import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { removeItem as removeOrderRedux } from 'src/redux/order/reducer'

import classes from './OrderCard.module.scss'

export default function OrderCard({ name, price, index }) {
  const dispatch = useDispatch()

  function removeItem() {
    const elem = document.getElementById(`Order-card${index}`)
    elem.classList.toggle(classes.RemoveItem)

    //Settime out same length as the animation slideRight
    setTimeout(() => {
      dispatch(removeOrderRedux(index))
      elem.classList.toggle(classes.RemoveItem)
    }, 700)
  }

  return (
    <Row id={'Order-card' + index} className={classes.OrderSummaryItem}>
      <Col xs={8}>{name}</Col>
      <Col xs={3} className={classes.AlignCenter}>
        {price} PLN
      </Col>
      <Col xs={1} className={classes.AlignCenter}>
        <FontAwesomeIcon
          onClick={removeItem}
          className={classes.DeleteCross}
          icon={faTimes}
          size="1x"
        />
      </Col>
    </Row>
  )
}
