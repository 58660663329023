import Joi from '@hapi/joi'

const schema = Joi.object({
  nip: Joi.string().allow(''),
  reservation: Joi.object({
    date: Joi.string().required(),
    hour: Joi.number().required(),
    location: Joi.string().required(),
    numberOfBlocks: Joi.number().min(1).required(),
    isEditable: Joi.bool(),
    isReserved: Joi.bool(),
  }).required(),
  clientComment: Joi.string().allow(''),
  productionComment: Joi.string().allow(''),
  name: Joi.string().required().allow(''),
  phone: Joi.string().required(),
  message: Joi.string().allow(''),
  address: Joi.string().required(),
  paymentType: Joi.string().required(),
  isPriority: Joi.boolean().required(),
  deliveryType: Joi.string().required(),
  email: Joi.string().allow(''),
  price: Joi.number(),
})

export default schema
