import React, { useState, useEffect } from 'react'
import Card from './Card'
import AddUserModal from './Add'
import DeleteUserModal from './Delete'
import { LoadingIcon } from 'src/components'
import { Button, Breadcrumb } from 'semantic-ui-react'
import styles from '../SpecialOffer/Breadcrumb.module.scss'
import {
  deleteUser,
  getUsersList,
  addUserToAdminGroup,
  removeUserFromAdminGroup,
} from 'src/api/rest/users'
import { notifyError, notifySuccess } from 'src/notification'

export default function ({ setActiveMenu }) {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [addModalIsActive, setAddModalIsActive] = useState(false)
  const [deletingUser, setDeletingUser] = useState(undefined)
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false)

  useEffect(() => {
    fetchUsersList()
  }, [])

  async function fetchUsersList() {
    setIsLoading(true)
    try {
      const userList = await getUsersList()
      setUsers(userList)
    } catch (error) {
      notifyError(
        'Error fetching user list',
        'Coś poszło nie tak podczas pobierania listy użytkowników.',
        error,
      )
    }
    setIsLoading(false)
  }

  async function onDeleteUser() {
    try {
      await deleteUser(deletingUser)
      notifySuccess('Użytkownik został usunięty.')
      const userList = await getUsersList()
      setDeleteModalIsActive(false)
      setUsers(userList)
    } catch (error) {
      notifyError(
        'Error deleting user',
        'Coś poszło nie tak podczas usuwania użytkownika.',
        error,
      )
    }
  }

  async function onChangeRole(username, setAdmin) {
    try {
      setAdmin
        ? await addUserToAdminGroup(username)
        : await removeUserFromAdminGroup(username)
      notifySuccess('Rola użytkownika została zaktualizowana.')
      fetchUsersList()
    } catch (error) {
      notifyError(
        'Error changing role',
        'Coś poszło nie tak podczas aktualizacji roli użytkownika.',
        error,
      )
    }
  }

  function onCloseNewUserModal() {
    fetchUsersList()
    setAddModalIsActive(false)
  }

  function onSetDeletingUser(username) {
    setDeleteModalIsActive(true)
    setDeletingUser(username)
  }

  return (
    <>
      <AddUserModal isActive={addModalIsActive} onHide={onCloseNewUserModal} />

      <DeleteUserModal
        username={deletingUser}
        onDeleteUser={onDeleteUser}
        isActive={deleteModalIsActive}
        onHide={() => setDeleteModalIsActive(false)}
      />

      <Breadcrumb className={styles.Breadcrumb}>
        <Breadcrumb.Section link onClick={() => setActiveMenu('Menu')}>
          Menu Administracyjne
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section link>Użytkownicy</Breadcrumb.Section>
      </Breadcrumb>
      {users && (
        <>
          {users.map((user, index) => (
            <Card
              {...user}
              index={index + 1}
              key={`user-card-${index}`}
              onChangeRole={onChangeRole}
              deleteUsername={onSetDeletingUser}
            />
          ))}

          <Button
            primary
            style={{ float: 'right', margin: '10px 0px' }}
            onClick={() => setAddModalIsActive(true)}
          >
            Dodaj nowego użytkownika
          </Button>
        </>
      )}
      {isLoading && <LoadingIcon />}
    </>
  )
}
