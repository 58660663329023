import React from 'react'
import { Switch } from 'react-router-dom'
import { AuthenticatedRoute, UnauthenticatedRoute } from './components'
import { AdminPanel, Home, Login, ResetPassword, Start, System } from './pages'

export default () => (
  <Switch>
    <UnauthenticatedRoute path="/" exact component={Start} />
    <AuthenticatedRoute path="/home" component={Home} />
    <AuthenticatedRoute path="/admin" component={AdminPanel} />
    <AuthenticatedRoute path="/system" component={System} />
    <UnauthenticatedRoute path="/login" exact component={Login} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} />
  </Switch>
)
