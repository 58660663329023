import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Icon, Pagination, Table, Header } from 'semantic-ui-react'
import formatDate from 'src/utils/formatDate'
import classes from './OrdersHistory.module.scss'
import { setFuturePage } from 'src/redux/ordersHistory/actions'
import { getRowClassName } from 'src/pages/Home/Helpers/index'
import { Input, Checkbox, List } from 'semantic-ui-react'

const FutureOrder = ({
  orders,
  getStatus,
  onSinglePrint,
  editOrder,
  searchWord,
  futureSearch,
  onFutureSearch,
  setConfirmedStatus,
  confirmedStatus,
  setCanceledStatus,
  canceledStatus,
  setToPayStatus,
  toPayStatus,
  setPaidStatus,
  paidStatus,
}) => {
  const TOTAL_PER_PAGE = 10
  const totalPages = Math.ceil(orders.length / TOTAL_PER_PAGE)
  const { futurePage: page } = useSelector((state) => state.ordersHistory)
  const dispatch = useDispatch()

  const getOrdersOfPage = (page, orders) => {
    if (searchWord.length > 0) {
      page = 1
    }
    const start = (page - 1) * TOTAL_PER_PAGE
    const end = start + TOTAL_PER_PAGE
    return orders.slice(start, end)
  }

  return (
    <>
      <Input
        fluid
        icon="users"
        iconPosition="left"
        placeholder="Szukaj..."
        size="small"
        value={futureSearch}
        onChange={(e, { value }) => onFutureSearch(value)}
      />

      <div className={classes.CheckboxContainer}>
        <List.Item>
          <Checkbox
            label="Potwierdzone"
            onClick={() => setConfirmedStatus(!confirmedStatus)}
          />
        </List.Item>
        <List.Item>
          <Checkbox
            label="Anulowane"
            onClick={() => setCanceledStatus(!canceledStatus)}
          />
        </List.Item>
        <List.Item>
          <Checkbox
            label="Oczekiwanie na płatność"
            onClick={() => setToPayStatus(!toPayStatus)}
          />
        </List.Item>
        <List.Item>
          <Checkbox
            label="Opłacone"
            onClick={() => setPaidStatus(!paidStatus)}
          />
        </List.Item>
      </div>

      <Header as="h4">Do realizacji</Header>

      <Table fixed className={classes.Table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={classes.IdItem}>ID </Table.HeaderCell>
            <Table.HeaderCell>Telefon</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} className={classes.DataItem}>
              Data
            </Table.HeaderCell>
            <Table.HeaderCell>Kwota</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Wielkość</Table.HeaderCell>
            <Table.HeaderCell>Akcje</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orders &&
            getOrdersOfPage(page, orders).map((order) => (
              <Table.Row
                key={order.id}
                className={getRowClassName(order.status, order.id)}
              >
                <Table.Cell className={classes.IdItem}>{order.id}</Table.Cell>
                <Table.Cell>{order.clientPhone}</Table.Cell>
                <Table.Cell colSpan={2} className={classes.DataItem}>
                  {formatDate(new Date(order.BWT.date))}{' '}
                  {order.BWT.selectedHour}:00
                </Table.Cell>
                <Table.Cell>{order.price}zł</Table.Cell>
                <Table.Cell>{getStatus(order.status)}</Table.Cell>
                <Table.Cell>
                  {order.BWT.numberOfBlocks}{' '}
                  {order.BWT.numberOfBlocks > 1 ? 'sloty' : 'slot'}
                  {order.priority && <Icon name="lightning" />}
                </Table.Cell>
                <Table.Cell>
                  <Icon
                    className={classes.ActionIcon}
                    onClick={() => onSinglePrint(order.id)}
                    name="print"
                  />
                  <Icon
                    onClick={() => editOrder(order)}
                    className={classes.ActionIcon}
                    name="edit"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>

        {/* {totalPages >= 1 && ( - could be necessary to
            leave pagination always visible, at least to 1 page
          */}
        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Pagination
                  size="mini"
                  boundaryRange={0}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  activePage={page}
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) =>
                    dispatch(setFuturePage(activePage))
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </>
  )
}

export default FutureOrder
