import React from 'react'
import moment from 'moment'

import Row from './Row'
import * as classes from './TimeTable.module.scss'

export default function ({
  bwt,
  onSelectRow,
  selectedIndexes,
  removeAble,
  onRemove,
}) {
  const { timeTable, startHour, date } = bwt

  function isDisabled(rowIndex) {
    const isSameDayOrBefore = moment(date).isSameOrBefore(moment())
    const currentHour = new Date(Date.now()).getHours()
    const isPastHour = rowIndex + startHour < currentHour
    return isPastHour && isSameDayOrBefore
  }

  function isSelected(rowIndex) {
    return selectedIndexes.some((index) => index === rowIndex)
  }

  return (
    <div className={classes.TableContainer} data-test-id="BWT-table">
      {timeTable &&
        timeTable.map((row, rowIndex) => (
          <Row
            data={row}
            key={`row-${rowIndex}`}
            index={rowIndex}
            onRemove={onRemove}
            startHour={startHour}
            removeAble={removeAble}
            onSelectRow={onSelectRow}
            isDisabled={() => isDisabled(rowIndex)}
            isSelected={isSelected(rowIndex)}
          />
        ))}
    </div>
  )
}
