import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { faUtensils, faPercent } from "@fortawesome/free-solid-svg-icons"
import OrderCard from "./OrderCard"
import classes from "./OrderSummary.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Switch, Input } from "src/components"
import { setDiscount } from "src/redux/order/reducer"

export default function OrderSummary() {
  const dispatch = useDispatch()

  const { userData, items, itemsPrice, deliveryPrice, discounts } = useSelector(
    (state) => state.order,
  )
  const [isDiscount, setIsDiscount] = useState(false)
  let boxPrice = !userData.boxValue
    ? 0
    : typeof userData?.boxValue === "object"
    ? userData.boxValue.reduce(
        (prev, current) => prev + current.count * current.price,
        0,
      )
    : JSON.parse(userData.boxValue).reduce(
        (prev, current) => prev + current.count * current.price,
        0,
      )

  console.log(boxPrice)

  function toggleDiscount() {
    setIsDiscount(!isDiscount)
  }

  function handleChange(e) {
    dispatch(setDiscount(Math.abs(Number(e.currentTarget.value))))
  }
  return (
    <div className={classes.OrderWrapper}>
      <div className={classes.OrderSection}>
        {items && items.length ? (
          <div>
            <div className={classes.Header}>
              <h3> Podsumowanie </h3>
              <div className={classes.SwitchContainer}>
                <p>Zniżka</p>
                <Switch checked={isDiscount} onChange={toggleDiscount} />
                <Input
                  name="discount"
                  placeholder=""
                  icon={faPercent}
                  onChange={handleChange}
                  value={discounts}
                  disabled={!isDiscount}
                  type="number"
                  min={0}
                />
              </div>
              <h3 className="price">
                {discounts && userData.deliveryType
                  ? (itemsPrice * (100 - parseInt(discounts))) / 100 +
                    " + " +
                    deliveryPrice +
                    " + " +
                    (boxPrice ?? 0) +
                    " = " +
                    parseFloat(
                      (itemsPrice * (100 - parseInt(discounts))) / 100 +
                        deliveryPrice +
                        (boxPrice ?? 0),
                    ).toFixed(2)
                  : itemsPrice +
                    " + " +
                    deliveryPrice +
                    " + " +
                    (boxPrice ?? 0) +
                    " = " +
                    (
                      parseFloat(itemsPrice) +
                      deliveryPrice +
                      (boxPrice ?? 0)
                    ).toFixed(2)}{" "}
                zł
              </h3>
            </div>

            {items.map((item, index) => (
              <OrderCard
                index={index}
                name={item.name}
                price={item.price}
                key={`ordercard-${index}`}
              />
            ))}
          </div>
        ) : (
          <div className={classes.EmptyBasket}>
            <FontAwesomeIcon size="5x" icon={faUtensils} />
          </div>
        )}
      </div>
    </div>
  )
}
