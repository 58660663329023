import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap'

import { signUp } from 'src/api/rest/auth'

import classes from './Signup.module.scss'
import { notifyError, notifySuccess } from 'src/notification'

export default function Signup({ onClose }) {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  function validateForm() {
    return (
      email.length > 0 &&
      username.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    )
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    try {
      await signUp(username, password, email)
      notifySuccess('Użytkownik został dodany.')
      onClose()
    } catch (error) {
      notifyError(
        'Error on sign up',
        'Coś poszło nie tak podczas rejestracji.',
        error,
      )
    }
    setIsLoading(false)
  }

  return (
    <div className={classes.Signup}>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel>Wybierz nazwę użytkownika</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Podaj swój adres email</ControlLabel>
          <FormControl
            type="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>
            Hasło - wymagania: minimum 8 znaków, mała litera, wielka litera,
            cyfra
          </ControlLabel>
          <FormControl
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Wprowadź hasło ponownie</ControlLabel>
          <FormControl
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          />
        </FormGroup>

        <Button type="submit" loading={isLoading} disabled={!validateForm()}>
          Dodaj nowego użytkownika
        </Button>
      </form>
    </div>
  )
}
