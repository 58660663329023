import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

import classes from '../EditItem.module.scss'

export default function EditSet({ set, onEdit, onDelete }) {
  const { id, name, products } = set

  return !products ? (
    <div className={classes.Ingredient}>
      <p> Brak przypisanych składników </p>
    </div>
  ) : (
    <article className={classes.ItemContainer}>
      <Row className={classes.Description}>
        <Col xs={2}>
          <span>ID{id}</span>
        </Col>
        <Col xs={6}>
          <span> {name} </span>
        </Col>
        <Col xs={2} cellPadding={0}>
          <Button onClick={() => onEdit(set)} primary>
            Edytuj
          </Button>
        </Col>
        <Col xs={2} cellPadding={0}>
          <Button onClick={() => onDelete(set)} color="red">
            Usuń
          </Button>
        </Col>
      </Row>
      <div className={classes.IngredientContainer}>
        {products.map((product, index) => (
          <div key={`product-${index}`} className={classes.Ingredient}>
            {product
              ? `${product.name} x ${product.numberOfPieces}`
              : 'ITEM HAS BEED REMOVED'}
          </div>
        ))}
      </div>
    </article>
  )
}
