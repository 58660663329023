import { __prod__ } from './constants'
const appSyncConfigProd = {
  aws_appsync_graphqlEndpoint:
    'https://6zyv223myfblpbkzrv2xqtbkk4.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-tlswfg7gfvejhp2h55frheh77q',
}

const appSyncConfigTest = {
  aws_appsync_graphqlEndpoint:
    'https://373ni2jvzvas5fn6bg3rpfyuge.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-6jsbwwdhhzdnblonssd4usvp74',
}

const authConfigTest = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_FXfjPGNv7',
  identityPoolId: 'eu-central-1:c4603751-e7ba-4cd5-8831-f4268f66d401',
  userPoolWebClientId: '5au9f4rlfsg5k85uk7n1tn0lan',
}

const authConfigProd = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_ts5ZHBlPH',
  identityPoolId: 'eu-central-1:d8d9c4b4-c3cb-4c04-ae59-5ccb06b97cba',
  userPoolWebClientId: '1ml45ehqd8fl0lsigbb9ob3acv',
}

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  Auth: {
    mandatorySignIn: true,
    ...(__prod__ ? authConfigProd : authConfigTest),
  },
  API: {
    endpoints: [
      {
        name: 'przytomni-api',
        endpoint: 'https://api-przytomni.blesu.eu',
        region: 'eu-central-1',
      },
    ],
  },
  Storage: {
    region: 'eu-central-1',
    bucket: 'cms-psushi',
    // identityPoolId: 'eu-central-1:d8d9c4b4-c3cb-4c04-ae59-5ccb06b97cba',
    identityPoolId: 'eu-central-1:c4603751-e7ba-4cd5-8831-f4268f66d401',
    AWSS3: {
      bucket: 'cms-psushi',
      region: 'eu-central-1',
    },
  },
  ...(__prod__ ? appSyncConfigProd : appSyncConfigTest),
}
