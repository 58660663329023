import React, { useState } from "react"
import { useSelector } from "react-redux"

import Form from "./Form"
import Bottombar from "./Bottombar"
import OrderSummary from "./OrderSummary"
import StatusBoard from "./StatusBoard"

import orderSchema from "./orderSchema"
import classes from "../Order.module.scss"
import { orderStatus } from "src/constants"

export default function NewOrder() {
  const [errorFields, setErrorFields] = useState([])
  const { userData, mode, status } = useSelector((state) => state.order)

  //validate here status
  function validateForm() {
    const result = orderSchema.validate(userData, {
      abortEarly: false,
    })
    const errorFields = result.error
      ? result.error.details.map((detail) => detail.context.key)
      : []

    setErrorFields(errorFields)
    //Validation for email if status is set to 'Waiting for payment'
    if (status.length === 0) {
      errorFields.push("status")
    } else if (status.length > 0) {
      const lastStatus = status[0].status
      if (lastStatus === orderStatus.waitingForPayment.EN) {
        //Validate if the email has been added to the order
        if (!validateEmail(userData.email)) {
          errorFields.push("email")
        }
      }
      if (lastStatus === orderStatus.waitingForConfirmation.EN) {
        errorFields.push("status")
      }
      if (!validateNumber(userData.phone)) {
        errorFields.push("phone")
      }
    }

    return errorFields.filter(
      (error) => error !== "boxValue" && error !== "replacement",
    )
  }

  function validateEmail(email) {
    const regex = /\S+@\S+\.\S+/
    return regex.test(email)
  }

  function validateNumber(phone) {
    const regex = /^[+\]\s*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\(\)\s\./0-9]*$/
    return regex.test(phone)
  }

  return (
    <div className={classes.NewOrderContainer}>
      <Form
        userData={userData}
        errorFields={errorFields}
        orderMode={mode}
        paymentStatus={status}
      />
      <StatusBoard errorFields={errorFields} />
      <OrderSummary />
      <Bottombar validateForm={validateForm} />
    </div>
  )
}
