import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "src/img/box.png"
import { FormControl } from "react-bootstrap"
import statusValue from "src/constants/types/orderStatus"
import { Select, Input, Switch } from "src/components"
import BoxCountModal from "./BoxCountModal"
import {
  faPhone,
  faUser,
  faAt,
  faIdBadge,
  faFighterJet,
  faCreditCard,
  faMapMarkerAlt,
  faCalendarAlt,
  faBolt,
  faBoxOpen as faBox,
} from "@fortawesome/free-solid-svg-icons"
import SwapModal from "./SwapModal"

import {
  zones,
  orderModes,
  restaurantAddress,
  boxType as boxTypes,
} from "src/constants"
import paymentTypes from "src/constants/types/payment"
import {
  setUserData as setUserDataRedux,
  setPlaces,
} from "src/redux/order/reducer"
import classes from "./CustomerData.module.scss"
import bwtLocations, {
  getBwtLocationTranslation,
} from "src/constants/views/bwtLocations"
import SearchLocationInput from "src/components/common/SearchLocationInput/SearchLocationInput"
import { Button } from "semantic-ui-react"
import DeliveryModal from "./deliveryModal"

const typeToLabel = (type) => {
  switch (type) {
    case "B":
      return boxTypes[3].label
    case "S":
      return boxTypes[0].label
    case "M":
      return boxTypes[1].label
    case "L":
      return boxTypes[2].label
  }
}

const swapEditOptions = [
  { value: "edit", label: "Edycja", disabled: true },
  { value: "swap", label: "Wymiany" },
  { value: "delivery", label: "Sprzedaż/Produkcja" },
]

function BWTInput({ bwtLocation, ...buttonProps }) {
  return (
    <div className={classes.BWTInput}>
      <Input {...buttonProps} />
      {bwtLocation && (
        <span
          className={
            bwtLocation === bwtLocations.factory
              ? classes.FactoryBackground
              : classes.RestaurantBackground
          }
        >
          {getBwtLocationTranslation(bwtLocation)}
        </span>
      )}
    </div>
  )
}

export default function CustomerData({
  userData,
  errorFields,
  orderMode,
  paymentStatus,
}) {
  const {
    nip,
    reservation,
    name,
    email,
    address,
    phone,
    isPriority,
    paymentType,
    deliveryType,
    clientComment,
    productionComment,
    boxValue,
    replacement,
  } = userData

  const dispatch = useDispatch()
  const { S, M, L } = useSelector((state) => state.box)
  const { salePlace, productionPlace, paymentDate } = useSelector(
    (state) => state.order,
  )
  const [boxModalActive, setBoxModalActive] = useState(false)
  const [swapModalActive, setSwapModalActive] = useState(false)
  const [deliveryModalActive, setDeliveryModalActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState(swapEditOptions[0].value)
  //Formats BWT and hour to display
  useEffect(() => {
    console.log({ rep: replacement })
  }, [replacement])
  const getBWTDate = (date, hour) => {
    return `${date.split("-").join("/")} ${hour}-${hour + 1}`
  }

  const boxValueOptions = [
    {
      value: JSON.stringify([{ type: boxTypes[4].value, count: 0, price: 0 }]),
      label: boxTypes[4].label,
    },
    {
      value: JSON.stringify([{ type: boxTypes[3].value, count: 0, price: 0 }]),
      label: boxTypes[3].label,
    },
    {
      value: JSON.stringify([{ type: boxTypes[0].value, count: 1, price: S }]),
      label: boxTypes[0].label,
    },
    {
      value: JSON.stringify([{ type: boxTypes[1].value, count: 1, price: M }]),
      label: boxTypes[1].label,
    },
    {
      value: JSON.stringify([{ type: boxTypes[2].value, count: 1, price: L }]),
      label: boxTypes[2].label,
    },
  ]

  function handleChange(e) {
    let updatedData = { [e.currentTarget.name]: e.currentTarget.value }
    if (updatedData.deliveryType === zones.pickup) {
      setDeliveryAddress()
    }
    if (e.currentTarget.name === "boxValue") {
      if (JSON.parse(e.currentTarget.value)[0].type === "N") {
        setBoxModalActive(true)
        return
      }
    }

    // else {
    //   dispatch(setUserDataRedux({ ['address']: '' }))
    // } //this part needs to be deleted !
    dispatch(setUserDataRedux(updatedData))
  }

  function handleSwapEditChange(e) {
    const modals = {
      swap: setSwapModalActive,
      delivery: setDeliveryModalActive,
    }

    modals[e.currentTarget.value](true)
    setSelectedOption()
  }

  const typeToPrice = {
    S: S,
    M: M,
    L: L,
  }

  function handleBoxCountChange(e) {
    let boxValueArray = []
    for (const key in e) {
      boxValueArray.push({
        type: key,
        price: typeToPrice[key],
        count: Number(e[key]),
      })
    }
    let updatedData = { ["boxValue"]: JSON.stringify(boxValueArray) }
    dispatch(setUserDataRedux(updatedData))
  }

  function handleReplacementChange(e) {
    let updatedData = { ["replacement"]: e }
    console.log(updatedData)
    dispatch(setUserDataRedux(updatedData))
  }

  function togglePriority() {
    dispatch(setUserDataRedux({ isPriority: !isPriority }))
  }

  function setDeliveryAddress() {
    let updatedAddress = { ["address"]: restaurantAddress.restaurant }
    dispatch(setUserDataRedux(updatedAddress))
  }
  const placeholder = [
    {
      SETId: 3020,
      replacementID: [
        {
          originalId: 1002,
          newId: 1006,
        },
      ],
    },
  ]

  return (
    <div className={classes.CustomerDataSection}>
      <div className={classes.Form}>
        <Input
          autoComplete="off"
          name="phone"
          icon={faPhone}
          placeholder="Telefon"
          onChange={handleChange}
          error={errorFields.includes("phone")}
          value={phone}
          disabled={orderModes.add !== orderMode}
        />

        <BWTInput
          disabled
          name={"reservation"}
          icon={faCalendarAlt}
          placeholder={"Wybierz datę w BWT."}
          value={
            reservation.date && getBWTDate(reservation.date, reservation.hour)
          }
          bwtLocation={reservation.location}
          error={errorFields.includes(
            "date",
            "hour",
            "location",
            "numberOfBlocks",
          )}
        />

        <Input
          name="name"
          type="text"
          value={name}
          icon={faUser}
          onChange={handleChange}
          placeholder="Nazwa Klienta"
          error={errorFields.includes("name")}
        />

        <Input
          icon={faAt}
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          placeholder="Email Klienta"
          error={errorFields.includes("email")}
        />

        <div className={classes.DoubleSection}>
          <Select
            name="deliveryType"
            selectedOption={deliveryType}
            icon={faFighterJet}
            onChange={handleChange}
            error={errorFields.includes("deliveryType")}
            placeholder={"Wybierz"}
            options={[
              { value: zones.zoneA, label: "Strefa A" },
              { value: zones.zoneB, label: "Strefa B" },
              { value: zones.pickup, label: "Odbiór osobisty - Kolejowa" },
            ]}
          />

          <Select
            name="paymentType"
            icon={faCreditCard}
            onChange={handleChange}
            selectedOption={
              paymentStatus.length > 0 &&
              (paymentStatus[0].status === statusValue.waitingForPayment.EN
                ? paymentTypes.online
                : paymentType)
            }
            error={errorFields.includes("paymentType")}
            placeholder={"Wybierz"}
            options={[
              { value: paymentTypes.cash, label: "Gotówka przy odbiorze" },
              { value: paymentTypes.card, label: "Karta przy odbiorze" },
              { value: paymentTypes.online, label: "Online (Tpay)" },
            ]}
          />
        </div>

        <div className={classes.DoubleSection}>
          <Input
            name="nip"
            type="text"
            value={nip}
            icon={faIdBadge}
            placeholder="NIP"
            onChange={handleChange}
            error={errorFields.includes("nip")}
          />
          <Select
            name="boxValue"
            onChange={handleChange}
            icon={faBox}
            selectedOption={
              typeof boxValue === "object" ? JSON.stringify(boxValue) : boxValue
            }
            error={errorFields.includes("boxValue")}
            placeholder={"Opakowanie"}
            options={boxValueOptions}
            isBox={true}
          />
          {/* <div className={classes.SwitchContainer}>
            <p>
              <FontAwesomeIcon icon={faBolt} /> Priorytet
            </p>
            <Switch checked={isPriority} onChange={togglePriority} />
          </div> */}
        </div>

        <div style={{ gridColumn: "2 span" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "8px",
            }}
          >
            <SearchLocationInput
              type="text"
              name="address"
              value={
                deliveryType === zones.pickup
                  ? restaurantAddress.restaurant
                  : address
              }
              icon={faMapMarkerAlt}
              id="deliveryAddress"
              onChange={handleChange}
              placeholder="Adres dostawy"
              error={errorFields.includes("address")}
            />
            {/* <Button
              primary
              style={{ height: "34px", padding: "4px 50px" }}
              onClick={() => setSwapModalActive(true)}
            >
              Wymiany
            </Button> */}
            <Select
              name="swapEditSelect"
              onChange={handleSwapEditChange}
              error={errorFields.includes("boxValue")}
              options={swapEditOptions}
              selectedOption={selectedOption}
            />
          </div>
        </div>

        <FormControl
          name="productionComment"
          value={productionComment}
          onChange={handleChange}
          componentClass="textarea"
          style={{ resize: "none" }}
          className={classes.InputField}
          placeholder="Komentarz dla kuchni"
        />

        <FormControl
          name="clientComment"
          value={clientComment}
          onChange={handleChange}
          componentClass="textarea"
          style={{ resize: "none" }}
          className={classes.InputField}
          placeholder="Komentarz dla klienta"
        />
      </div>
      <BoxCountModal
        isActive={boxModalActive}
        onClose={() => setBoxModalActive(false)}
        onHide={() => setBoxModalActive(false)}
        handleBoxCountChange={handleBoxCountChange}
      />
      <SwapModal
        isActive={swapModalActive}
        onClose={() => setSwapModalActive(false)}
        onHide={() => setSwapModalActive(false)}
        onSubmit={(e) => handleReplacementChange(e)}
        initialValue={replacement ?? []}
      />

      <DeliveryModal
        isActive={deliveryModalActive}
        onClose={() => setDeliveryModalActive(false)}
        onHide={() => setDeliveryModalActive(false)}
        onSubmit={(input) => dispatch(setPlaces(input))}
        initialValue={{ productionPlace, salePlace, paymentDate }}
      />
    </div>
  )
}
