import classes from "./Menu.module.scss"
import MenuCard from "./MenuCard"
import BuyIcon from "src/img/Buy.svg"
import Location from "src/img/Location.svg"
import Chart from "src/img/Chart.svg"
import Setting from "src/img/Setting.svg"
import Calendar from "src/img/Calendar.svg"
import { tableViews } from "src/constants/views"
import AdminBWTTable from "../Table/AdminBWTTable"
import { Modal } from "src/components"
import RoutimoModal from "../Table/RoutimoModal"
import GreetingsModal from "../Table/WelcomeModal"
import ExcelGenerator from "../Table/ExcelGenerator"
import ExcelGeneratorTablet from "../Table/ExcelGeneratorTablet"
import RaportGeneratorModal from "../Table/RaportGenerator"
import DeliveryPricesModal from "../Table/DeliveryPricesModal"
import BoxPricesModal from "../Table/BoxPricesModal"
import React, { useState } from "react"

export default function SystemMenu({ mode, setActiveMenu }) {
  const [tableIsActive, setTableIsActive] = useState(false)
  const [tableMode, setTableMode] = useState(tableViews.default)
  const [routimoIsActive, setRoutimoIsActive] = useState(false)
  const [excelIsActive, setExcelIsActive] = useState(false)
  const [excelTabletIsActive, setExcelTabletIsActive] = useState(false)
  const [greetingsIsActive, setGreetingsIsActive] = useState(false)
  const [generateExcelRaport, setGenerateExcelRaport] = useState(false)
  const [deliveryPricesModal, setDeliveryPricesModal] = useState(false)
  const [boxPricesModal, setBoxPricesModal] = useState(false)

  function openModal(mode) {
    setTableMode(mode)
    setTableIsActive(true)
  }

  return (
    <div className={classes.MenuWrapper}>
      <Modal
        show={tableIsActive}
        bsSize="large"
        onHide={() => setTableIsActive(false)}
        description={
          <AdminBWTTable
            tableMode={tableMode}
            onHide={() => setTableIsActive(false)}
          />
        }
        title={
          mode === tableViews.custom
            ? "Edytuj ilość zamówień dla wybranego dnia."
            : "Edytuj standardową ilość zamówień w BWT."
        }
      />
      <MenuCard
        imgSrc={BuyIcon}
        title="Strona internetowa"
        desc="Zarządzanie informacjami na stronie internetowej skierowanymi do klientów."
      >
        <h2 onClick={() => setGreetingsIsActive(true)}>Wiadomość Powitalna</h2>
        <h2 onClick={() => setActiveMenu("Offer")}>Oferta specjalna</h2>
      </MenuCard>
      <MenuCard
        imgSrc={Location}
        title="Dostawa"
        desc="Zarządzanie danymi związanymi z transportem zamówień i wyznaczaniem tras."
      >
        <h2
          onClick={() => {
            setExcelIsActive(true)
          }}
        >
          Wygeneruj excel Fabryka
        </h2>
        <h2 onClick={() => setRoutimoIsActive(true)}>
          Wyślij przedział do Routific
        </h2>
        <h2 onClick={() => setDeliveryPricesModal(true)}>Ustal cenę dostawy</h2>
      </MenuCard>
      <MenuCard
        imgSrc={Chart}
        title="Raporty"
        desc="Generacja danych dotyczących zużycia produktów oraz kwot zamówień."
      >
        <h2 onClick={() => setGenerateExcelRaport(true)}>Raport z magazynu</h2>
        <h2 onClick={() => setExcelTabletIsActive(true)}>Raport z tabletu</h2>
      </MenuCard>
      <MenuCard
        imgSrc={Calendar}
        title="Baza Wolnych Terminów"
        desc="Ustawienia pojemności pozwalającej na przyjęcie określonej ilości zamówień w danym przedziale."
      >
        <h2 onClick={() => openModal(tableViews.default)}>
          Standardowa ilość BWT
        </h2>
        <h2 onClick={() => openModal(tableViews.custom)}>
          Niestandardowa ilość BWT
        </h2>
      </MenuCard>
      <MenuCard
        imgSrc={Setting}
        title="Administracja"
        desc="Zarządzanie dostępem do systemu DYSZA."
      >
        <h2 onClick={() => setActiveMenu("Users")}>Zarządzaj użytkownikami</h2>
        <h2 onClick={() => setBoxPricesModal(true)}>
          Ceny zestawów pakujących
        </h2>
        {/* <h2 onClick={() => setDisableSystemModal(true)}>Wyłącz system</h2> */}
      </MenuCard>
      <div className={classes.MenuBg}></div>
      <RoutimoModal
        isActive={routimoIsActive}
        onClose={() => setRoutimoIsActive(false)}
      />

      <ExcelGenerator
        isActive={excelIsActive}
        onClose={() => setExcelIsActive(false)}
      />

      <ExcelGeneratorTablet
        isActive={excelTabletIsActive}
        onClose={() => setExcelTabletIsActive(false)}
      />

      <GreetingsModal
        isActive={greetingsIsActive}
        onClose={() => setGreetingsIsActive(false)}
        onHide={() => setGreetingsIsActive(false)}
      />

      <RaportGeneratorModal
        isActive={generateExcelRaport}
        onClose={() => setGenerateExcelRaport(false)}
        onHide={() => setGenerateExcelRaport(false)}
      />
      {/* <DisableSystemModal
        isActive={disableSystemModal}
        onClose={() => setDisableSystemModal(false)}
        onHide={() => setDisableSystemModal(false)}
      /> */}
      <DeliveryPricesModal
        isActive={deliveryPricesModal}
        onClose={() => setDeliveryPricesModal(false)}
        onHide={() => setDeliveryPricesModal(false)}
      />
      <BoxPricesModal
        isActive={boxPricesModal}
        onClose={() => setBoxPricesModal(false)}
        onHide={() => setBoxPricesModal(false)}
      />
    </div>
  )
}
