import React, { useState, useEffect } from 'react'
import { Input, Modal } from 'src/components'
import { Button } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDeliveryPrices } from 'src/redux/order/reducer'
import { notifyError, notifySuccess } from 'src/notification'
import { updateDeliveryPrices } from 'src/api/rest/deliveryPrices'
const DeliveryPricesModal = ({ isActive, onClose, onHide }) => {
  const dispatch = useDispatch()
  const { deliveryPrices } = useSelector((state) => state.order)
  const [zoneA, setZoneA] = useState(deliveryPrices.zonea)
  const [zoneB, setZoneB] = useState(deliveryPrices.zoneb)
  useEffect(() => {
    setZoneA(deliveryPrices.zonea)
    setZoneB(deliveryPrices.zoneb)
  }, [deliveryPrices.zonea, deliveryPrices.zoneb])
  const handleZonePriceChange = async () => {
    try {
      //Api call
      await updateDeliveryPrices(Number(zoneA), Number(zoneB))
      await dispatch(fetchDeliveryPrices())
      notifySuccess('Pomyślnie zmieniono ceny dostaw')
      onClose()
    } catch (err) {
      notifyError('Error', 'Zmiana cen nie powiodła się', err)
    }
  }
  return (
    <Modal
      title="Ceny Dostaw"
      show={isActive}
      onHide={onClose}
      description={
        <>
          <label htmlFor="Zone A">Strefa A</label>
          <Input
            name="Zone A"
            onChange={(e) => setZoneA(e.target.value)}
            value={zoneA}
          />
          <label htmlFor="Zone B">Strefa B</label>
          <Input
            name="Zone B"
            onChange={(e) => setZoneB(e.target.value)}
            value={zoneB}
          />
        </>
      }
      footer={[
        <Button onClick={() => handleZonePriceChange()} primary>
          Zmień ceny dostaw
        </Button>,
      ]}
    ></Modal>
  )
}

export default DeliveryPricesModal
