import React from 'react'
import { Button } from 'semantic-ui-react'

export default function FileButton({ onSelect, placeholder, value }) {
  const uid = Math.random().toString(36).substring(7)

  function onChangeFile(e) {
    const file = e.currentTarget.files[0]
    if (onSelect) {
      onSelect(file)
    }
  }

  return (
    <label htmlFor={uid} style={{ display: 'flex' }}>
      <Button
        icon="upload"
        label={{
          basic: true,
          content: placeholder,
        }}
        labelPosition="right"
      />

      <input
        id={uid}
        type="file"
        onChange={onChangeFile}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </label>
  )
}
