import React, { useState } from "react"
import { useDispatch } from "react-redux"
import moment from "moment"
import { SingleDatePicker } from "react-dates"

import { Button, Select } from "semantic-ui-react"
import { Modal } from "src/components"
import { doublizeNumber } from "src/utils/formatDate"
import { exportCSV } from "src/utils/exportCSV"

import { notifyError } from "src/notification"
import getOrdersInTime from "src/utils/getOrdersInTime"
import orderStatus from "src/constants/types/orderStatus"
import { searchOrdersHistory } from "src/redux/ordersHistory/actions"

const RoutimoModal = ({ isActive, onClose }) => {
  const [hour, setHour] = useState(undefined)
  const [date, setDate] = useState(moment())
  const dispatch = useDispatch()

  function getHours() {
    let hours = []

    for (let i = 9; i < 24; i++) {
      let hour = doublizeNumber(i) + ":00"
      hours.push({ text: hour, key: hour, value: i })
    }

    return hours
  }

  const filterFactory = (order) => order.BWT.location === "factory"

  const filterCompletedOrders = (order) =>
    order.status[0].status === orderStatus.confirmed.EN ||
    order.status[0].status === orderStatus.paid.EN

  function onGenerateExcel() {
    dispatch(searchOrdersHistory()).then((response) => {
      const { status, future = [] } = response
      if (!status) {
        notifyError(
          "Error generating excel",
          "Nie ma nowych zamówień na ostatnią godzinę.",
        )
        return
      }

      const formattedDate = date
        .set({
          hour: hour,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .valueOf()

      const orders = getOrdersInTime(future, formattedDate)
        .filter(filterFactory)
        .filter(filterCompletedOrders)

      if (!orders || orders.length === 0) {
        notifyError(
          "No new orders exist",
          "Nie ma nowych zamówień na ostatnią godzinę.",
        )
        return
      }

      exportCSV(orders)
    })
  }

  return (
    <Modal
      title="Generacja raportu excel"
      show={isActive}
      onHide={onClose}
      footer={[
        <Button onClick={onGenerateExcel} primary>
          Wygeneruj excel
        </Button>,
      ]}
    >
      <div style={{ display: "flex", flexDirection: "column", minHeight: 500 }}>
        <div style={{ display: "grid", gridGap: 10, gridAutoFlow: "column" }}>
          <Select
            onChange={(e, { value }) => setHour(value)}
            value={hour}
            placeholder="Wybierz godzinę"
            options={getHours()}
          />
        </div>

        <br />

        <SingleDatePicker
          focused
          firstDayOfWeek={1}
          numberOfMonths={1}
          date={moment(date)}
          displayFormat={() => "DD/MM/YYYY"}
          onFocusChange={() => {}}
          onDateChange={setDate}
          isOutsideRange={(date) =>
            !date.isSameOrAfter(moment().startOf("day"))
          }
        />
      </div>
    </Modal>
  )
}

export default RoutimoModal
