export const onCreateShopOrder = /* GraphQL */ `
  subscription onCreatePSushiOrderShopDev {
    onCreatePSushiOrderShopDev {
      clientPhone
      id
      clientAddr
      clientComment
      clientEmail
      clientName
      clientNIP
      createdAt
      discounts
      orders
      price
      priority
      status
      deliveryType
      paymentType
    }
  }
`
