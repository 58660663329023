import React, { useState } from 'react'
import moment from 'moment'
import { Button } from 'semantic-ui-react'
import { SingleDatePicker } from 'react-dates'
import { useSelector, useDispatch } from 'react-redux'

import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BwtTable from './Table'
import { setBWTLocation, setBWTDate, fetchBWT } from 'src/redux/bwt/actions'

import { notifyError } from 'src/notification'
import { getMidNightInMS } from 'src/utils/formatDate'
import bwtLocations from 'src/constants/views/bwtLocations'

import classes from './TimeTable.module.scss'

export default function BWT() {
  const [focused, setFocused] = useState(false)
  const { date, location } = useSelector((state) => state.bwt)
  const reservation = useSelector((state) => state.order.userData.reservation)
  const dispatch = useDispatch()

  function forceUpdate() {
    dispatch(fetchBWT())
  }

  function setLocation(newLocation) {
    if (isReserved()) return
    dispatch(setBWTLocation(newLocation))
  }

  function setDate(newDate) {
    if (isReserved()) return
    dispatch(setBWTDate(newDate))
  }

  function isReserved() {
    if (reservation.isReserved) {
      notifyError(
        'Please unreserve previous blocks',
        'Anuluj rezerwację bloku czasowy.',
      )
      return true
    }
    return false
  }

  return (
    <div className={classes.TableContainer}>
      <div className={classes.TableHeader}>
        <div className={classes.Flex}>
          <Button.Group size="massive">
            <Button
              className={
                bwtLocations.factory === location
                  ? classes.FactoryBackground
                  : ''
              }
              onClick={() => setLocation(bwtLocations.factory)}
            >
              Fabryka
            </Button>

            <Button
              className={
                bwtLocations.restaurant === location
                  ? classes.RestaurantBackground
                  : ''
              }
              onClick={() => setLocation(bwtLocations.restaurant)}
            >
              Restauracja
            </Button>
          </Button.Group>

          <FontAwesomeIcon
            size={'lg'}
            icon={faRedoAlt}
            onClick={forceUpdate}
            className={classes.RefreshIcon}
          />
        </div>

        <SingleDatePicker
          focused={focused}
          firstDayOfWeek={1}
          numberOfMonths={1}
          date={moment(date)}
          displayFormat={() => 'DD/MM/YYYY'}
          onFocusChange={({ focused }) => setFocused(focused)}
          onDateChange={(date) =>
            setDate(getMidNightInMS(date.utc().valueOf()))
          }
          isOutsideRange={(date) =>
            !date.isSameOrAfter(moment().startOf('day'))
          }
        />
        <div className={classes.TableContent}>
          <BwtTable date={date} location={location} />
        </div>
      </div>
    </div>
  )
}
