import React, { useState, useEffect, createRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import useForm from "src/hooks/useForm"
import { Storage } from "aws-amplify"

import {
  Container,
  Grid,
  Header,
  Input,
  Form,
  Dropdown,
  Button,
  Icon,
  Radio,
  List,
  Image,
  Ref,
  TextArea,
} from "semantic-ui-react"
import Product from "./Card"
import DeleteModal from "../DeleteModal"
import MenuList from "src/components/MenuList"

import { notifyError, notifySuccess } from "src/notification"

import {
  postProduct,
  putProduct,
  deleteProduct,
  fetchProductsToMenuEdit,
} from "src/api/rest/product"
import { appendProduct, updateProduct, removeProduct } from "src/redux/menu"

import { productDropdown, productType, boxType } from "src/constants"

import classes from "../Menu.module.scss"
import { FileUpload } from "src/components/common"

import { Select, InputNumber } from "antd"
import { fetchSetsToMenuEdit } from "src/api/rest/set"
import { fetchIngredientsToMenuEdit } from "src/api/rest/ingredient"

const SORT_ASC = (a, b) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

const { Row, Column } = Grid
const { Field } = Form

const { Option } = Select

const initState = {
  price: 0,
  name: "",
  type: "",
  category: "",
  weight: 0,
  isTakeAway: false,
  isRestaurant: false,
  ingredients: [],
  isVisible: false,
  isVisibleInSet: true,
  numberOfPieces: 0,
  description: "",
  imageURL:
    "https://images.vexels.com/media/users/3/143477/isolated/preview/a19742d122924a03bfe9ee46ce39ac42-sushi-icon-sticks-by-vexels.png",
  unit: "g",
}

export default function () {
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [effectingItems, setEffectingItems] = useState([])
  const [deletingItem, setDeletingItem] = useState(undefined)
  const [clearSelector, setClearSelector] = useState(false)
  const [ingredients, setIngredients] = useState([])
  const [selectedIngredients, setSelectedIngredients] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [products, setProducts] = useState([])
  const [sets, setSets] = useState([])
  const [ingredientsAndSouces, setIngredientsAndSouces] = useState([])
  const { state, handleChange, setState } = useForm(initState)

  var dropdownItem = createRef()

  useEffect(() => {
    fetchIngredientsProductsSets()
  }, [])

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      ingredients: ingredientsOptions
        .filter((el) => selectedIngredients.includes(el.name))
        .map((el) => {
          if (!isEditing) return el
          const x = selectedProduct.ingredients.find(({ id }) => el.id === id)
          if (x) return { ...el, quantity: x.quantity, unit: x.unit }
          return el
        }),
    }))
  }, [selectedIngredients])

  const fetchIngredientsProductsSets = async () => {
    const responseIngr = await fetchIngredientsToMenuEdit()
    const responseProd = await fetchProductsToMenuEdit()
    const responseSets = await fetchSetsToMenuEdit()
    setIngredients(responseIngr)
    setProducts(responseProd)
    setSets(responseSets)
    setIngredientsAndSouces([
      ...responseIngr,
      ...responseProd.filter((product) => {
        if (product.category === "Sosy" || product.category === "Półprodukty") {
          return product
        }
      }),
    ])
  }

  const ingredientsOptions = ingredientsAndSouces.map((el) => ({
    ...el,
    quantity: 0,
  }))
  // ingredients?.length &&
  //   products?.length && [
  //     ...ingredients.map((el) => ({
  //       ...el,
  //       quantity: 0,
  //       // unit: 'g',
  //     })),
  //     ...products.map((product) => {
  //       if (product.category === 'Sosy') {
  //         return product
  //       }
  //     }),
  //   ]
  const dispatch = useDispatch()

  function validateForm() {
    return (
      // state.price &&
      state.ingredients &&
      state.name.trim() &&
      state.numberOfPieces &&
      state.category
    )
  }

  async function onSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    if (!isEditing) {
      state.ingredients = state.ingredients.map((el) => ({
        id: parseInt(el.key ?? el.id),
        quantity: el.quantity ? el.quantity : 0,
        unit: el.unit ? el.unit : "g",
        // unit: 'g',
      }))
    }

    console.log("state")
    console.log(state)
    isEditing ? await updateExistingProduct(state) : await addNewProduct(state)
    setClearSelector(true)
    onCancelEdit()
    setIsLoading(false)
    setClearSelector(false)
  }

  function onEdit(product) {
    setIsEditing(true)
    setState(product)
    setSelectedProduct(product)
    setSelectedIngredients(
      ingredientsOptions
        .filter((ingredient) =>
          product.ingredients.find(
            (productIngredient) => ingredient.id === productIngredient.id,
          ),
        )
        .map((el) => el.name),
    )
  }

  function onCancelEdit() {
    let element = dropdownItem.current.querySelector('[aria-hidden="true"]')
    if (element) {
      element.click()
    }
    setIsEditing(false)
    setState(initState)
  }

  async function onDelete() {
    try {
      await deleteProduct(deletingItem.id, true)
      dispatch(removeProduct(deletingItem.id))
      onCloseModal()
      notifySuccess("Produkt wykasowany")
      fetchIngredientsProductsSets()
    } catch (error) {
      notifyError(
        "Something went wrong when deleting item",
        "Coś poszło nie tak podczas kasowania obiektu.",
        error,
      )
    }
  }

  async function onShowDeleteItem(product) {
    setIsDeleting(true)
    setDeletingItem(product)
    const { id } = await getEffectingItems(product.id, false)
    //Get effected products name
    const effectedSets = id.map((id) => sets.find((set) => id === set.id).name)
    setEffectingItems(effectedSets)
  }

  async function getEffectingItems(productID) {
    try {
      return await deleteProduct(productID, false)
    } catch (error) {
      notifyError(
        "Something when wrong when fetching effecting items",
        "Coś poszło nie tak podczas pobierania obiektu.",
        error,
      )
    }
  }

  function onCloseModal() {
    setIsDeleting(false)
    setEffectingItems([])
  }

  async function addNewProduct(product) {
    console.log("produkt")
    console.log(product)
    try {
      let newProduct
      if (product.isTakeAway === false && product.isRestaurant === false) {
        newProduct = await postProduct({
          ...product,
          isTakeAway: true,
          isRestaurant: true,
        })
      } else {
        newProduct = await postProduct(product)
      }
      dispatch(appendProduct(newProduct))
      notifySuccess("Produkt został dodany.")
      fetchIngredientsProductsSets()
    } catch (error) {
      notifyError(
        "Error adding new product",
        "Coś poszło nie tak podczas dodawania Produktu.",
        error,
      )
    }
    setIsLoading(false)
  }

  async function updateExistingProduct(product) {
    try {
      await putProduct(product, state.id)
      const productWithId = { ...product, id: state.id }
      dispatch(updateProduct(productWithId))
      notifySuccess("Produkt został zaktualizowany.")
      fetchIngredientsProductsSets()
    } catch (error) {
      notifyError(
        "Error updating product.",
        "Coś poszło nie tak podczas aktualizacji Produktu.",
        error,
      )
    }
    setIsLoading(false)
  }

  async function onUploadImage(image) {
    try {
      const name = Date.now() + "-" + image.name
      await Storage.put(
        name,
        image,
        // , {
        // contentType: 'image/png',
        // acl: 'public-read',
        // }
      )
      // Storage.put(name, image, {
      //   contentType: 'image/png',
      //   acl: 'public-read',
      // }).then((res) => console.log(res))

      const url = `https://cms-psushi.s3.eu-central-1.amazonaws.com/public/${name}`
      setState((prevState) => ({ ...prevState, imageURL: url }))
    } catch (error) {
      console.error(error)
    }
  }
  const dropdownOptions = [
    { key: "g", text: "g", value: "g" },
    { key: "szt", text: "szt", value: "szt" },
    { key: "ml", text: "ml", value: "ml" },
  ]
  return (
    <>
      <DeleteModal
        title="Usuwanie obiektu"
        onDelete={onDelete}
        isActive={isDeleting}
        onClose={onCloseModal}
        description={
          <>
            <Header>
              Czy jesteś pewny, że chcesz usunąć{" "}
              {deletingItem && deletingItem.name}?
            </Header>
            <p color="text-muted">
              Usunięcie tego obiektu spowoduje również usunięcie poniższych
              obiektów.
            </p>
            <List bulleted items={effectingItems} />
          </>
        }
      />
      <Container fluid className={classes.Wrapper}>
        <Grid>
          <Row columns={2}>
            <Column>
              <Header as="h3" textAlign="center">
                {!isEditing ? "Dodaj Produkt" : "Zaktualizuj Produkt"}
              </Header>

              <div className={classes.ItemImage}>
                <Image size="small" rounded src={state.imageURL} />

                <FileUpload
                  onSelect={onUploadImage}
                  placeholder="Wybierz obraz"
                />
              </div>

              <Form onSubmit={onSubmit}>
                <div className={classes.Row}>
                  <Field
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    label="Wprowadź nazwę"
                    placeholder="Wyborne avocado."
                    control={Input}
                    icon="food"
                    width="7"
                  />
                  <Field
                    name={"price"}
                    value={state.price}
                    onChange={({ currentTarget }) =>
                      setState((prevState) => ({
                        ...prevState,
                        price: parseFloat(currentTarget.value),
                      }))
                    }
                    label={"Cena"}
                    placeholder={"100zł"}
                    control={Input}
                    min={0}
                    type={"number"}
                    icon={"money"}
                    width="7"
                  />
                </div>
                <div className={classes.Row}>
                  <Field width="7">
                    <label>Wybierz lokalizację</label>
                    <Ref innerRef={dropdownItem}>
                      <Dropdown
                        fluid
                        selection
                        // placeholder={'Wybierz'}
                        defautValue="isTakeAway"
                        options={[
                          { text: "Fabryka", value: "isTakeAway" },
                          { text: "Restauracja", value: "isRestaurant" },
                          { text: "Restauracja i Fabryka", value: "isBoth" },
                        ]}
                        onChange={(e, { value }) => {
                          value === "isTakeAway" &&
                            setState((prevState) => ({
                              ...prevState,
                              isTakeAway: true,
                              isRestaurant: false,
                            }))
                          value === "isRestaurant" &&
                            setState((prevState) => ({
                              ...prevState,
                              isTakeAway: false,
                              isRestaurant: true,
                            }))
                          value === "isBoth" &&
                            setState((prevState) => ({
                              ...prevState,
                              isTakeAway: true,
                              isRestaurant: true,
                            }))
                        }}
                      />
                    </Ref>
                  </Field>
                  <Field width="7">
                    <label>Wybierz rodzaj produktu:</label>
                    <Dropdown
                      fluid
                      selection
                      name={"type"}
                      placeholder={"Wybierz"}
                      options={productType}
                      value={state.type}
                      onChange={(e, { value }) =>
                        setState((prevState) => ({ ...prevState, type: value }))
                      }
                    />
                  </Field>
                </div>

                <div className={classes.Row}>
                  <Field width="7">
                    <label>Wybierz kategorię</label>
                    <Dropdown
                      fluid
                      selection
                      name={"category"}
                      placeholder={"Wybierz"}
                      options={productDropdown}
                      value={state.category}
                      onChange={(e, { value }) =>
                        setState((prevState) => ({
                          ...prevState,
                          category: value,
                        }))
                      }
                    />
                  </Field>
                </div>
                <Field width="16">
                  <label>Opis:</label>
                  <TextArea
                    fluid
                    selection
                    name={"description"}
                    placeholder={"Opis"}
                    value={state.description}
                    onChange={(e, { value }) =>
                      setState((prevState) => ({
                        ...prevState,
                        description: value,
                      }))
                    }
                  />
                </Field>
                <Field
                  name={"numberOfPieces"}
                  value={state.numberOfPieces}
                  onChange={({ currentTarget }) =>
                    setState((prevState) => ({
                      ...prevState,
                      numberOfPieces: parseInt(currentTarget.value),
                    }))
                  }
                  label={"Liczba kawałków"}
                  placeholder={"0"}
                  control={Input}
                  min={0}
                  type={"number"}
                  icon={"money"}
                />
                <Field>
                  {console.log({
                    selected: ingredientsAndSouces
                      .filter((el) =>
                        state.ingredients.find((ing) => ing.id === el.id),
                      )
                      .map((item) => item.name),
                    ingredientsAndSouces,
                    form: state.ingredientsOptions,
                  })}
                  <label> Składniki </label>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={
                      isEditing
                        ? "Dodawanie składników do produktu nie jest możliwe - stwórz nowy produkt lub edytuj gramature"
                        : "Składniki"
                    }
                    // disabled={isEditing ? true : false}
                    // value={clearSelector ? [] : state.ingredientsOptions}
                    value={selectedIngredients}
                    onChange={(value, options) => {
                      setSelectedIngredients(value)
                    }}
                    // onChange={(e, value) => {
                    //   console.log({ value })
                    //   setState((prevState) => ({
                    //     ...prevState,
                    //     ingredients: value,
                    //   }))
                    // }}
                  >
                    {ingredientsOptions.map(({ id, name, quantity }) => (
                      <Option key={id} value={name} quantity={quantity}>
                        {name}
                      </Option>
                    ))}
                  </Select>

                  {/* Gramatura part */}
                </Field>
                {/* {!isEditing
                  ? state.ingredients.length > 0 &&
                    state.ingredients.map(({ key, value, quantity }) => (
                      <>
                        <label> {`Ustaw gramaturę dla ${value}: `}</label>
                        <InputNumber
                          style={{ width: '100%' }}
                          key={key}
                          id={key}
                          value={quantity}
                          onChange={(value) =>
                            setState((prevState) => ({
                              ...prevState,
                              ingredients: state.ingredients.map((el) =>
                                el.key === key
                                  ? {
                                      ...el,
                                      id: parseInt(key),
                                      quantity: parseInt(value),
                                      unit: 'g',
                                    }
                                  : el,
                              ),
                            }))
                          }
                        />
                      </>
                    ))
                  : state.ingredients.length > 0 &&
                    state.ingredients.map(({ id, quantity }) => (
                      <>
                        <label>
                          {`Ustaw gramaturę dla ${
                            ingredientsOptions.find((item) => item.id === id)
                              .name
                          }: `}
                        </label>
                        <InputNumber
                          style={{ width: '100%' }}
                          key={id}
                          id={id}
                          value={quantity}
                          onChange={(value) =>
                            setState((prevState) => ({
                              ...prevState,
                              ingredients: state.ingredients.map((el) =>
                                el.id === id
                                  ? {
                                      ...el,
                                      id: parseInt(id),
                                      quantity: parseInt(value),
                                      unit: 'g',
                                    }
                                  : el,
                              ),
                            }))
                          }
                        />
                      </>
                    ))} */}
                {
                  // !isEditing
                  //   ? state.ingredients.length > 0 &&
                  //     state.ingredients.map(({ id, name, quantity, unit }) => (
                  //       <>
                  //         <label> {`Ustaw gramaturę dla ${name}: `}</label>
                  //         {console.log({ isEditing })}
                  //         <br />
                  //         Wybierz jednostkę:{" "}
                  //         <div>
                  //           <Dropdown
                  //             selection
                  //             style={{ fontWeight: 700 }}
                  //             defaultValue="g"
                  //             value={unit ? unit : "g"}
                  //             options={dropdownOptions}
                  //             id={id}
                  //             key={id}
                  //             onChange={(e, data) => {
                  //               setState((prevState) => ({
                  //                 ...prevState,
                  //                 ingredients: state.ingredients.map((el) =>
                  //                   el.key === id
                  //                     ? {
                  //                         ...el,
                  //                         unit: data.value ? data.value : "g",
                  //                       }
                  //                     : el,
                  //                 ),
                  //               }))
                  //             }}
                  //           />{" "}
                  //         </div>
                  //         <InputNumber
                  //           style={{ width: "100%" }}
                  //           key={id}
                  //           id={id}
                  //           value={quantity}
                  //           lang={"pl"}
                  //           onChange={(value) => {
                  //             return setState((prevState) => ({
                  //               ...prevState,
                  //               ingredients: state.ingredients.map((el) =>
                  //                 el.key === id
                  //                   ? {
                  //                       ...el,
                  //                       id: parseInt(id),
                  //                       quantity: parseFloat(value),
                  //                     }
                  //                   : el,
                  //               ),
                  //             }))
                  //           }}
                  //         />
                  //       </>
                  //     ))
                  //   :
                  state.ingredients.length > 0 &&
                    state.ingredients.map(({ id, quantity, unit }) => (
                      <>
                        <label>
                          {`Ustaw gramaturę dla ${
                            ingredientsOptions.find((item) => item.id === id)
                              .name
                          }: `}
                        </label>
                        {console.log({ isEditing, id, quantity, unit })}
                        <br />
                        Wybierz jednostkę:
                        <Dropdown
                          selection
                          style={{ fontWeight: 700 }}
                          value={unit ?? "g"}
                          options={dropdownOptions}
                          id={id}
                          onChange={(e, data) => {
                            setState((prevState) => ({
                              ...prevState,
                              ingredients: state.ingredients.map((el) =>
                                el.id === id
                                  ? {
                                      ...el,
                                      unit: data.value,
                                    }
                                  : el,
                              ),
                            }))
                          }}
                        />{" "}
                        <InputNumber
                          style={{ width: "100%" }}
                          key={id}
                          id={id}
                          value={quantity}
                          onChange={(value) =>
                            setState((prevState) => ({
                              ...prevState,
                              ingredients: state.ingredients.map((el) =>
                                el.id === id
                                  ? {
                                      ...el,
                                      id: parseInt(id),
                                      quantity: parseFloat(value),
                                      unit: el.unit ? el.unit : "g",
                                    }
                                  : el,
                              ),
                            }))
                          }
                        />
                      </>
                    ))
                }

                {state.category === "Półprodukty" && (
                  <div>
                    <>
                      <hr />
                      <label>Ustaw gramaturę dla tego produktu: </label>
                      <br />
                      <span>Ustaw jednostkę:</span>
                      <Dropdown
                        selection
                        style={{ fontWeight: 700 }}
                        value={state.unit}
                        options={dropdownOptions.filter(
                          (el) => el.value !== "szt",
                        )}
                        id={"product_weight_unit"}
                        onChange={(e, data) => {
                          setState((prevState) => ({
                            ...prevState,

                            unit: data.value,
                          }))
                        }}
                      />{" "}
                      <br />
                      <div style={{ display: "flex" }}>
                        <InputNumber
                          style={{ width: "100%", height: "100%" }}
                          min={0}
                          id={"weight"}
                          value={state.weight}
                          lang={"pl"}
                          onChange={(value) => {
                            return setState((prevState) => ({
                              ...prevState,
                              weight: value,
                            }))
                          }}
                        />
                      </div>
                    </>
                  </div>
                )}

                <Field width={16} className={classes.SwitchContainer}>
                  <div>
                    <Icon name="eye" /> Widoczne jako osobny produkt
                  </div>

                  <Radio
                    toggle
                    checked={state.isVisible}
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isVisible: !state.isVisible,
                      }))
                    }
                  />
                </Field>

                <Field width={16} className={classes.SwitchContainer}>
                  <div>
                    <Icon name="eye" /> Widoczne w zestawie
                  </div>

                  <Radio
                    toggle
                    checked={state.isVisibleInSet}
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isVisibleInSet: !state.isVisibleInSet,
                      }))
                    }
                  />
                </Field>
                <Button
                  primary
                  type="submit"
                  loading={isLoading}
                  disabled={!validateForm()}
                >
                  {!isEditing ? "Dodaj produkt" : "Aktualizuj"}
                </Button>

                {isEditing && <Button onClick={onCancelEdit}>Anuluj</Button>}
              </Form>
            </Column>
            <Column>
              <MenuList>
                {products &&
                  products
                    .sort(SORT_ASC)
                    .map((product) => (
                      <Product
                        key={product.id}
                        ingredientsMenu={ingredientsAndSouces}
                        product={product}
                        onEdit={onEdit}
                        onDelete={onShowDeleteItem}
                      />
                    ))}
              </MenuList>
            </Column>
          </Row>
        </Grid>
      </Container>
    </>
  )
}
