import React from 'react'
import classes from './MenuCard.module.scss'
export default function MenuCard({ imgSrc, title, desc, children }) {
  return (
    <div className={classes.Wrapper}>
      <div className={classes.IconWrapper}>
        <img src={imgSrc} alt="" />
      </div>
      <h2>{title}</h2>
      <p>{desc}</p>
      <div className={classes.Border}></div>
      <div className={classes.ButtonContainer}>{children}</div>
    </div>
  )
}
