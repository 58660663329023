import React, { useState, useEffect } from "react"
import { Input, Modal } from "src/components"
import { Button } from "semantic-ui-react"
const BoxCountModal = ({ isActive, onClose, onHide, handleBoxCountChange }) => {
  const [boxSCount, setBoxSCount] = useState(0)
  const [boxMCount, setBoxMCount] = useState(0)
  const [boxLCount, setBoxLCount] = useState(0)
  const [error, setError] = useState({
    S: false,
    M: false,
    L: false,
  })
  const validate = () => {
    const regex = new RegExp(/^\d+$/)
    setError({
      S: !regex.test(boxSCount),
      M: !regex.test(boxMCount),
      L: !regex.test(boxLCount),
    })
    return (
      !regex.test(boxSCount) || !regex.test(boxMCount) || !regex.test(boxLCount)
    )
  }
  const handleChange = () => {
    const isError = validate()
    if (!isError) {
      handleBoxCountChange({ S: boxSCount, M: boxMCount, L: boxLCount })
      onClose()
    }
  }
  return (
    <Modal
      title="Ilość Zestawów Pakujących"
      show={isActive}
      onHide={onClose}
      description={
        <>
          <label htmlFor="S">Małe</label>
          <Input
            name="S"
            onChange={(e) => {
              setBoxSCount(e.target.value)
              setError((_) => ({ ..._, S: false }))
            }}
            value={boxSCount}
            error={error.S}
          />
          <label htmlFor="M">Średnie</label>
          <Input
            name="M"
            onChange={(e) => {
              setBoxMCount(e.target.value)
              setError((_) => ({ ..._, M: false }))
            }}
            value={boxMCount}
            error={error.M}
          />
          <label htmlFor="M">Duże</label>
          <Input
            name="L"
            onChange={(e) => {
              setBoxLCount(e.target.value)
              setError((_) => ({ ..._, L: false }))
            }}
            value={boxLCount}
            error={error.L}
          />
        </>
      }
      footer={[
        <Button onClick={() => handleChange()} primary>
          Zatwierdź
        </Button>,
      ]}
    ></Modal>
  )
}

export default BoxCountModal
