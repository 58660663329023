import Papa from 'papaparse'

export function exportCSVTablet(ordersList) {
  if (!ordersList || ordersList.length < 0) return

  const columns = [
    'ID',
    'address',
    'GOTOWKA',
    'KARTA',
    'ONLINE',
    'BLIK',
    'VOUCHER',
    'KELNER',
  ]

  const rows = []
  for (let i = 0; i < ordersList.length; i++) {
    const data = getRowData(ordersList[i])
    rows.push(data)
  }

  const data = {
    fields: columns,
    data: rows,
  }

  var csv = Papa.unparse(data, {
    delimiter: ';',
    newline: '\r\n',
  })
  let csvData = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' })
  var csvURL = window.URL.createObjectURL(csvData)

  const date = new Date().toLocaleString()
  const nameTablet = `RESTAURACJA-${date}`

  var tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${nameTablet}.csv`)
  tempLink.click()
}

function getRowData(row) {
  const { ID, address, GOTOWKA, KARTA, ONLINE, BLIK, VOUCHER, KELNER } = row

  return new Array(ID, address, GOTOWKA, KARTA, ONLINE, BLIK, VOUCHER, KELNER)
}
