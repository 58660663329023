import classes from 'src/pages/Home/LeftPanel/ShopOrders/ShopOrders.module.scss'
import orderStatus from 'src/constants/types/orderStatus'

export function getRowClassName(status) {
  if (status.length === 0) {
    return ''
  }

  const lastStatus = status[0].status
  const options = {
    [orderStatus.new.EN]: () => classes.NewRow,
    [orderStatus.canceled.EN]: () => classes.CanceledRow,
  }

  return options[lastStatus] ? options[lastStatus]() : ''
}

export function getStatus(status) {
  const lastStatus = status[0]
  for (let elem in orderStatus) {
    if (lastStatus.status === orderStatus[elem].EN) return orderStatus[elem].PL
  }
  return 'Nie znaleziono takiego statusu.'
}
