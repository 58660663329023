import React from 'react'
import emailIsVerified from 'src/utils/emailIsVerified'
import { Route, Redirect } from 'react-router-dom'

export default ({ component: C, props: cProps, ...rest }) => {
  const isVerified = emailIsVerified()
  return (
    <Route
      render={(props) =>
        !isVerified ? <C {...props} {...cProps} /> : <Redirect to={'/home'} />
      }
    />
  )
}
