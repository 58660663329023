import React, { useState } from 'react'
import { Form, Header, Icon, Label, Segment } from 'semantic-ui-react'
const { Field } = Form

export default ({
  selectedProducts,
  totalPieces,
  selectOptions,
  addProduct,
  removeProduct,
  width,
}) => {
  const [numberOfPieces, setNumberOfPieces] = useState(0)
  const [product, setProduct] = useState(undefined)

  function onAddProduct() {
    addProduct(product, numberOfPieces)
    setNumberOfPieces(0)
    setProduct(-1)
  }

  //Get product options except the onces that are already selected
  const productOptions = selectOptions.map(({ id, name }) => ({
    value: id,
    text: name,
  }))

  const addProductIsDisabled =
    !numberOfPieces ||
    isNaN(numberOfPieces) ||
    parseInt(numberOfPieces) < 1 ||
    !product

  return (
    <Field width={width}>
      <Form>
        <Form.Group>
          <Form.Dropdown
            placeholder="Wybierz produkty"
            fluid
            search
            selection
            width={11}
            options={productOptions}
            value={product}
            onChange={(e, { value }) => setProduct(value)}
          />
          <Form.Input
            width={4}
            value={numberOfPieces}
            onChange={(e) => setNumberOfPieces(e.currentTarget.value)}
          />
          <Form.Button
            icon
            width={1}
            onClick={onAddProduct}
            color={!addProductIsDisabled ? 'green' : 'red'}
          >
            <Icon name="check" disabled={addProductIsDisabled} />
          </Form.Button>
        </Form.Group>

        {selectedProducts.length === 0 ? (
          <Header as="h5" color="grey">
            Nie wybrano jeszcze produktów!
          </Header>
        ) : (
          <Segment>
            <Label.Group color="blue">
              {selectedProducts.map((product) => (
                <Label onClick={() => removeProduct(product.id)}>
                  {product.name} x {product.numberOfPieces}
                  <Icon name="close" />
                </Label>
              ))}
            </Label.Group>

            <Label color="grey" attached="bottom right" size="mini">
              Całkowita ilość kawałkó to {totalPieces}.
            </Label>
          </Segment>
        )}
      </Form>
    </Field>
  )
}
