import React from 'react'
import emailIsVerified from 'src/utils/emailIsVerified'
import { Route, Redirect } from 'react-router-dom'

export default ({ component: Component, ...rest }) => {
  const isVerified = emailIsVerified()

  return (
    <Route
      {...rest}
      render={(props) =>
        isVerified ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  )
}
