import React, { useState } from 'react'
import { Grid, Col } from 'react-bootstrap'
import MenuList from './MenuList'
import EditForm from './EditForm/index'
import classes from 'src/pages/Home/Home.module.scss'
export default function EdittingOffer({
  setIsEdittingOffer,
  setEdittingData,
  setActiveMenu,
  edittingData,
  setOfferList,
}) {
  const [activeProducts, setActiveProducts] = useState([])
  return (
    <Grid className={classes.Layout}>
      <Col xs={6}>
        <EditForm
          setOfferList={setOfferList}
          setIsEdittingOffer={setIsEdittingOffer}
          edittingData={edittingData}
          setEdittingData={setEdittingData}
          setActiveMenu={setActiveMenu}
          setActiveProducts={setActiveProducts}
          activeProducts={activeProducts}
        />
      </Col>
      <Col xs={6}>
        <MenuList
          setActiveProducts={setActiveProducts}
          activeProducts={activeProducts}
        />
      </Col>
    </Grid>
  )
}
