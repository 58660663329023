import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

export default function ({
  footer,
  title,
  children,
  description,
  extraDescription,
  onHide,
  show,
  isLoading,
  ...rest
}) {
  return (
    <Modal show={show} {...rest}>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {description}
        {children}
        <p>{extraDescription}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} onClick={onHide}>Anuluj</Button>
        {footer}
      </Modal.Footer>
    </Modal>
  )
}
