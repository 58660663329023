// @ts-check
import { combineReducers } from 'redux'

import order from 'src/redux/order/reducer'
import menu from 'src/redux/menu'
import ordersHistory from 'src/redux/ordersHistory/reducer'
import bwt from 'src/redux/bwt/reducer'
import auth from 'src/redux/auth'
import shop from 'src/redux/shopOrders/reducer'
import orderCopy from 'src/redux/orderCopy'
import box from 'src/redux/box'
import swaps from 'src/redux/swaps'

export default combineReducers({
  order,
  menu,
  bwt,
  auth,
  shop,
  orderCopy,
  ordersHistory,
  box,
  swaps
})
