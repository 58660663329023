//@ts-check
import { API } from "aws-amplify"
import api, { apiName } from "./constants"

export async function postIngredient(ingredient) {
  try {
    let request = {
      body: {
        name: ingredient.name,
        category: ingredient.category,
        price: ingredient.price || "-",
        isTakeAway: ingredient.isTakeAway,
        isRestaurant: ingredient.isRestaurant,
        imageURL: ingredient.imageURL || "",
      },
    }
    return await API.post(apiName, api.ingredientMenu, request)
    // return await API.post(apiName, api.ingredient, request)
  } catch (error) {
    throw error
  }
}

export async function fetchIngredients() {
  try {
    return await API.get(apiName, api.ingredientMenu, {})
  } catch (error) {
    throw error
  }
}

export async function fetchIngredientsToMenuEdit() {
  try {
    return await API.get(apiName, api.ingredientMenu, {})
  } catch (error) {
    throw error
  }
}

export async function putIngredient(ingredient, id) {
  try {
    const request = {
      body: {
        name: ingredient.name,
        category: ingredient.category,
        isTakeAway: ingredient.isTakeAway,
        isRestaurant: ingredient.isRestaurant,
        price: ingredient.price || "-",
        imageURL: ingredient.imageURL || "",
      },
    }
    return await API.put(apiName, `${api.ingredientMenu}/${id}`, request)
    // return await API.put(apiName, `${api.ingredient}/${id}`, request)
  } catch (error) {
    throw error
  }
}

export async function deleteIngredient(id, approve) {
  try {
    const request = {
      body: {
        approve,
      },
    }
    return await API.del(apiName, `${api.ingredientMenu}/${id}`, request)
    // return await API.del(apiName, `${api.ingredient}/${id}`, request)
  } catch (error) {
    throw error
  }
}
