const SIGN_IN = 'auth/SIGN_IN'
const SIGN_OUT = 'auth/SIGN_OUT'

const initState = {
  userData: {},
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SIGN_OUT]: () => ({ ...state, userData: {} }),
    [SIGN_IN]: () => ({ ...state, userData: payload }),
  }

  return cases[type] ? cases[type]() : state
}

//Actions
export const signOut = () => ({ type: SIGN_OUT })
export const signIn = (userData) => ({ type: SIGN_IN, payload: userData })
