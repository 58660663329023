import React, { useState, useEffect, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useForm from 'src/hooks/useForm'
import { Storage } from 'aws-amplify'

import {
  Container,
  Grid,
  Header,
  Input,
  Form,
  Dropdown,
  List,
  Image,
  Ref,
  Button,
} from 'semantic-ui-react'
import Ingredient from './Card'
import DeleteModal from '../DeleteModal'
import MenuList from 'src/components/MenuList'
import { notifySuccess, notifyError } from 'src/notification'

import {
  postIngredient,
  putIngredient,
  deleteIngredient,
  fetchIngredientsToMenuEdit,
} from 'src/api/rest/ingredient'

import {
  appendIngredient,
  updateIngredient,
  removeIngredient,
} from 'src/redux/menu'

import { ingredientDropdown } from 'src/constants'
import ingredientCategory from 'src/constants/types/ingredient'
import classes from '../Menu.module.scss'
import { FileUpload } from 'src/components/common'
import { fetchProductsToMenuEdit } from 'src/api/rest/product'

const { Row, Column } = Grid
const { Field } = Form

const initState = {
  name: '',
  price: 0,
  category: '',
  isTakeAway: false,
  isRestaurant: false,
  imageURL:
    'https://images.vexels.com/media/users/3/143477/isolated/preview/a19742d122924a03bfe9ee46ce39ac42-sushi-icon-sticks-by-vexels.png',
}

export default function () {
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [effectingItems, setEffectingItems] = useState([])
  const [deletingItem, setDeletingItem] = useState(undefined)
  const [ingredients, setIngredients] = useState([])
  const [products, setProducts] = useState([])
  const { state, handleChange, setState } = useForm(initState)

  var dropdownItem = createRef()

  // const products = useSelector((state) => state.menu.products)
  // const ingredients = useSelector((state) => state.menu.ingredients)

  useEffect(() => {
    fetchIngredientsAndProducts()
  }, [])

  const fetchIngredientsAndProducts = async () => {
    const responseIngr = await fetchIngredientsToMenuEdit()
    const responseProd = await fetchProductsToMenuEdit()

    setIngredients(responseIngr)
    setProducts(responseProd)
  }

  const dispatch = useDispatch()
  const ingredientsWithPriceList = [
    ingredientCategory.alcohol,
    ingredientCategory.beverages,
    ingredientCategory.addons,
  ]

  const ingredientWithPrice = (category) =>
    ingredientsWithPriceList.includes(category)

  function onEdit(ingredient) {
    setIsEditing(true)
    setState(ingredient)
  }

  function onCancelEdit() {
    let element = dropdownItem.current.querySelector('[aria-hidden="true"]')
    if (element) {
      element.click()
    }
    setIsEditing(false)
    setState(initState)
  }

  async function onDelete() {
    try {
      await deleteIngredient(deletingItem.id, true)
      dispatch(removeIngredient(deletingItem.id))
      onCloseModal()
      notifySuccess('Składnik wykasowany.')
    } catch (error) {
      notifyError(
        'Something went wrong when deleting item',
        'Coś poszło nie tak podczas kasowania obiektu.',
        error,
      )
    }
  }

  async function onShowDeleteItem(ingredient) {
    setIsDeleting(true)
    setDeletingItem(ingredient)
    const { id: productIDs } = await getEffectingItems(ingredient.id)
    //Get effected products name
    const effectedProducts = productIDs.map(
      (id) => products.find((product) => id === product.id).name,
    )
    setEffectingItems(effectedProducts)
  }

  async function getEffectingItems(ingredientID) {
    try {
      return await deleteIngredient(ingredientID, false)
    } catch (error) {
      notifyError(
        'Something when wrong when fetching effecting items',
        'Coś poszło nie tak podczas pobierania obiektów',
        error,
      )
    }
  }

  function onCloseModal() {
    setIsDeleting(false)
    setEffectingItems([])
  }

  function validateForm() {
    if (ingredientWithPrice(state.category))
      return state.name && state.category && state.price

    return state.name && state.category
  }

  async function onSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    isEditing
      ? await updateExistingIngredient(state)
      : await addNewIngredient(state)

    onCancelEdit()
    setIsLoading(false)
  }

  async function addNewIngredient(ingredient) {
    try {
      const newIngredient = await postIngredient(ingredient)
      dispatch(appendIngredient(newIngredient))
      notifySuccess('Składnik został dodany.')
      onCancelEdit()
      fetchIngredientsAndProducts()
    } catch (error) {
      notifyError(
        'Error adding new ingredient',
        'Coś poszło nie tak podczas dodawania Składnika.',
        error,
      )
    }
    setIsLoading(false)
  }

  async function updateExistingIngredient(ingredient) {
    try {
      await putIngredient(ingredient, state.id)
      dispatch(updateIngredient({ ...ingredient, id: state.id }))
      notifySuccess('Składnik został zaktualizowany.')
    } catch (error) {
      notifyError(
        'Something went wrong when updating ingredient.',
        'Coś poszło nie tak podczas aktualizacji obiektu.',
        error,
      )
    }
    setIsLoading(false)
  }

  async function onUploadImage(image) {
    try {
      const name = Date.now() + '-' + image.name
      await Storage.put(
        name,
        image,
        // , {
        // contentType: 'image/png',
        // acl: 'public-read',
        // }
      )

      const url = `https://cms-psushi.s3.eu-central-1.amazonaws.com/public/${name}`
      setState((prevState) => ({ ...prevState, imageURL: url }))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <DeleteModal
        title="title"
        onDelete={onDelete}
        isActive={isDeleting}
        onClose={onCloseModal}
        description={
          <>
            <Header> Czy na pewno chcesz usunąć pozycję? </Header>
            <p color="text-muted">
              Usunięcie może wpłynąć na inne pozycje z menu.
            </p>
            <List bulleted items={effectingItems} />
          </>
        }
      />

      <Container fluid className={classes.Wrapper}>
        <Grid>
          <Row columns={2}>
            <Column>
              <Header as="h3" textAlign="center">
                {!isEditing ? 'Dodaj Składnik' : 'Zaktualizuj Składnik'}
              </Header>

              <div className={classes.ItemImage}>
                <Image size="small" rounded src={state.imageURL} />
                <FileUpload
                  onSelect={onUploadImage}
                  placeholder="Wybierz obraz"
                />
              </div>

              <Form onSubmit={onSubmit}>
                <Field
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                  label="Wprowadź nazwę"
                  placeholder="Wyborne avocado."
                  control={Input}
                  icon="food"
                />

                <Field>
                  <label>Wybierz kategorię</label>
                  <Dropdown
                    fluid
                    selection
                    name={'category'}
                    value={state.category}
                    placeholder={'Wybierz'}
                    options={ingredientDropdown}
                    onChange={(e, { value }) =>
                      setState((prevState) => ({
                        ...prevState,
                        category: value,
                        price: parseFloat(value),
                      }))
                    }
                  />
                </Field>
                <Field>
                  <label>Wybierz lokalizację</label>
                  <Ref innerRef={dropdownItem}>
                    <Dropdown
                      fluid
                      selection
                      clearable
                      placeholder={'Wybierz lokalizację'}
                      options={[
                        { value: 'isTakeAway', text: 'Fabryka' },
                        { value: 'isRestaurant', text: 'Restauracja' },
                        { value: 'isBoth', text: 'Restauracja i Fabryka' },
                      ]}
                      onChange={(e, { value }) => {
                        value === 'isTakeAway' &&
                          setState((prevState) => ({
                            ...prevState,
                            isTakeAway: true,
                            isRestaurant: false,
                          }))
                        value === 'isRestaurant' &&
                          setState((prevState) => ({
                            ...prevState,
                            isTakeAway: false,
                            isRestaurant: true,
                          }))
                        value === 'isBoth' &&
                          setState((prevState) => ({
                            ...prevState,
                            isTakeAway: true,
                            isRestaurant: true,
                          }))
                      }}
                    />
                  </Ref>
                </Field>

                {ingredientWithPrice(state.category) && (
                  <Field
                    name={'price'}
                    value={state.price}
                    onChange={({ currentTarget }) =>
                      setState((prevState) => ({
                        ...prevState,
                        price: parseFloat(currentTarget.value),
                      }))
                    }
                    label={'Cena'}
                    placeholder={'100zł'}
                    control={Input}
                    min={0}
                    type={'number'}
                    icon={'money'}
                  />
                )}

                <Button
                  primary
                  type="submit"
                  loading={isLoading}
                  disabled={!validateForm()}
                >
                  {!isEditing ? 'Dodaj Składniki' : 'Aktualizuj'}
                </Button>

                {isEditing && <Button onClick={onCancelEdit}>Anuluj</Button>}
              </Form>
            </Column>

            <Column>
              <MenuList>
                {ingredients.length > 0 &&
                  ingredients.map((ingredient) => (
                    <Ingredient
                      key={ingredient.id}
                      onEdit={onEdit}
                      ingredient={ingredient}
                      onDelete={onShowDeleteItem}
                    />
                  ))}
              </MenuList>
            </Column>
          </Row>
        </Grid>
      </Container>
    </>
  )
}
