//@ts-check
import { API } from "aws-amplify"
import api, { apiName } from "./constants"

/**
 * Get BWT data for a specific date and location
 * @param {string} phoneNumber - Phone number of user to search history for.
 * @return {Promise} - Gets all the orders by this phonenumber made
 */
export async function getUserOrderHistory(phoneNumber) {
  try {
    return await API.get(apiName, `${api.order}/phone/${phoneNumber}`, {})
  } catch (error) {
    throw error
  }
}

export async function getOrdersHistory() {
  try {
    return await API.get(apiName, api.order, {})
  } catch (error) {
    throw error
  }
}

export async function postOrder(order, workerID) {
  const boxValue =
    typeof order.boxValue === "string"
      ? JSON.parse(order.boxValue)
      : order.boxValue
  try {
    const request = {
      body: {
        discounts: order.discounts,
        BWT: order.BWT,
        deliveryPrice: order.deliveryPrice,
        price: order.price,
        orders: order.orders,
        status: order.status,
        clientName: order.clientName,
        clientEmail: order.clientEmail || "-",
        clientPhone: order.clientPhone,
        clientAddr: order.clientAddr,
        createdAt: order.createdAt,
        paymentType: order.paymentType,
        deliveryType: order.deliveryType,
        priority: order.priority,
        clientNIP: order.clientNIP || "-",
        clientComment: order.clientComment || "-",
        boxValue: boxValue,
        workerID: workerID,
        replacement: order.replacement ?? [],
        productionPlace: order.productionPlace,
        salePlace: order.salePlace,
        paymentDate: order.paymentDate,
      },
    }
    console.log({ request: request })
    return await API.post(apiName, api.order, request)
  } catch (error) {
    throw error
  }
}

export async function putOrder(order, id, workerID) {
  const boxValue =
    typeof order.boxValue === "string"
      ? JSON.parse(order.boxValue)
      : order.boxValue
  try {
    const request = {
      body: {
        discounts: order.discounts,
        BWT: order.BWT,
        price: order.price,
        orders: order.orders,
        status: order.status,
        deliveryPrice: order.deliveryPrice,
        clientName: order.clientName,
        clientEmail: order.clientEmail,
        clientPhone: order.clientPhone,
        clientAddr: order.clientAddr,
        createdAt: order.createdAt,
        paymentType: order.paymentType,
        deliveryType: order.deliveryType,
        priority: order.priority,
        clientNIP: order.clientNIP || "-",
        clientComment: order.clientComment || "-",
        tpay: "",
        boxValue: boxValue,
        workerID: workerID,
        replacement: order.replacement ?? [],
        productionPlace: order.productionPlace,
        salePlace: order.salePlace,
        paymentDate: order.paymentDate,
      },
    }
    console.log({ request: request })
    return await API.put(apiName, `${api.order}/${id}`, request)
  } catch (error) {
    throw error
  }
}

export async function searchOrder(searchFilter, location, from, till) {
  try {
    const request = {
      body: {
        id: searchFilter,
        location,
        from,
        till,
      },
    }
    return await API.post(apiName, `${api.order}/search`, request)
  } catch (error) {
    throw error
  }
}

export async function fetchShopOrders() {
  try {
    return await API.get(apiName, api.shop, {})
  } catch (error) {
    throw error
  }
}

export async function sendTPayLink(orderID) {
  try {
    return await API.get(apiName, `${api.tpay}/${orderID}`, {})
  } catch (error) {
    throw error
  }
}

export async function fetchSummaryRestaurantOrder() {
  try {
    return await API.get(apiName, api.summaryRestaurant, {})
  } catch (error) {
    throw error
  }
}
