import React, { useState, useEffect, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useForm from 'src/hooks/useForm'
import { Storage } from 'aws-amplify'

import {
  Container,
  Grid,
  Header,
  Input,
  Dropdown,
  Form,
  Button,
  Image,
  Ref,
  Icon,
  Radio,
  TextArea,
} from 'semantic-ui-react'
import { notifySuccess, notifyError } from 'src/notification'
import Set from './Card'
import ProductSelect from './ProductSelect'
import MenuList from 'src/components/MenuList'
import DeleteModal from '../DeleteModal'

import {
  postSet,
  putSet,
  deleteSet,
  fetchSetsToMenuEdit,
} from 'src/api/rest/set'
import { appendSet, updateSet, removeSet } from 'src/redux/menu'

import classes from '../Menu.module.scss'
import { FileUpload } from 'src/components/common'
import { productType, boxType } from 'src/constants'
import { fetchProductsToMenuEdit } from 'src/api/rest/product'

const { Row, Column } = Grid
const { Field } = Form

const initState = {
  name: '',
  price: 0,
  type: '',
  category: 'Zestaw',
  isTakeAway: false,
  isRestaurant: false,
  numberOfPieces: 0,
  products: [],
  numOfExtraPieces: 0,
  isPin: false,
  imageURL:
    'https://images.vexels.com/media/users/3/143477/isolated/preview/a19742d122924a03bfe9ee46ce39ac42-sushi-icon-sticks-by-vexels.png',
}

export default function SetPanel() {
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingItem, setDeletingItem] = useState(undefined)
  const [products, setProducts] = useState([])
  const [sets, setSets] = useState([])

  const { state, handleChange, setState } = useForm(initState)

  var dropdownItem = createRef()

  useEffect(() => {
    fetchIngredientsProductsSets()
  }, [])

  // const sets = useSelector((state) => state.menu.sets)
  // const products = useSelector((state) => state.menu.products)

  const fetchIngredientsProductsSets = async () => {
    const responseProd = await fetchProductsToMenuEdit()
    const responseSets = await fetchSetsToMenuEdit()

    setProducts(responseProd)
    setSets(responseSets)
  }

  const dispatch = useDispatch()

  function addProduct(productID, numberOfPieces) {
    const product = products.find((item) => item.id === productID)

    if (product) {
      product.numberOfPieces = parseInt(numberOfPieces)

      setState((prevState) => ({
        ...prevState,
        products: [...state.products, product],
      }))
    }
  }

  function removeProduct(productID) {
    const products = state.products.filter(
      (product) => product.id !== productID,
    )
    setState((prevState) => ({ ...prevState, products }))
  }

  function validateForm() {
    return (
      state.type &&
      state.products &&
      state.price > 0 &&
      state.name.trim() &&
      state.numOfExtraPieces >= 0
    )
  }

  function onEdit(set) {
    setIsEditing(true)
    setState(set)
  }

  function onCancelEdit() {
    let element = dropdownItem.current.querySelector('[aria-hidden="true"]')
    if (element) {
      element.click()
    }

    setIsEditing(false)
    setState(initState)
  }

  async function onDelete() {
    try {
      await deleteSet(deletingItem.id, true)
      dispatch(removeSet(deletingItem.id))
      setIsDeleting(false)
      notifySuccess('Zestaw wykasowany.')
    } catch (error) {
      notifyError(
        'Something went wrong when deleting item',
        'Coś poszło nie tak podczas kasowania obiektu.',
        error,
      )
    }
  }

  async function onShowDeleteItem(set) {
    setIsDeleting(true)
    setDeletingItem(set)
  }

  async function onSubmit(e) {
    e.preventDefault()
    setIsLoading(true)

    isEditing ? await updateExistingSet(state) : await addNewSet(state)
    onCancelEdit()
    setIsLoading(false)
  }

  async function addNewSet(set) {
    try {
      const newSet = await postSet(set)
      dispatch(appendSet(newSet))
      notifySuccess('Zestaw został dodany.')
      fetchIngredientsProductsSets()
    } catch (error) {
      notifyError(
        'Error adding new set',
        'Coś poszło nie tak podczas dodawania Zestawu',
        error,
      )
    }
    setIsLoading(false)
  }

  async function updateExistingSet(set) {
    try {
      await putSet(set, state.id)
      dispatch(updateSet(set))
      notifySuccess('Zestaw został zaktualizowany.')
    } catch (error) {
      notifyError(
        'Error updating set',
        'Coś poszło nie tak podczas aktualizacji Zestawu.',
        error,
      )
    }
    setIsLoading(false)
  }

  function getTotalPieces() {
    if (!state.products) return 0
    return state.products.reduce(
      (total, product) => (total += parseInt(product.numberOfPieces)),
      0,
    )
  }

  function getSelectOptions() {
    if (!products) return []
    //Filter out all the already selected products
    return products.filter(
      (item) => !state.products.some((product) => product.id === item.id),
    )
  }

  async function onUploadImage(image) {
    try {
      const name = Date.now() + '-' + image.name
      await Storage.put(
        name,
        image,
        // , {
        // contentType: 'image/png',
        // acl: 'public-read',
        // }
      )

      const url = `https://cms-psushi.s3.eu-central-1.amazonaws.com/public/${name}`
      setState((prevState) => ({ ...prevState, imageURL: url }))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {deletingItem && (
        <DeleteModal
          title="Usuwanie obiektu."
          onDelete={onDelete}
          isActive={isDeleting}
          onClose={() => setIsDeleting(false)}
          description={
            <p>Czy jesteś pewny, że chcesz usunąć {deletingItem.name}?</p>
          }
        />
      )}

      <Container fluid className={classes.Wrapper}>
        <Grid>
          <Row columns={2}>
            <Column>
              <Header as="h3" textAlign="center">
                {!isEditing ? 'Dodaj Zestaw' : 'Zaktualizuj Zestaw'}
              </Header>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <Image size="small" rounded src={state.imageURL} />
                <FileUpload
                  onSelect={onUploadImage}
                  placeholder="Wybierz obraz"
                />
              </div>

              <Form>
                <div className={classes.Row}>
                  <Field
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    label="Wprowadź nazwę"
                    placeholder="Wyborne avocado."
                    control={Input}
                    icon="food"
                    width="7"
                  />
                  <Field
                    name={'price'}
                    value={state.price}
                    onChange={handleChange}
                    label={'Cena'}
                    placeholder={'100zł'}
                    control={Input}
                    min={0}
                    type={'number'}
                    icon={'money'}
                    width="7"
                  />
                </div>
                <div className={classes.Row}>
                  <Field width="7">
                    <label>Wybierz lokalizację</label>
                    <Ref innerRef={dropdownItem}>
                      <Dropdown
                        fluid
                        selection
                        placeholder={'Wybierz'}
                        options={[
                          { text: 'Fabryka', value: 'isTakeAway' },
                          { text: 'Restauracja', value: 'isRestaurant' },
                          { text: 'Restauracja i Fabryka', value: 'isBoth' },
                        ]}
                        onChange={(e, { value }) => {
                          value === 'isTakeAway' &&
                            setState((prevState) => ({
                              ...prevState,
                              isTakeAway: true,
                              isRestaurant: false,
                            }))
                          value === 'isRestaurant' &&
                            setState((prevState) => ({
                              ...prevState,
                              isTakeAway: false,
                              isRestaurant: true,
                            }))
                          value === 'isBoth' &&
                            setState((prevState) => ({
                              ...prevState,
                              isTakeAway: true,
                              isRestaurant: true,
                            }))
                        }}
                      />
                    </Ref>
                  </Field>

                  <Field width="7">
                    <label>Wybierz rodzaj produktu:</label>
                    <Dropdown
                      fluid
                      selection
                      name={'type'}
                      placeholder={'Wybierz'}
                      options={productType}
                      value={state.type}
                      onChange={(e, { value }) =>
                        setState((prevState) => ({ ...prevState, type: value }))
                      }
                    />
                  </Field>
                </div>

                <Field width="16">
                  <label>Opis:</label>
                  <TextArea
                    fluid
                    selection
                    name={'description'}
                    placeholder={'Opis'}
                    value={state.description}
                    onChange={(e, { value }) =>
                      setState((prevState) => ({
                        ...prevState,
                        description: value,
                      }))
                    }
                  />
                </Field>
                <label> Produkty </label>
                <ProductSelect
                  addProduct={addProduct}
                  removeProduct={removeProduct}
                  totalPieces={getTotalPieces()}
                  selectedProducts={state.products}
                  selectOptions={getSelectOptions()}
                />

                <Field
                  name={'numOfExtraPieces'}
                  value={state.numOfExtraPieces}
                  onChange={handleChange}
                  label={'Liczba dodatkowych kawałków'}
                  placeholder={'100zł'}
                  control={Input}
                  min={0}
                  type={'number'}
                  icon={'plus'}
                />
                <Field width={16} className={classes.SwitchContainer}>
                  <div>
                    <Icon name="eye" /> Wyróżniony
                  </div>

                  <Radio
                    toggle
                    checked={state.isPin}
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isPin: !state.isPin,
                      }))
                    }
                  />
                </Field>

                <Button
                  primary
                  onClick={onSubmit}
                  loading={isLoading}
                  disabled={!validateForm()}
                >
                  {!isEditing ? 'Dodaj zestawy' : 'Aktualizuj'}
                </Button>

                {isEditing && (
                  <Button onClick={onCancelEdit} color="red">
                    Anuluj
                  </Button>
                )}
              </Form>
            </Column>

            <Column>
              <MenuList>
                {sets &&
                  sets.map((set) => (
                    <Set
                      key={set.name}
                      set={set}
                      onEdit={onEdit}
                      onDelete={onShowDeleteItem}
                    />
                  ))}
              </MenuList>
            </Column>
          </Row>
        </Grid>
      </Container>
    </>
  )
}
