import React, { useState } from 'react'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

import { Button } from 'semantic-ui-react'
import { Modal } from 'src/components'

import { notifyError } from 'src/notification'
import { fetchSummaryRestaurantOrder } from 'src/api/rest/order'
import { exportCSVTablet } from 'src/utils/exportCSVTablet'

const RoutimoModal = ({ isActive, onClose }) => {
  const [date, setDate] = useState(moment())

  function onGenerateExcel() {
    const fetchSummaryRestaurant = async () => {
      try {
        const response = await fetchSummaryRestaurantOrder()
        if (!response) {
          notifyError(
            'Error generating excel',
            'Nie można wygenerować raportu dla wersji Tablet.',
          )
          return
        }

        exportCSVTablet(response)
      } catch (error) {
        notifyError(
          'No new orders exist',
          'Nie ma żadnych danych do wygenerowania excela.',
        )
      }
    }
    fetchSummaryRestaurant()
  }

  return (
    <Modal
      title="Generacja raportu excel dla Tabletu2"
      show={isActive}
      onHide={onClose}
      footer={[
        <Button onClick={onGenerateExcel} primary>
          Wygeneruj excel dla Tabletu
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 500 }}>
        <br />

        <SingleDatePicker
          focused
          firstDayOfWeek={1}
          numberOfMonths={1}
          date={moment(date)}
          displayFormat={() => 'DD/MM/YYYY'}
          onFocusChange={() => {}}
          onDateChange={setDate}
          isOutsideRange={(date) =>
            !date.isSameOrAfter(moment().startOf('day'))
          }
        />
      </div>
    </Modal>
  )
}

export default RoutimoModal
