const bwtLocations = {
  factory: 'factory',
  restaurant: 'restaurant',
}

export default bwtLocations

export const getBwtLocationTranslation = (location) => {
  const options = {
    [bwtLocations.factory]: 'Fabryka',
    [bwtLocations.restaurant]: 'Restauracja',
  }

  return options[location]
}
