import React, { useState } from 'react'
import { Radio, Button } from 'semantic-ui-react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { Select, Modal, Input } from 'src/components'

import { statusOptions, allStatusOptions } from './constants'
import { getDateWithHour } from 'src/utils/formatDate'
import {
  addStatus,
  addCancelingReason,
  updateOrder,
} from 'src/redux/order/reducer'

import { releaseBWTBlock } from 'src/redux/bwt/actions'

import statusValue from 'src/constants/types/orderStatus'
import { setUserData as setUserDataRedux } from 'src/redux/order/reducer'
import paymentTypes from 'src/constants/types/payment'

import classes from './OrderStatusHistory.module.scss'
import { notifyError } from 'src/notification'
import { copyOrderToOriginal, setCopyOfOrder } from 'src/redux/orderCopy'

const cancelingOptions = {
  cancelWithReason: 0,
  cancelAndCopy: 1,
}

export default function OrderStatusHistory({ errorFields }) {
  const {
    status: orderStatus,
    addedStatus,
    isLoading,
  } = useSelector((state) => state.order)
  const [status, setStatus] = useState('')
  const [addingStatus, setAddingStatus] = useState(false)

  const [cancelingReason, setCancelingReason] = useState('')
  const [cancelingOrder, setCancelingOrder] = useState(false)
  const [cancelingOption, setCancelingOption] = useState(
    cancelingOptions.cancelWithReason,
  )

  const dispatch = useDispatch()

  function setPaymentStatus() {
    if (status.length > 0 && status === statusValue.waitingForPayment.EN) {
      dispatch(setUserDataRedux({ ['paymentType']: paymentTypes.online }))
    }
  }

  function appendStatus() {
    const newStatus = { status, timestamp: Date.now() }
    dispatch(addStatus(newStatus))
    //Show popup asking for the reason of the cancelling

    if (status === statusValue.canceled.EN) {
      setCancelingOrder(true)
    }

    setStatus(undefined)
    setAddingStatus(false)
    setPaymentStatus()
  }

  function handleSelectChange(e) {
    /**
     * Prevent adding new status after already adding one before, this causes error with printing and BWT
     * We need to move forward the status of the order in order to keep everything updated.
     */
    if (addedStatus) {
      notifyError(
        'This status has already been added',
        'Status został dodany. Zaakceptuj zmiany.',
      )
      return
    }
    setAddingStatus(true)
    setStatus(e.currentTarget.value)
  }

  async function onCancelOrder() {
    if (cancelingOption === cancelingOptions.cancelAndCopy) {
      //Make a cache of the order to copy back leter
      dispatch(setCopyOfOrder())

      dispatch(addCancelingReason('ZMIANA TERMINU'))
      //Update the current order and cancel it
      await dispatch(updateOrder())

      //Copy the cached order to the form
      dispatch(copyOrderToOriginal())
    } else {
      dispatch(addCancelingReason(cancelingReason))
    }
    // dispatch(releaseBWTBlock())
    setCancelingOrder(false)
  }

  return (
    <div className={classes.OrderStatusHistorySection}>
      <Col xs={7} className={classes.OrderTimestampHeader}>
        <div className={classes.HeadingTertiary}>Zdefiniuj status</div>
      </Col>

      <Col xs={5} className={classes.OrderStatus}>
        <Select
          icon={faPlus}
          placeholder={'Wybierz'}
          options={statusOptions}
          selectedOption={status}
          onChange={handleSelectChange}
          error={errorFields.includes('status')}
        />
      </Col>

      {orderStatus && (
        <div className={classes.StatusListContainer}>
          {orderStatus.map((element, index) => (
            <Row key={`stat-${index}`} className={classes.StatusItem}>
              <Col xs={7}>{getDateWithHour(element.timestamp)}</Col>
              <Col xs={5} className={classes.OrderStatus}>
                {
                  allStatusOptions.find((item) => item.value === element.status)
                    .label
                }
              </Col>
            </Row>
          ))}
        </div>
      )}

      {addingStatus && (
        <Modal
          centered
          size={'sm'}
          show={true}
          title="Dodaj Status"
          footer={[
            <Button onClick={appendStatus} primary>
              Dodaj status
            </Button>,
          ]}
          onHide={() => setAddingStatus(false)}
        >
          Czy chcesz dodać status do zamówienia?
        </Modal>
      )}

      {cancelingOrder && (
        <Modal
          centered
          size={'sm'}
          title="Wybierz sposób anulowania"
          show={true}
          isLoading={isLoading}
          footer={[
            <Button
              onClick={onCancelOrder}
              loading={isLoading}
              disabled={isLoading}
              primary
            >
              <FontAwesomeIcon icon={faCheck} /> OK
            </Button>,
          ]}
          onHide={() => setCancelingOrder(false)}
        >
          <Radio
            label="Anulowanie zamówienia"
            checked={cancelingOption === cancelingOptions.cancelWithReason}
            onClick={() =>
              setCancelingOption(cancelingOptions.cancelWithReason)
            }
          />

          <Input
            icon={faQuestion}
            label="Przyczyna anulowania"
            value={cancelingReason}
            onChange={(e) => setCancelingReason(e.target.value)}
          />

          <Radio
            label="Zmiana terminu zamówienia"
            checked={cancelingOption === cancelingOptions.cancelAndCopy}
            onClick={() => setCancelingOption(cancelingOptions.cancelAndCopy)}
          />
        </Modal>
      )}
    </div>
  )
}
