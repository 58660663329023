import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setUserData as setUserDataRedux } from 'src/redux/order/reducer'

import classes from './SearchLocationInput.module.scss'

let autoComplete

const loadScript = (url, callback) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

function SearchLocationInput({
  label,
  value,
  onChange,
  icon,
  help,
  error,
  type = 'text',
  ...rest
}) {
  const autoCompleteRef = useRef(null)
  const dispatch = useDispatch()

  function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ['address'], componentRestrictions: { country: 'pl' } },
    )
    autoComplete.setFields([
      'address_components',
      'formatted_address',
      'street_number',
      'floor',
    ])
    autoComplete.addListener('place_changed', () => handlePlaceSelect())
  }

  async function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace()
    const query = addressObject.name.split(',').slice(0, -1).join()
    dispatch(setUserDataRedux({ ['address']: query }))
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`,
      () => handleScriptLoad(autoCompleteRef),
    )
  }, [])

  return (
    <FormGroup className={classes.Wrapper}>
      {label && <ControlLabel> {label} </ControlLabel>}
      <div className={classes.FormGroup}>
        <input
          className={`${classes.Input} ${error && classes.Error}`}
          ref={autoCompleteRef}
          type={type}
          value={value}
          onChange={onChange}
          {...rest}
        />
        {icon && <FontAwesomeIcon className={classes.Icon} icon={icon} />}
      </div>
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  )
}
export default SearchLocationInput
