import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import BottomBar from '../BottomBar'
import { BWTTable, LoadingIcon } from 'src/components'
import * as classes from '../TimeTable.module.scss'
import { fetchBWT, setSelectedIndexes } from 'src/redux/bwt/actions'
import { notifyError } from 'src/notification'

export default function () {
  const bwt = useSelector((state) => state.bwt)
  const reservation = useSelector((state) => state.order.userData.reservation)
  const { isLoading, location, date, selectedIndexes } = bwt
  const dispatch = useDispatch()

  useEffect(() => {
    if (date && location) dispatch(fetchBWT())
  }, [date, location])

  function onSelectRow(rowIndex) {
    if (reservation.isReserved) {
      notifyError('Please release previous block.', 'Zwolnij blok czasowy.')
      return
    }
    dispatch(setSelectedIndexes([rowIndex]))
  }

  return (
    <div className={classes.BWTTable}>
      {isLoading && <LoadingIcon size="small" />}

      {!isLoading && bwt.timeTable.length > 0 && (
        <>
          <BWTTable
            disablePastHours
            bwt={bwt}
            onSelectRow={onSelectRow}
            selectedIndexes={selectedIndexes}
          />

          <BottomBar />
        </>
      )}
    </div>
  )
}
