import { searchOrder } from 'src/api/rest/order'
import {
  SET_COUNT,
  SET_CURRENT_PAST_ORDER_COUNT,
  SET_FUTURE_ORDERS,
  SET_FUTURE_PAGE,
  SET_LOADING,
  SET_LOCATION,
  SET_PAST_ORDERS,
  SET_PAST_PAGE,
} from './types'

export const setPastPage = (page) => ({ type: SET_PAST_PAGE, payload: page })

export const setFuturePage = (page) => ({
  type: SET_FUTURE_PAGE,
  payload: page,
})

export const setFutureOrders = (orders) => ({
  type: SET_FUTURE_ORDERS,
  payload: orders,
})

export const setPastOrders = (orders) => ({
  type: SET_PAST_ORDERS,
  payload: orders,
})

export const setLoading = (boolean) => ({
  type: SET_LOADING,
  payload: boolean,
})

export const setOrderCount = (count) => ({
  type: SET_COUNT,
  payload: count,
})

export const setLocation = (location) => ({
  type: SET_LOCATION,
  payload: location,
})

export const setCurrentPastOrderCount = (count) => ({
  type: SET_CURRENT_PAST_ORDER_COUNT,
  payload: count,
})

export function increaseTotalPastOrder() {
  return (dispatch, getState) => {
    const {
      totalPastOrdersCount,
      currentPastOrderCount,
    } = getState().ordersHistory
    const total = Math.min(currentPastOrderCount + 100, totalPastOrdersCount)

    dispatch(setCurrentPastOrderCount(total))
  }
}

export function resetTables() {
  return (dispatch) => {
    dispatch(setPastPage(1))
    dispatch(setFuturePage(1))
    dispatch(setCurrentPastOrderCount(100))
  }
}

export function searchOrdersHistory(searchFilter = '') {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { currentPastOrderCount, location } = getState().ordersHistory
      dispatch(setLoading(true))

      try {
        const result = await searchOrder(
          searchFilter,
          location,
          0,
          currentPastOrderCount,
        )

        const { status, future = [], past = [], totalOrder } = result
        if (status) {
          //status returns if it has found some data.
          // dispatch(setFutureOrders(future))
          dispatch(setPastOrders(past))
          dispatch(setOrderCount(totalOrder))
        } else {
          // dispatch(setFutureOrders([]))
          dispatch(setPastOrders([]))
          dispatch(setOrderCount(0))
        }
        resolve(result)
      } catch (error) {
        reject(error)
      }
      dispatch(setLoading(false))
    })
  }
}
export function searchFutureOrdersHistory(searchFilter = '') {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { currentPastOrderCount, location } = getState().ordersHistory
      dispatch(setLoading(true))

      try {
        const result = await searchOrder(
          searchFilter,
          location,
          0,
          currentPastOrderCount,
        )

        const { status, future = [], past = [], totalOrder } = result
        if (status) {
          //status returns if it has found some data.
          dispatch(setFutureOrders(future))
          // dispatch(setPastOrders(past))
          dispatch(setOrderCount(totalOrder))
        } else {
          dispatch(setFutureOrders([]))
          // dispatch(setPastOrders([]))
          dispatch(setOrderCount(0))
        }
        resolve(result)
      } catch (error) {
        reject(error)
      }
      dispatch(setLoading(false))
    })
  }
}
