import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Switch from 'react-switch'
import DateTime from 'react-datetime'
import { Button } from 'semantic-ui-react'

import { Modal } from 'src/components'
import { printStatus } from './constants'
import formatDate, { formatTypes } from 'src/utils/formatDate'

import { ReactComponent as ChefIcon } from 'src/img/chef.svg'
import { ReactComponent as UserIcon } from 'src/img/user.svg'
import { notifyError, notifySuccess } from 'src/notification'

import {
  printSingleHourOrdersDelivery,
  printSingleHourOrdersProduction,
} from 'src/api/rest/printer'

import * as classes from './OrdersHistory.module.scss'

const GroupPrint = ({ onClose }) => {
  const [selectedPrintTime, setSelectedPrintTime] = useState(
    new Date().setMinutes(0, 0, 0),
  )
  const [printMode, setPrintMode] = useState(printStatus.delivery)
  const [isLoading, setIsLoading] = useState(false)
  const { location } = useSelector((state) => state.ordersHistory)

  const isActive = (status) => (status !== printMode ? classes.active : '')

  function onChangePrintMode() {
    printMode === printStatus.production
      ? setPrintMode(printStatus.delivery)
      : setPrintMode(printStatus.production)
  }

  async function onPrint() {
    setIsLoading(true)

    try {
      const date = new Date(selectedPrintTime)
      const printDate = formatDate(date, '-', formatTypes.ddmmyyyy)
      const printHour = date.getHours()

      printMode === printStatus.production
        ? await printSingleHourOrdersProduction(printHour, printDate, location)
        : await printSingleHourOrdersDelivery(printHour, printDate, location)

      notifySuccess(
        `Drukowanie zamówień z godziny ${printHour}-${printHour + 1}`,
      )
    } catch (error) {
      notifyError(
        'Error printing',
        'Coś poszło nie tak z wydrukiem zamówienia.',
        error,
      )
    }

    setIsLoading(false)
  }

  return (
    <Modal
      show={true}
      title="Wydruk grupowy zamówień"
      footer={
        <Button loading={isLoading} onClick={onPrint} primary>
          Drukuj
        </Button>
      }
      onHide={onClose}
    >
      <p>
        Wybierz rodzaj wydruków oraz datę i przedział godzinowy. Spowoduje to
        wydruk wszystkich zamówień ze statusem „Potwierdzone” dla zdefiniowanego
        przedziału.
      </p>

      <div className={classes.DateWrapper}>
        <p>Data oraz przedział godzinowy</p>
        <DateTime
          value={selectedPrintTime}
          dateFormat={'DD/MM/YYYY'}
          onChange={(date) => setSelectedPrintTime(date)}
          timeConstraints={{
            minutes: { step: 60 },
          }}
        />
      </div>

      <div className={classes.Switch}>
        <div className={`${classes.icon} ${isActive(printStatus.production)}`}>
          Wydruki dla klienta <UserIcon />
        </div>

        <Switch
          checkedIcon={false}
          onColor={'#46b8da'}
          offColor={'#46b8da'}
          uncheckedIcon={false}
          onChange={onChangePrintMode}
          checked={printStatus.production === printMode}
        />

        <div className={`${classes.icon} ${isActive(printStatus.delivery)} `}>
          <ChefIcon /> Wydruki dla kuchni
        </div>
      </div>
    </Modal>
  )
}

export default GroupPrint
