//@ts-check
import { API } from 'aws-amplify'
import api, { apiName } from './constants'

/**
 * Check if the system is off or on for the entered date.
 * @param {Date} startDate - The starting date to distable the system, date format should be DD-MM-YYYY
 * @param {Date} endDate - The end date to distable the system, date format should be DD-MM-YYYY
 * @return {Promise} - The starting date to distable, date format should be DD-MM-YYYY
 */

export async function disableSystem(startDate, endDate) {
  try {
    //Date parameters should be in DD-MM-YYYY format
    const params = {
      body: {
        stop: endDate,
        start: startDate,
      },
    }
    return await API.post(apiName, `${api.administration}`, params)
  } catch (error) {
    throw error
  }
}

export async function getDisabledDays() {
  try {
    return await API.get(apiName, `${api.administration}`, {})
  } catch (error) {
    throw error
  }
}

/**
 * Check if the system is off or on for the entered date.
 * @param {Date} date - The date to check, Date format should be DD-MM-YYYY
 * @return {Promise} - A promise that resolves to an boolean if system is working for the date entered.
 */
export async function getSystemStatus(date) {
  try {
    return await API.get(apiName, `${api.administration}/${date}`, {})
  } catch (error) {
    throw error
  }
}

/**
 * Check if the system is off or on for the entered date.
 * @param {Date} date - The date to check, Date format should be DD-MM-YYYY
 * @return {Promise} - A promise that resolves to an boolean if system is working for the date entered.
 */
export async function deleteSystemStatus(date) {
  try {
    return await API.del(apiName, `${api.administration}/${date}`, {})
  } catch (error) {
    throw error
  }
}

export async function setWelcomeText(message, isVisible, isSystemClose) {
  try {
    const request = {
      body: {
        isVisible,
        text: message,
        isSystemClose,
      },
    }
    return await API.put(apiName, `${api.welcomeText}/`, request)
  } catch (error) {
    throw error
  }
}

export async function getWelcomeText() {
  try {
    return await API.get(apiName, `${api.welcomeText}/`, {})
  } catch (error) {
    throw error
  }
}
