import bwtLocations from 'src/constants/views/bwtLocations'
import {
  SET_BWT,
  UPDATE_TABLE,
  SET_LOADING,
  SET_LOCATION,
  SET_DATE,
  SET_COUNT,
  SET_INDEXES,
} from './constants'
import formatDate, { formatTypes } from 'src/utils/formatDate'

const initState = {
  id: '',
  date: formatDate(new Date(), '-', formatTypes.yyyymmdd),
  location: bwtLocations.factory,
  startHour: undefined,
  timeTable: [],
  isLoading: false,
  count: 0,
  selectedIndexes: [],
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SET_INDEXES]: () => ({
      ...state,
      selectedIndexes: payload,
    }),

    [SET_LOADING]: () => ({
      ...state,
      isLoading: payload,
    }),

    [SET_COUNT]: () => ({
      ...state,
      count: payload,
    }),

    [SET_DATE]: () => ({
      ...state,
      date: payload,
    }),

    [SET_LOCATION]: () => ({
      ...state,
      location: payload,
    }),

    [SET_BWT]: () => ({
      ...state,
      id: payload.id,
      date: payload.date,
      location: payload.location,
      startHour: payload.startHour,
      timeTable: payload.timeTable,
    }),

    [UPDATE_TABLE]: () => ({ ...state, timeTable: payload }),
  }

  return cases[type] ? cases[type]() : state
}
