import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap'

import { Button } from 'semantic-ui-react'
import { signIn } from 'src/api/rest/auth'
import { signIn as signInRedux } from 'src/redux/auth'

import classes from './Login.module.scss'
import { notifyError } from 'src/notification'

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  function validateForm() {
    return username.length > 0 && password.length > 0
  }

  async function login() {
    setIsLoading(true)
    try {
      const userData = await signIn(username, password)
      dispatch(signInRedux(userData))
    } catch (error) {
      notifyError('Error login into account', 'Błąd podczas logowania.', error)
    }
    setIsLoading(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    await login()
  }

  return (
    <div className={classes.Login}>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel>Użytkownik </ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Hasło</ControlLabel>
          <FormControl
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            type="password"
          />
        </FormGroup>
        <Button
          primary
          fluid
          type="submit"
          loading={isLoading}
          disabled={!validateForm()}
        >
          Logowanie
        </Button>
      </form>
    </div>
  )
}
