import { API } from 'aws-amplify'
import api, { apiName } from './constants'

export async function addOffer(id, name, from, till, cycle, menu) {
  const request = {
    body: {
      id,
      name,
      from,
      till,
      cycle,
      menu,
    },
  }
  try {
    return await API.post(apiName, api.carousel, request)
  } catch (error) {
    throw error
  }
}

export async function editOffer(id, name, from, till, cycle, menu) {
  const request = {
    body: {
      id,
      name,
      from,
      till,
      cycle,
      menu,
    },
  }
  try {
    return await API.put(apiName, api.carousel, request)
  } catch (error) {
    console.error('Something went wrong when editting special offer', error)
  }
}
export async function deleteOffer(id) {
  try {
    return await API.del(apiName, `${api.carousel}/${id}`, {})
  } catch (error) {
    console.error('Something went wrong when deleting special offer', error)
  }
}

export async function getOffers() {
  try {
    return await API.get(apiName, `${api.carousel}`, {})
  } catch (error) {
    console.error('Something went wrong when getting special offers', error)
  }
}
