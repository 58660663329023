import React from 'react'
import classes from './HistoryCard.module.scss'

export default function HistoryCard({ createdAt, price, orders }) {
  return (
    <div className={classes.HistoryCardContainer}>
      <div className={classes.Info}>
        <h4> Data zamowienia: {new Date(createdAt).toLocaleDateString()} </h4>
        <p> {price} zl </p>
      </div>
      <ol>
        {orders &&
          orders.map((order, index) => (
            <li key={`history-card-${index}`}>{order.name}</li>
          ))}
      </ol>
    </div>
  )
}
