import React, { useState } from 'react'
import moment from 'moment'
import { isInclusivelyBeforeDay, DateRangePicker } from 'react-dates'
import { postStorage } from 'src/api/rest/storage'

import { Button, Message, Checkbox } from 'semantic-ui-react'
import { Modal } from 'src/components'
import { doublizeNumber } from 'src/utils/formatDate'
import { notifyError } from 'src/notification'
import { exportRaportCSV } from 'src/utils/exportRaportCSV'

const initialState = {
  start: '',
  stop: '',
  location: '',
}

const RaportGeneratorModal = ({ isActive, onClose, onHide }) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [storageState, setStorageState] = useState({})
  const [placeState, setPlaceState] = useState(false)

  async function onGenerateExcel() {
    try {
      const storageData = await postStorage(storageState)
      exportRaportCSV(
        storageData,
        storageState.start,
        storageState.stop,
        placeState ? 'RESTAURACJA' : 'FABRYKA',
      )
      setStorageState(initialState)
      setStartDate(null)
      setEndDate(null)
      onHide()
    } catch (error) {
      notifyError(
        'Something when wrong when fetching effecting items',
        'A może by tak wybrać zakres generowanego raportu? ;)',
        error,
      )
    }
  }

  function formatDateType(date) {
    const formattedDate = new Date(date)
    const day = formattedDate.getDate()
    const month = formattedDate.getMonth() + 1
    const year = formattedDate.getFullYear()

    return `${doublizeNumber(day)}-${doublizeNumber(month)}-${year}`
  }

  function handleDatesChange({ startDate, endDate }) {
    setStartDate(startDate)
    setEndDate(endDate)

    setStorageState({
      start: formatDateType(startDate),
      stop: formatDateType(endDate),
      location: placeState ? 'restaurant' : 'factory',
    })
  }

  return (
    <Modal
      title="Generacja raportu excel"
      show={isActive}
      onHide={onClose}
      footer={[
        <Button onClick={onGenerateExcel} primary>
          Wygeneruj excel
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 500 }}>
        <Message info>
          <Message.Header>Informacja!</Message.Header>
          <Message size="small">
            Raport z dzisiejszego dnia generowany jest po godzinie 23:00!
          </Message>
          <Message size="small">
            Dzisiejsza data nie zostanie uwzględniona w raporcie!
          </Message>
        </Message>
        <DateRangePicker
          startDate={startDate}
          startDateId="tata-start-date"
          endDate={endDate}
          endDateId="tata-end-date"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          minimumNights={0}
        />
        <Checkbox
          onClick={() => setPlaceState(!placeState)}
          checked={placeState}
          toggle
          label={placeState ? 'restaurant' : 'factory'}
        />
      </div>
    </Modal>
  )
}

export default RaportGeneratorModal
