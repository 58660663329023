export default {
  apiKey: 'AIzaSyBe-JeXKq4zHwUmNd9YBUr5ZUTIN5YoABo',
  authDomain: 'dysza-mailing.firebaseapp.com',
  databaseURL: 'https://dysza-mailing.firebaseio.com',
  projectId: 'dysza-mailing',
  storageBucket: 'dysza-mailing.appspot.com',
  messagingSenderId: '500730916502',
  appId: '1:500730916502:web:f1f4e0819f8152842bdcb7',
  measurementId: 'G-0GEMWP4K8D',
}
