import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FormControl,
  ControlLabel,
  HelpBlock,
  FormGroup,
} from "react-bootstrap"

import classes from "./Input.module.scss"

export default function Input({
  className,
  id,
  label,
  icon,
  error,
  help,
  value,
  onChange,
  ...rest
}) {
  return (
    <FormGroup
      autoComplete={"off"}
      className={className ? `${className}` : `${classes.Wrapper}`}
      controlId={id}
    >
      {label && <ControlLabel> {label} </ControlLabel>}
      <div className={classes.FormGroup}>
        <FormControl
          autoComplete={"off"}
          className={`${classes.Input} ${error && classes.Error}`}
          value={value}
          onChange={onChange}
          {...rest}
        />
        {icon && <FontAwesomeIcon className={classes.Icon} icon={icon} />}
      </div>
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  )
}
