import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

import { Button } from 'semantic-ui-react'
import { notifyError, notifySuccess } from 'src/notification'
import { BWTTable, LoadingIcon, Counter } from 'src/components'
import {
  getBWT,
  getDefaultBWT,
  updateDefaultBWT,
  putBWT,
} from 'src/api/rest/bwt'

import { tableViews } from 'src/constants/views'
import bwtLocations from 'src/constants/views/bwtLocations'
import formatDate, { formatTypes } from 'src/utils/formatDate'

import * as classes from './Table.module.scss'
import { clone } from 'src/utils'

export default function ({ onHide, tableMode }) {
  const [focused, setFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState(undefined)
  const [date, setDate] = useState(Date.now())
  const [location, setLocation] = useState(bwtLocations.factory)
  const [counter, setCounter] = useState(0)
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    fetchBWT()

    return () => {
      setTableData(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, location])

  async function fetchBWT() {
    setIsLoading(true)

    const data =
      tableMode === tableViews.custom
        ? await getBWT(formatDate(date, '-', formatTypes.ddmmyyyy), location)
        : await getDefaultBWT(location)
    setTableData(data)
    setIsLoading(false)
  }

  async function onUpdateBWT() {
    try {
      tableMode === tableViews.default
        ? await updateDefaultBWT(tableData)
        : await putBWT(tableData)

      notifySuccess('Data w BWT została zaktualizowana.')
      onHide()
    } catch (error) {
      notifyError(
        'Error updating BWT',
        'Coś poszło nie tak podczas aktualizacji BWT.',
        error,
      )
    }
  }

  function onAddColumnAfter() {
    const cloned = clone(tableData)
    cloned.timeTable.push(newRow())
    setTableData(cloned)
  }

  function onAddColumnBefore() {
    const cloned = clone(tableData)
    cloned.timeTable.unshift(newRow())
    cloned.startHour -= 1
    setTableData(cloned)
  }

  function newRow() {
    return {
      totalTaken: 0,
      takenBlocks: [],
      totalReserved: 0,
      reservedBlocks: [],
      totalBlocks: 10,
    }
  }

  function onRemoveHour(index) {
    const cloned = clone(tableData)
    cloned.timeTable = [
      ...cloned.timeTable.slice(0, index),
      ...cloned.timeTable.slice(index + 1, cloned.timeTable.length),
    ]
    if (index === 0) cloned.startHour += 1
    setTableData(cloned)
  }

  function onSelectRow(index) {
    const foundIndex = selectedRows.findIndex((row) => row === index)
    foundIndex === -1
      ? setSelectedRows([...selectedRows, index])
      : setSelectedRows([
          ...selectedRows.slice(0, foundIndex),
          ...selectedRows.slice(foundIndex + 1, selectedRows.length),
        ])
  }

  function getColor(loc) {
    return loc === location ? 'blue' : ''
  }

  function onChangeCounter(count) {
    if (!count || count < 0) {
      count = 0
    }

    //Set all the selected rows total blocks to here
    const cloned = clone(tableData)
    selectedRows.forEach((index) => {
      cloned.timeTable[index].totalBlocks = parseInt(count)
    })
    setCounter(parseInt(count))
    setTableData(cloned)
  }

  if (isLoading) return <LoadingIcon />

  return (
    <div className={classes.ModalContainer}>
      <div className={classes.TableHeader}>
        <SingleDatePicker
          focused={focused}
          firstDayOfWeek={1}
          numberOfMonths={1}
          date={moment(date)}
          isOutsideRange={() => false}
          displayFormat={() => 'DD/MM/YYYY'}
          onDateChange={(date) => setDate(date.valueOf())}
          onFocusChange={({ focused }) => setFocused(focused)}
        />

        <Button.Group size="massive">
          <Button
            color={getColor(bwtLocations.factory)}
            onClick={() => setLocation(bwtLocations.factory)}
          >
            Fabryka
          </Button>

          <Button
            color={getColor(bwtLocations.restaurant)}
            onClick={() => setLocation(bwtLocations.restaurant)}
          >
            Restauracja
          </Button>
        </Button.Group>
      </div>

      <Button fluid positive icon="plus" onClick={onAddColumnBefore} />

      <br />

      <BWTTable
        removeAble
        bwt={tableData}
        onRemove={onRemoveHour}
        onSelectRow={onSelectRow}
        selectedIndexes={selectedRows}
      />

      <Button fluid positive icon="plus" onClick={onAddColumnAfter} />

      <Counter
        counter={counter}
        onChangeInput={(e) => onChangeCounter(e.currentTarget.value)}
        increaseCounter={() => onChangeCounter(counter + 1)}
        deacreaseCounter={() => onChangeCounter(counter - 1)}
      />

      <Button onClick={onUpdateBWT} fluid primary>
        Aktualizacja
      </Button>
    </div>
  )
}
