import { getHourinMS } from './formatDate'
/* 
  Return past hour orders.
  ex: Now: 16:05 => returns from 15:00 - 16:00
  ex: Now: 13:58 => return from 12:00 - 13:00
*/
export default function (orders, startTime, endTime) {
  const oneHourInMS = getHourinMS(1)
  endTime = endTime || startTime + oneHourInMS
  return orders.filter((order) => {
    const time = new Date(order.BWT.date)
      .setHours(order.BWT.selectedHour)
      .valueOf()
    return time >= startTime && time < endTime
  })
}

export const getLastHour = () => new Date(Date.now()).setHours(0, 0, 0, 0)
