import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'
import styles from '../DeleteOfferModal/Modal.module.scss'
export default function CollisionModal({
  isModalOpen,
  setIsModalOpen,
  nameList,
}) {
  const namesToRender = nameList.map((item) => (
    <span style={{ color: 'red' }}>{item}, </span>
  ))
  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => setIsModalOpen(false)}
      className={styles.Modal}
      size="mini"
      open={isModalOpen}
      style={{ left: 'auto', top: 'auto' }}
    >
      <Modal.Content>
        <p>Kolizja dat z ofertami:</p>
        {namesToRender}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setIsModalOpen(false)}>
          <Icon name="remove" /> Zamknij
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
