import { getUsersList } from 'src/api/rest/users'

export default async function (username) {
  try {
    const usersList = await getUsersList()
    const user = usersList.find((item) => item.Username === username)
    return user
      ? user.Groups.findIndex((item) => item === 'admins') === -1
        ? false
        : true
      : false
  } catch (error) {
    return false
  }
}
