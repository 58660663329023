import formatDate, { formatTypes } from "src/utils/formatDate"
import { confirmBWTBlock as confirmBlock } from "src/api/rest/bwt"
import { notifyError, notifySuccess } from "src/notification"
import {
  SET_BWT,
  UPDATE_TABLE,
  SET_LOADING,
  SET_LOCATION,
  SET_DATE,
  SET_COUNT,
  SET_INDEXES,
} from "./constants"

import {
  getBWT,
  releaseBWTBlock as releaseBWT,
  reserveBWTBlock as reserveBWT,
  checkFreeBlocks,
  cancelBWTOrder as cancelBWT,
} from "src/api/rest/bwt"
import {
  setReservation,
  clearReservervation,
  clearForm,
  setPlaces,
  setPaymentDate,
} from "src/redux/order/reducer"
import { orderModes, orderTypes } from "src/constants"

//Actions
export const setBWT = (bwtTable) => ({
  type: SET_BWT,
  payload: bwtTable,
})

//Date formate: 'yyyy-mm-dd'
export const setBWTDate = (date) => ({
  type: SET_DATE,
  payload: date,
})

export const setBWTLocation = (location) => ({
  type: SET_LOCATION,
  payload: location,
})

export const setCount = (count) => ({
  type: SET_COUNT,
  payload: count,
})

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
})

export const setSelectedIndexes = (indexes) => ({
  type: SET_INDEXES,
  payload: indexes,
})

export function increaseCounter() {
  return (dispatch, getState) => {
    const { bwt, order } = getState()
    const { reservation } = order.userData
    const { count } = bwt
    if (count >= getEmptyBlocks(bwt, reservation.numberOfBlocks)) return
    dispatch(setCount(Number(count + 1)))
  }
}

export function getEmptyBlocks(bwt, previousReservation = 0) {
  const { selectedIndexes, timeTable } = bwt
  const rowIndex = selectedIndexes[0]
  const row = timeTable[rowIndex]

  const { totalBlocks, totalReserved, totalTaken } = row
  let totalFreeBlocks = totalBlocks - totalReserved - totalTaken

  //Adding currently reserved blocks (if any) by the user as well
  //This can happen when reserved blocks before, then want to increase number of blocks later
  totalFreeBlocks += previousReservation
  return totalFreeBlocks
}

export function decreaseCounter() {
  return (dispatch, getState) => {
    const { count } = getState().bwt
    if (count > 0) dispatch(setCount(count - 1))
  }
}

export function fetchBWT() {
  return async (dispatch, getState) => {
    const { bwt, order } = getState()
    const { date, location } = bwt
    try {
      if (!date || !location) {
        dispatch(setLoading(false))
        return
      }

      dispatch(setLoading(true))
      const formatedDate = formatDate(date, "-", formatTypes.ddmmyyyy)
      const data = await getBWT(formatedDate, location)
      dispatch(setBWT(data))
      dispatch(checkPreviousReservation(data))

      if (order.userData.reservation.isReserved) {
        const index = order.userData.reservation.hour - data.startHour
        dispatch(setSelectedIndexes([index]))
      }
    } catch (error) {
      notifyError(
        "Error when fetching BWT",
        "Błąd podczas pobierania BWT.",
        error,
      )
    }

    dispatch(setLoading(false))
  }
}

export function reserveBWTBlock() {
  return async (dispatch, getState) => {
    try {
      const { bwt, order, auth } = getState()
      const { date, location, count, startHour, selectedIndexes, id } = bwt
      const username = auth.userData.username

      const rowIndex = selectedIndexes[0]
      const hour = rowIndex + startHour
      const previousReservationAmount =
        order.userData.reservation.numberOfBlocks
      const totalCount = count - previousReservationAmount
      const formattedDate = formatDate(date, "-", formatTypes.ddmmyyyy)

      const isFree = await hasFreeBlocks(
        formattedDate,
        location,
        hour,
        totalCount,
      )

      if (!isFree) return

      const { Attributes: updatedBWT } = await reserveBWT(
        id,
        count,
        hour,
        username,
        order.mode === orderModes.confirm ? orderTypes.shop : orderTypes.dysza,
        order.id,
      )
      console.log({ updatedBWT })
      dispatch(setBWT(updatedBWT))
      dispatch(
        setPlaces({
          productionPlace: updatedBWT.location,
          salePlace: updatedBWT.location,
        }),
      )
      dispatch(
        setPaymentDate({
          date: new Date(updatedBWT.date).toISOString().split("T")[0],
        }),
      )
      dispatch(setReservation(hour, formattedDate, location, count, true, true))
    } catch (error) {
      console.error("error", error)
      notifyError(
        "Error while reserving block",
        "Błąd podczas rezerwacji bloku.",
        error,
      )
    }
  }
}

async function hasFreeBlocks(date, location, hour, count) {
  try {
    const { status: isAvailable } = await checkFreeBlocks(
      date,
      location,
      hour,
      count,
    )
    if (!isAvailable) {
      notifyError(
        "This block is not available please refresh the table",
        "Ten blok jest niedostępny, odśwież BWT.",
      )
      return false
    }

    return true
  } catch (error) {
    notifyError(
      "Error while checking for empty blocks",
      "Błąd podczas sprawdzania pustych bloków.",
      error,
    )
  }
}

export function releaseBWTBlock() {
  return async (dispatch, getState) => {
    try {
      const { bwt, auth, order } = getState()
      const { id: bwtID, selectedIndexes, startHour } = bwt
      const hour = startHour + selectedIndexes[0]
      const username = auth.userData.username
      const { Attributes: bwtTable } = await releaseBWT(
        bwtID,
        hour,
        order.id,
        order.mode === orderModes.confirm ? orderTypes.shop : orderTypes.dysza,
        username,
      )
      if (bwtTable !== undefined) {
        dispatch(setBWT(bwtTable))
      }
      dispatch(setCount(0))
      dispatch(clearReservervation())
    } catch (error) {
      console.log(error)
      notifyError(
        "Error while releasing bwt",
        "Błąd podczas zwalniania BWT",
        error,
      )
    }
  }
}

export function confirmBWTBlock() {
  return async (dispatch, getState) => {
    try {
      const { bwt, order, auth } = getState()
      const { location, date, selectedIndexes, startHour } = bwt
      const hour = startHour + selectedIndexes[0]
      const { Attributes: bwtTable } = await confirmBlock(
        order.id,
        auth.userData.username,
        location,
        hour,
        date,
        order.mode === orderModes.confirm ? orderTypes.shop : orderTypes.dysza,
      )
      dispatch(setBWT(bwtTable))
      dispatch(setCount(0))
      notifySuccess("Dodano do BWT.")
    } catch (error) {
      notifyError(
        "Error while confirming BWT",
        "Błąd podczas akceptacji BWT.",
        error,
      )
    }
  }
}

export function updateBWT(data) {
  console.log("updateBWT data:")
  console.log(data)
  return (dispatch) => {
    try {
      dispatch({ type: UPDATE_TABLE, payload: data.timeTable })
      notifySuccess("Zaktualizowano BWT.")
    } catch (error) {
      notifyError(
        "Error while updating BWT",
        "Błąd podczas aktualizacji BWT.",
        error,
      )
    }
  }
}

export function checkCurrentOrderIndex() {
  return (dispatch, getState) => {
    const { bwt, order } = getState()
    const { startHour } = bwt
    const { isReserved, hour } = order.userData.reservation
    if (isReserved) {
      const selectedIndex = hour - startHour
      dispatch(setSelectedIndexes([selectedIndex]))
    }
  }
}

export function checkPreviousReservation(data) {
  return (dispatch, getState) => {
    const { timeTable, date, location } = data
    const username = getState().auth.userData.username
    const reservationIndex = findReservationIndex(timeTable, username)

    //If found a reservation, set it as selected
    if (reservationIndex !== -1) {
      const { numberOfBlocks } = timeTable[
        reservationIndex
      ].reservedBlocks.find(({ workerID }) => workerID === username)
      const hour = data.startHour + reservationIndex

      dispatch(setCount(numberOfBlocks))
      dispatch(setSelectedIndexes([reservationIndex]))
      //DISPATCH TO USERDATA
      const formattedDate = formatDate(date, "-", formatTypes.ddmmyyyy)
      dispatch(
        setReservation(
          hour,
          formattedDate,
          location,
          numberOfBlocks,
          true,
          true,
        ),
      )
    }
  }
}

export function cancelBWTOrder(orderID) {
  console.log("cancelBWTOrder orderID")
  console.log(orderID)
  return async (dispatch) => {
    try {
      // const { Attributes: bwtTable } = await cancelBWT(orderID)
      const { Attributes: bwtTable } = await cancelBWT(orderID)
      console.log("cancelBWTOrder bwtTable:")
      console.log(bwtTable)
      dispatch(updateBWT(bwtTable))
    } catch (error) {
      notifyError(
        "Error when cancelling bwt order",
        "Błąd podczas kasowania zamówienia w BWT.",
        error,
      )
    }
  }
}

//helper functions
export function findReservationIndex(timeTable, username) {
  return timeTable.findIndex((row) => {
    const index = row.reservedBlocks.findIndex(
      (reservation) =>
        reservation.workerID === username &&
        reservation.orderType === orderTypes.dysza,
    )
    if (index !== -1) return true
  })
}

export function clearBWT() {
  return (dispatch) => {
    dispatch(setCount(0))
    dispatch(setSelectedIndexes([]))

    // after cancel button clear bwt & set date for "TODAY"
    const formattedDate = formatDate(Date.now(), "-", formatTypes.yyyymmdd)
    dispatch(setBWTDate(formattedDate))
  }
}
