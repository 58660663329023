import React from 'react'
import { Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { orderModes, orderStatus } from 'src/constants'
import classes from './Bottombar.module.scss'
import { addOrder, updateOrder, clearForm } from 'src/redux/order/actions'

import { notifyError } from 'src/notification'
import { setBWTDate, releaseBWTBlock } from 'src/redux/bwt/actions'
import formatDate, { formatTypes } from 'src/utils/formatDate'

export default function BottomBar({ validateForm }) {
  const dispatch = useDispatch()
  const {
    id: orderID,
    status,
    mode,
    discounts,
  } = useSelector((state) => state.order)
  const { count } = useSelector((state) => state.bwt)

  const { userData } = useSelector((state) => state.order)

  const {
    mode: orderMode,
    items,
    isLoading,
  } = useSelector((state) => state.order)

  function validateDiscount(discounts) {
    if (!discounts) {
      return true
    } else {
      const regex = /^[1-9]$|^[1-9][0-9]$|^(100)$/
      return regex.test(parseInt(discounts))
    }
  }

  function checkOrderValidation() {
    const errorFields = validateForm()
    console.log(errorFields)

    if (!validateDiscount(discounts)) {
      notifyError(
        'Discount value should be in range 1 - 100 ;)',
        'Nie za dużo tej zniżki? ;).',
      )
      return false
    }

    if (errorFields.length) {
      notifyError(
        'Fill missing fields',
        'Wypełnij wszystkie brakujące pola.',
        errorFields,
      )
      return false
    }

    if (!items.length) {
      notifyError(
        'No items added',
        'Żadne zamówienie nie zostało dodane do listy.',
      )
      return false
    }

    if (status.length < 1) {
      notifyError('No status added', 'Status nie został dodany do zamówienia.')
      return false
    }
    return true
  }

  function onAddOrder() {
    if (!checkOrderValidation()) return
    dispatch(addOrder())
  }

  function onUpdateOrder() {
    if (status.length > 0) {
      const lastStatus = status[0].status
      if (lastStatus !== orderStatus.canceled.EN) {
        if (!checkOrderValidation()) return
      }
    }

    dispatch(updateOrder())
  }

  function onCancel() {
    if (
      status.length > 0 &&
      userData.reservation.numberOfBlocks > 0 &&
      !orderID.length &&
      status[0].status !== orderStatus.waitingForPayment.EN
    ) {
      dispatch(releaseBWTBlock())
      // const formattedDate = formatDate(Date.now(), '-', formatTypes.yyyymmdd)
      // dispatch(setBWTDate(formattedDate))
      setTimeout(() => {
        const formattedDate = formatDate(Date.now(), '-', formatTypes.yyyymmdd)
        dispatch(setBWTDate(formattedDate))
      }, 1200)
    }

    if (
      status.length > 0 &&
      status[status.length - 1].status ===
        orderStatus.waitingForConfirmation.EN &&
      userData.reservation.numberOfBlocks > 0 &&
      status[0].status !== orderStatus.waitingForPayment.EN
    ) {
      dispatch(releaseBWTBlock())
      // const formattedDate = formatDate(Date.now(), '-', formatTypes.yyyymmdd)
      // dispatch(setBWTDate(formattedDate))
      setTimeout(() => {
        const formattedDate = formatDate(Date.now(), '-', formatTypes.yyyymmdd)
        dispatch(setBWTDate(formattedDate))
      }, 1200)
    }

    if (userData.reservation.numberOfBlocks === 0) {
      dispatch(clearForm())
      return
    }

    dispatch(clearForm())
    dispatch(releaseBWTBlock())
    setTimeout(() => {
      const formattedDate = formatDate(Date.now(), '-', formatTypes.yyyymmdd)
      dispatch(setBWTDate(formattedDate))
    }, 1200)
  }

  return (
    <div className={classes.Bottombar}>
      {orderMode === orderModes.add && (
        <Button
          primary
          disabled={isLoading}
          loading={isLoading}
          onClick={onAddOrder}
          data-test-id="confirm-order-button"
        >
          Zatwierdź
        </Button>
      )}

      {orderMode === orderModes.edit && (
        <Button
          primary
          disabled={isLoading}
          loading={isLoading}
          onClick={onUpdateOrder}
        >
          Aktualizacja zamówienia
        </Button>
      )}

      {orderMode === orderModes.confirm && (
        <Button
          primary
          disabled={isLoading}
          loading={isLoading}
          onClick={onUpdateOrder}
        >
          Potwierdź zamówienie
        </Button>
      )}

      <Button onClick={onCancel} disabled={isLoading}>
        Anuluj
      </Button>
    </div>
  )
}
