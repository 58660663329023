import React from 'react'
import { Col } from 'react-bootstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from '../../User/Card/UserCard.module.scss'
export default function ProductCard(props) {
  const { index, name, price, id, setActiveProducts } = props

  const handleDelete = () => {
    setActiveProducts((prev) =>
      prev.filter((product) => {
        if (product.id !== id) {
          return product
        }
      }),
    )
  }
  return (
    <div className={classes.Card}>
      <Col xs={1}>{index + 1}.</Col>
      <Col xs={8}>{name}</Col>
      <Col xs={2}>{price} zł</Col>
      <Col xs={1}>
        <FontAwesomeIcon
          size="lg"
          icon={faTimes}
          onClick={() => handleDelete(id)}
          className={classes.CardIcon + ' ' + classes.CrossIcon}
        />
      </Col>
    </div>
  )
}
