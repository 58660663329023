import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import classes from './MenuItem.module.scss'
import { addItem } from 'src/redux/order/reducer'

export default function SetItem({
  id,
  ingredients,
  category,
  name,
  price,
  extraPieces,
  setActiveProducts,
  activeProducts,
}) {
  function addItemToActiveProducts() {
    let test = true
    activeProducts.forEach((product) => {
      if (product.id === id) {
        test = false
      }
    })
    if (activeProducts.length < 5 && test) {
      setActiveProducts((prev) => [...prev, { id, name, price }])
    }
  }

  return (
    <article
      id={category}
      onClick={addItemToActiveProducts}
      className={classes.ItemContainer}
    >
      <Row className={classes.Description}>
        <Col xs={2}>
          <span> ID{id} </span>
        </Col>

        <Col xs={7}>
          <span> {name} </span>
        </Col>

        <Col xs={3} className={classes.Price}>
          <span> {price} PLN</span>
        </Col>
      </Row>

      {ingredients && (
        <div className={classes.IngredientContainer}>
          {ingredients.map((ingredient, index) => (
            <div key={`ingredient-${index}`} className={classes.Ingredient}>
              {ingredient}
            </div>
          ))}
        </div>
      )}

      {extraPieces && parseInt(extraPieces) > 0 ? (
        <span className={classes.ExtraPieces}> {extraPieces} </span>
      ) : (
        <> </>
      )}
    </article>
  )
}
