//@ts-check
import {
  // getDeliveryPrice,
  orderModes,
  orderStatus,
  menuTypes,
  orderTypes,
} from "src/constants"
import { getDeliveryPrices } from "src/api/rest/deliveryPrices"
import {
  SET_DELIVERY_PRICES,
  ADD_ITEM,
  CLEAR_ORDER,
  REMOVE_ITEM,
  SET_ORDER_ITEMS,
  SET_ORDER_ID,
  SET_ORDER_MODE,
  CLEAR_STATUS,
  ADD_STATUS,
  SET_STATUS,
  SET_NUMBER_OF_BLOCKS,
  SET_USER_DATA,
  SET_RESERVATION,
  IS_LOADING,
  CLEAR_RESERVATION,
  ADD_CANCELING_REASON,
  SET_DISCOUNT,
  SET_PLACES,
  SET_PAYMENT_DATE,
} from "./constant"

import { postOrder, putOrder, sendTPayLink } from "src/api/rest/order"
import {
  reserveBWTBlock,
  cancelBWTOrder,
  setBWTDate,
  setBWTLocation,
  confirmBWTBlock,
  clearBWT,
  fetchBWT,
  releaseBWTBlock,
} from "src/redux/bwt/actions"

import { notifyError, notifySuccess } from "src/notification"
import { round, getItemKind, priceCalculator } from "src/utils"
import formatDate, { formatTypes } from "src/utils/formatDate"
import { getShopOrders } from "src/redux/shopOrders/actions"
import { rollBackBWT } from "src/api/rest/bwt"

export function runRollBackBWT() {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const state = getState()
      await dispatch(
        rollBackBWT(
          state.order.id,
          state.auth.userData.username,
          state.bwt.location,
          state.bwt.startHour,
          state.bwt.date,
          state.order.mode === orderModes.confirm
            ? orderTypes.shop
            : orderTypes.dysza,
        ),
      )
      dispatch(fetchBWT())
    } catch (error) {
      notifyError(
        "Error while adding order",
        "Błąd podczas dodawania zamówienia.",
        error,
      )
    }
  }
}

export function addOrder() {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))

    try {
      const state = getState()
      console.log(state)
      const order = await postOrder(
        getNewOrderData(state),
        state.auth.userData.username,
      )
      dispatch(setOrderID(order.id))
      await dispatch(checkOrderStatus())
      dispatch(clearForm())
      dispatch(clearBWT())
      notifySuccess("Zamówienie dodane")
    } catch (error) {
      console.log({ error: error })
      notifyError(
        "Error while adding order",
        "Błąd podczas dodawania zamówienia.",
        error,
      )
      dispatch(runRollBackBWT())
      //tutaj wywolac dodatkowe zabezpieczenie w postaci ENDPOINTA
    }

    dispatch(setLoading(false))
  }
}

export function updateOrder() {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const { order: orderData } = getState()

      const state = getState()
      const order = getUpdatedOrder(orderData, state)
      console.log("putOrder", { order })

      await putOrder(order, order.id, state.auth.userData.username)
      await dispatch(checkOrderStatus())
      dispatch(clearForm())
      dispatch(clearBWT())

      //Fetch latest shop orders when updating one of them
      if (orderData.mode === orderModes.confirm) {
        await dispatch(getShopOrders())
      }

      /* Setting date of BWT back to today, this cause an error where
       * employees forgot to set the date when ordering in future, and misplacing the orders.
       */
      const formattedDate = formatDate(Date.now(), "-", formatTypes.yyyymmdd)
      dispatch(setBWTDate(formattedDate))

      notifySuccess("Zamówienie zaktualizowane")
    } catch (error) {
      notifyError(
        "Error while updating order",
        "Błąd podczas dodawania zamówienia.",
        error,
      )
    }

    dispatch(setLoading(false))
  }
}

export function editHistoryOrder(orderData) {
  return (dispatch) => {
    const { orders, id, status, BWT, discounts } = orderData
    const userData = getUserData(orderData)
    const reservationData = getReservation(BWT)

    const index = status.findIndex(
      (item) => item.status === orderStatus.waitingForConfirmation.EN,
    )
    const orderFromShop = index !== -1

    dispatch(setOrderMode(orderFromShop ? orderModes.confirm : orderModes.edit))
    dispatch(setItemsData(orders))
    dispatch(setDiscount(discounts))
    dispatch(setOrderID(id))
    dispatch(setUserData(userData))
    dispatch(setStatus(status))
    dispatch(
      setReservation(
        reservationData.hour,
        reservationData.date,
        reservationData.location,
        reservationData.numberOfBlocks,
        false,
        true,
      ),
    )
    //SET bwt data
    const formattedDate = formatDate(BWT.date, "-", formatTypes.yyyymmdd)
    dispatch(setBWTDate(formattedDate))
    dispatch(setBWTLocation(BWT.location))
    console.log("editHistoryOrder dispatch ", {
      productionPlace: orderData.productionPlace,
      salePlace: orderData.salePlace,
    })
    dispatch(
      setPlaces({
        productionPlace: orderData.productionPlace,
        salePlace: orderData.salePlace,
      }),
    )

    dispatch(
      setPaymentDate({
        date: orderData.paymentDate
          ? orderData.paymentDate
          : new Date(BWT.date)
              .toLocaleString()
              .split(",")[0]
              .split("/")
              .reverse()
              .join("-"),
      }),
    )
    dispatch(fetchBWT())
  }
}

export function editShopOrder(orderData) {
  const { orders, id, status, discounts } = orderData
  const userData = getUserData(orderData)
  console.log({ orderData })
  return (dispatch) => {
    dispatch(clearForm())
    dispatch(setItemsData(orders))
    dispatch(setOrderID(id))
    dispatch(setUserData(userData))

    dispatch(setDiscount(discounts))

    dispatch(setStatus(status))
    dispatch(setOrderMode(orderModes.confirm))
    console.log({ orderData })
    if (orderData.BWT) {
      const { BWT } = orderData
      const reservationData = getReservation(BWT)
      dispatch(
        setReservation(
          reservationData.hour,
          reservationData.date,
          reservationData.location,
          reservationData.numberOfBlocks,
          false,
          true,
        ),
      )

      const formattedDate = formatDate(BWT.date, "-", formatTypes.yyyymmdd)
      dispatch(setBWTDate(formattedDate))
      dispatch(setBWTLocation(BWT.location))
      console.log("EditShopOrder dispatch ", {
        productionPlace: orderData.productionPlace,
        salePlace: orderData.salePlace,
      })
      dispatch(
        setPlaces({
          productionPlace: orderData.productionPlace,
          salePlace: orderData.salePlace,
        }),
      )

      dispatch(
        setPaymentDate({
          date: orderData.paymentDate
            ? orderData.paymentDate
            : new Date(BWT.date)
                .toLocaleString()
                .split(",")[0]
                .split("/")
                .reverse()
                .join("-"),
        }),
      )
      dispatch(fetchBWT())
    }
  }
}

//Take actions according to the lastest status uploaded
export function checkOrderStatus() {
  return async (dispatch, getState) => {
    const { order } = getState()
    if (!order.addedStatus) {
      return
    }
    const { status, userData } = order
    const latestStatus = status[0].status

    console.log("order in bwt: ")
    console.log(order)

    if (latestStatus === orderStatus.canceled.EN) {
      console.log("here?")
      if (order.userData.reservation.isReserved) {
        console.log("here 2?")
        //If the order is from shop and want to cancel it
        if (order.mode === orderModes.confirm) {
          console.log("here 3?")
          // if (userData.reservation.isReserved) {
          if (
            userData.reservation.isReserved &&
            status[1].status !== orderStatus.waitingForConfirmation.EN
          ) {
            console.log("here 4?")
            await dispatch(cancelBWTOrder(order.id))
          } else {
            console.log("here 5?")
            //you need to just unreserved the bwt (orange blocks)
            await dispatch(releaseBWTBlock())
          }
        } else {
          console.log("here 6?")
          await dispatch(cancelBWTOrder(order.id))
        }
      }
    } else if (latestStatus === orderStatus.confirmed.EN) {
      /**
       * Make confirmation action once, if it has been confirmed before,
       * don't confirm for second time, this will cause error
       */

      //Checking if it has been confirmed before
      if (order.status.length > 1) {
        const secondLastStatus = order.status[1].status
        if (
          secondLastStatus === orderStatus.waitingForPayment.EN &&
          order.mode !== orderModes.confirm
        ) {
          /**
           * Looks like we already have confirmed BWT before,
           * so no need to confirm again, or else it will cause error.
           */
          return
        }
      }
      await dispatch(confirmBWTBlock())
    } else if (latestStatus === orderStatus.waitingForPayment.EN) {
      await sendTPayLink(order.id)
      if (order.mode === orderModes.confirm) {
        // await dispatch(reserveBWTBlock()) // unnecessary endpoint while changing status!!!!!!
      } else {
        await dispatch(confirmBWTBlock())
      }
    }
  }
}

export function clearForm() {
  return (dispatch) => {
    dispatch(clearOrder())
    dispatch(setOrderMode(orderModes.add))
  }
}

export const setLoading = (loading) => ({
  type: IS_LOADING,
  payload: loading,
})
export const setDeliveryPrices = (delivery) => ({
  type: SET_DELIVERY_PRICES,
  payload: delivery,
})
export const addItem = (item) => ({
  type: ADD_ITEM,
  payload: item,
})

export const removeItem = (index) => ({
  type: REMOVE_ITEM,
  payload: index,
})

export const clearOrder = () => ({
  type: CLEAR_ORDER,
})

export const setOrderItems = (items) => ({
  type: SET_ORDER_ITEMS,
  payload: items,
})

export const setOrderID = (id) => ({
  type: SET_ORDER_ID,
  payload: id,
})

export const setOrderMode = (mode) => ({
  type: SET_ORDER_MODE,
  payload: mode,
})

export const setDiscount = (discount) => ({
  type: SET_DISCOUNT,
  payload: discount,
})

export const setPlaces = (places) => ({
  type: SET_PLACES,
  payload: places,
})

export const setPaymentDate = (date) => ({
  type: SET_PAYMENT_DATE,
  payload: date,
})
//Status
export const clearStatus = () => ({ type: CLEAR_STATUS })

export const setStatus = (status) => ({
  type: SET_STATUS,
  payload: status,
})

export const addStatus = (status) => ({
  type: ADD_STATUS,
  payload: status,
})

//Form
export const setUserData = (data) => ({
  type: SET_USER_DATA,
  payload: data,
})

export const setNumberOfBlocks = (numberOfBlocks) => ({
  type: SET_NUMBER_OF_BLOCKS,
  payload: numberOfBlocks,
})

export const addCancelingReason = (reason) => ({
  type: ADD_CANCELING_REASON,
  payload: reason,
})

export const clearReservervation = () => ({ type: CLEAR_RESERVATION })

export const setReservation = (
  hour,
  date,
  location,
  numberOfBlocks,
  isEditable,
  isReserved,
) => ({
  type: SET_RESERVATION,
  payload: { hour, date, location, numberOfBlocks, isEditable, isReserved },
})

//Helper functions
export function getNewOrderData(state) {
  const { order } = state
  const {
    status,
    items,
    userData,
    deliveryPrices,
    salePlace,
    productionPlace,
    paymentDate,
  } = order

  return {
    status,
    discounts: order.discounts,
    orders: getItemIDs(items),
    deliveryPrice:
      userData.deliveryType == "Zone A"
        ? deliveryPrices.zonea
        : userData.deliveryType == "Zone B"
        ? deliveryPrices.zoneb
        : 0,
    // deliveryPrice: getDeliveryPrice(userData.deliveryType),
    price: getTotalPrice(
      items,
      userData.deliveryType,
      order.discounts,
      userData.deliveryType.toLowerCase().includes("zone a")
        ? deliveryPrices.zonea
        : userData.deliveryType.toLowerCase().includes("zone b")
        ? deliveryPrices.zoneb
        : 0,
      userData.boxValue,
    ),
    clientNIP: userData.nip,
    BWT: {
      date: getDate(userData.reservation.date),
      location: userData.reservation.location,
      selectedHour: userData.reservation.hour,
      numberOfBlocks: userData.reservation.numberOfBlocks,
    },
    clientName: userData.name,
    clientEmail: userData.email,
    clientPhone: userData.phone,
    clientAddr: userData.address,
    clientComment: {
      production: userData.productionComment,
      client: userData.clientComment,
    },
    createdAt: Date.now(),
    priority: userData.isPriority,
    paymentType: userData.paymentType,
    deliveryType: userData.deliveryType,
    boxValue: userData.boxValue,
    replacement: userData.replacement,
    salePlace: salePlace,
    productionPlace: productionPlace,
    paymentDate: paymentDate,
  }
}

function getDate(date) {
  //this is a string in dd-mm-yyyy
  const day = date.substr(0, 2)
  const month = date.substr(3, 2)
  const year = date.substr(6, 5)
  const newDate = new Date(year, Number(month) - 1, day)

  return Date.parse(newDate)
}

export function getUpdatedOrder(order, state) {
  const {
    status,
    items,
    userData,
    deliveryPrices,
    salePlace,
    productionPlace,
    paymentDate,
  } = order
  console.log(deliveryPrices)
  return {
    id: order.id,
    status,
    discounts: order.discounts,
    orders: getItemIDs(items),
    // deliveryPrice: getDeliveryPrice(userData.deliveryType),
    deliveryPrice:
      userData.deliveryType == "Zone A"
        ? deliveryPrices.zonea
        : userData.deliveryType == "Zone B"
        ? deliveryPrices.zoneb
        : 0,
    price: getTotalPrice(
      items,
      userData.deliveryType,
      order.discounts,
      userData.deliveryType == "Zone A"
        ? deliveryPrices.zonea
        : userData.deliveryType == "Zone B"
        ? deliveryPrices.zoneb
        : 0,
      userData.boxValue,
    ),
    clientNIP: userData.nip,
    BWT: {
      date: getDate(userData.reservation.date),
      location: userData.reservation.location,
      selectedHour: userData.reservation.hour,
      numberOfBlocks: userData.reservation.numberOfBlocks,
    },
    clientName: userData.name,
    clientEmail: userData.email,
    clientPhone: userData.phone,
    clientAddr: userData.address,
    clientComment: {
      production: userData.productionComment,
      client: userData.clientComment,
    },
    createdAt: Date.now(),
    priority: userData.isPriority,
    paymentType: userData.paymentType,
    deliveryType: userData.deliveryType,
    boxValue: userData.boxValue,
    replacement: userData.replacement,
    salePlace: salePlace,
    productionPlace: productionPlace,
    paymentDate: paymentDate,
  }
}
//

export function setItemsData(items) {
  return (dispatch, getState) => {
    const { ingredients, products, sets } = getState().menu
    const options = {
      [menuTypes.product]: (itemId) => findProductData(products, itemId),
      [menuTypes.sushiSet]: (itemId) => findSetData(sets, itemId),
      [menuTypes.ingredient]: (itemId) =>
        findIngredientData(ingredients, itemId),
    }
    const orderItems = items.reduce((allItems, item) => {
      const itemKind = getItemKind(item.id)
      if (itemKind && options[itemKind]) {
        allItems.push(options[itemKind](item.id))
      }
      console.log({ xd: options[itemKind](item.id) })
      return allItems
    }, [])
    dispatch(setOrderItems(orderItems))
  }

  function findProductData(products, id) {
    const index = products.findIndex((product) => product.id === id)
    console.log({ y: products[index] })
    return products[index]
  }

  function findSetData(sets, id) {
    const index = sets.findIndex((set) => set.id === id)
    return sets[index]
  }

  function findIngredientData(ingredients, id) {
    const index = ingredients.findIndex((ingredient) => ingredient.id === id)
    return ingredients[index]
  }
}

export function getTotalPrice(
  items,
  deliveryType,
  discount,
  deliveryCost,
  boxValue,
) {
  const itemsPrice = priceCalculator(items)
  const deliveryPrice = deliveryType ? deliveryCost : 0
  const discountPercentage = discount ? parseInt(discount) : 0
  console.log({ boxValue: boxValue })
  const boxPrice =
    boxValue && typeof boxValue === "string"
      ? JSON.parse(boxValue)?.reduce(
          (prev, current) => prev + current.price * current.count,
          0,
        )
      : boxValue?.reduce(
          (prev, current) => prev + current.price * current.count,
          0,
        ) ?? 0

  const totalPrice =
    parseFloat(itemsPrice) * parseFloat((100 - discountPercentage) / 100) +
    parseFloat(deliveryPrice) +
    boxPrice
  return round(totalPrice, 2)
}

function getItemIDs(items) {
  return items.map((item) => ({
    id: item.id,
    name: item.name,
    price: item.price,
  }))
}

function getUserData(order) {
  return {
    boxValue: order.boxValue,
    replacement: order.replacement,
    price: order.price,
    paymentType: order.paymentType,
    deliveryType: order.deliveryType,
    nip: order.clientNIP,
    phone: order.clientPhone,
    name: order.clientName,
    email: order.clientEmail,
    address: order.clientAddr,
    isPriority: order.priority,
    clientComment: order.clientComment ? order.clientComment.client : "-",
    productionComment: order.clientComment
      ? order.clientComment.production
      : "-",
  }
}

function getReservation(bwtData, isEditable) {
  const { date, location, selectedHour, numberOfBlocks } = bwtData
  return {
    date: formatDate(date, "-", formatTypes.ddmmyyyy),
    location,
    hour: selectedHour,
    numberOfBlocks,
    isEditable,
  }
}

export const fetchDeliveryPrices = () => {
  try {
    return async (dispatch) => {
      const prices = await getDeliveryPrices()
      dispatch(setDeliveryPrices(prices))
    }
  } catch (err) {
    console.log(err)
  }
}
