//@ts-check
import { API } from "aws-amplify"
import api, { apiName } from "./constants"

export async function fetchProducts() {
  try {
    return await API.get(apiName, api.productMenu, {})
  } catch (error) {
    console.error("Something went wrong when getting products", error)
  }
}

export async function fetchProductsToMenuEdit() {
  try {
    return await API.get(apiName, api.productMenu, {})
  } catch (error) {
    console.error("Something went wrong when getting products", error)
  }
}

export async function postProduct(newProduct) {
  console.log("new product")
  console.log(newProduct)
  try {
    const {
      name,
      ingredients,
      type,
      numberOfPieces,
      category,
      isTakeAway,
      isRestaurant,
      price,
      isVisible,
      imageURL,
      isVisibleInSet,
      description,
      weight,
      unit,
    } = newProduct

    const request = {
      body: {
        name,
        price,
        category,
        isTakeAway,
        isRestaurant,
        type,
        isVisible,
        ingredients,
        numberOfPieces,
        imageURL,
        isVisibleInSet,
        description,
        weight,
        unit,
      },
    }

    return await API.post(apiName, api.productMenu, request)
    // return await API.post(apiName, api.product, request)
  } catch (error) {
    throw error
  }
}

export async function putProduct(updatedProduct, id) {
  try {
    const {
      name,
      ingredients,
      numberOfPieces,
      category,
      type,
      price,
      isTakeAway,
      isRestaurant,
      isVisible,
      imageURL = "",
      isVisibleInSet,
      description,
      weight,
      unit,
    } = updatedProduct

    const request = {
      body: {
        name,
        price,
        category,
        isTakeAway,
        isRestaurant,
        type,
        isVisible,
        ingredients,
        numberOfPieces,
        imageURL,
        isVisibleInSet,
        description,
        weight,
        unit,
      },
    }
    return await API.put(apiName, `${api.productMenu}/${id}`, request)
    // return await API.put(apiName, `${api.product}/${id}`, request)
  } catch (error) {
    throw error
  }
}

export async function deleteProduct(id, approve) {
  try {
    const request = {
      body: {
        approve,
      },
    }
    return await API.del(apiName, `${api.productMenu}/${id}`, request)
    // return await API.del(apiName, `${api.product}/${id}`, request)
  } catch (error) {
    console.error("Coś poszło nie tak podczas usuwania Produktu.", error)
  }
}
