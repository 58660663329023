import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import formatDate from 'src/utils/formatDate'

import GroupPrint from './GroupPrint'
import SinglePrint from './SinglePrint'
import { LoadingIcon } from 'src/components/common'

import bwtLocations from 'src/constants/views/bwtLocations'
import { editHistoryOrder } from 'src/redux/order/reducer'
import { getStatus } from 'src/pages/Home/Helpers/index'

import {
  searchOrdersHistory,
  searchFutureOrdersHistory,
  setLocation,
  increaseTotalPastOrder,
  resetTables,
} from 'src/redux/ordersHistory/actions'

import orderStatus from 'src/constants/types/orderStatus'
import { debounce } from 'src/utils'
import FutureOrder from './FutureTable'
import PastOrder from './PastTable'
import { notifyError } from 'src/notification'

import * as classes from './OrdersHistory.module.scss'

const DEBOUNCE_TIMEOUT = 500

const OrdersHistory = ({ showOrderWindow }) => {
  const dispatch = useDispatch()
  const ordersHistory = useSelector((state) => state.ordersHistory)
  const { reservation } = useSelector((state) => state.order.userData)
  const { futureOrders, pastOrders, location, isLoading } = ordersHistory

  const [singlePrint, setSinglePrint] = useState(false)
  const [groupPrint, setGroupPrint] = useState(false)
  const [singlePrintId, setSinglePrintId] = useState('')
  const [futureSearch, setFutureSearch] = useState('')
  const [pastSearch, setPastSearch] = useState('')

  const [confirmedStatus, setConfirmedStatus] = useState(false)
  const [paidStatus, setPaidStatus] = useState(false)
  const [canceledStatus, setCanceledStatus] = useState(false)
  const [toPayStatus, setToPayStatus] = useState(false)

  useEffect(() => {
    if (pastSearch === '') {
      fetchOrders('')
    }
  }, [pastSearch])

  const debounceOnSearch = useRef(
    debounce(fetchOrders, DEBOUNCE_TIMEOUT),
  ).current

  function onFutureSearch(search) {
    setFutureSearch(search)
  }

  function onPastSearch(search) {
    setPastSearch(search)
  }

  function setCategoryFilter(ordersToCategorize) {
    return ordersToCategorize.filter((item) => {
      return confirmedStatus || paidStatus || canceledStatus || toPayStatus
        ? (confirmedStatus
          ? getStatus(item.status).includes(orderStatus.confirmed.PL)
          : '') ||
        (paidStatus
          ? getStatus(item.status).includes(orderStatus.paid.PL)
          : '') ||
        (canceledStatus
          ? getStatus(item.status).includes(orderStatus.canceled.PL)
          : '') ||
        (toPayStatus
          ? getStatus(item.status).includes(
            orderStatus.waitingForPayment.PL,
          )
          : '')
        : item
    })
  }

  function filterFutureOrders(ordersToFilter, searchWord) {
    return ordersToFilter.filter((item) => {
      if (
        formatDate(new Date(item.BWT.date))
          .toLowerCase()
          .includes(searchWord) ||
        item.id.toLowerCase().includes(searchWord) ||
        item.clientPhone.includes(searchWord)
      )
        return true
    })
  }

  function filterPastOrders(ordersToFilter, searchWord) {
    let pastFilteredOrders = ordersToFilter.filter((item) => {
      if (
        formatDate(new Date(item.BWT.date))
          .toLowerCase()
          .includes(searchWord) ||
        item.id.toLowerCase().includes(searchWord) ||
        item.clientPhone.includes(searchWord)
      )
        return item
    })
    return pastFilteredOrders;
  }

  function onSinglePrint(id) {
    setSinglePrintId(id)
    setSinglePrint(true)
  }

  function fetchOrders(searchInput) {
    dispatch(resetTables())
    dispatch(searchOrdersHistory(searchInput))
    dispatch(searchFutureOrdersHistory())
  }

  function fetchMorePastHistory() {
    dispatch(increaseTotalPastOrder())
    dispatch(searchOrdersHistory())
  }

  function editOrder(order) {
    if (reservation.isReserved) {
      notifyError('Please unreserved', 'Zwolnij blok czasowy')
      return
    }
    dispatch(editHistoryOrder(order))
    showOrderWindow()
  }

  function setHistoryLocation(location) {
    dispatch(setLocation(location))
    fetchOrders()
  }

  return (
    <div className={classes.OrderHistoryContainer}>
      <div className={classes.ActionWrapper}>
        <Button.Group size="massive">
          <Button
            className={
              bwtLocations.factory === location ? classes.FactoryBackground : ''
            }
            onClick={() => setHistoryLocation(bwtLocations.factory)}
          >
            Fabryka
          </Button>

          <Button
            className={
              bwtLocations.restaurant === location
                ? classes.RestaurantBackground
                : ''
            }
            onClick={() => setHistoryLocation(bwtLocations.restaurant)}
          >
            Restauracja
          </Button>
        </Button.Group>
        <Button primary onClick={() => setGroupPrint(true)}>
          Wydruk grupowy
        </Button>
      </div>

      {isLoading ? (
        <LoadingIcon />
      ) : (
          <>
            {futureOrders.length > 0 && (
              <FutureOrder
                orders={setCategoryFilter(
                  filterFutureOrders(futureOrders, futureSearch.toLowerCase()),
                )}
                getStatus={getStatus}
                onSinglePrint={onSinglePrint}
                editOrder={editOrder}
                searchWord={futureSearch.toLowerCase()}
                confirmedStatus={confirmedStatus}
                setConfirmedStatus={setConfirmedStatus}
                paidStatus={paidStatus}
                setPaidStatus={setPaidStatus}
                canceledStatus={canceledStatus}
                setCanceledStatus={setCanceledStatus}
                setToPayStatus={setToPayStatus}
                toPayStatus={toPayStatus}
                onFutureSearch={onFutureSearch}
              />
            )}

            {pastOrders.length >= 0 && (
              <PastOrder
                orders={filterPastOrders(pastOrders, pastSearch.toLowerCase())}
                pastSearch={pastSearch}
                onPastSearch={onPastSearch}
                getStatus={getStatus}
                onSinglePrint={onSinglePrint}
                editOrder={editOrder}
                fetchMorePastHistory={fetchMorePastHistory}
                searchWord={pastSearch.toLowerCase()}
                searchDatabase={fetchOrders}
              />
            )}
          </>
        )}

      {groupPrint && <GroupPrint onClose={() => setGroupPrint(false)} />}

      {singlePrint && (
        <SinglePrint
          orderId={singlePrintId}
          onClose={() => setSinglePrint(false)}
        />
      )}
    </div>
  )
}

export default OrdersHistory
