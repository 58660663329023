import { API } from 'aws-amplify'
import api, { apiName } from './constants'

export async function putBox(box) {
  const request = {
    body: {
      ...box,
    },
  }
  try {
    return await API.put(apiName, api.box, request)
  } catch (error) {
    throw error
  }
}

export async function getBox() {
  try {
    return await API.get(apiName, `${api.box}`, {})
  } catch (error) {
    console.error('Something went wrong when getting special offers', error)
  }
}
