import React from 'react'
import { Button } from 'semantic-ui-react'

import * as classes from './Counter.module.scss'

export default function ({
  counter,
  isDisabled,
  onChangeInput,
  inputDisabled,
  increaseCounter,
  deacreaseCounter,
}) {
  return (
    <div className={classes.CounterContainer}>
      <Button
        primary
        icon="minus"
        disabled={isDisabled}
        onClick={deacreaseCounter}
      />

      <input
        value={counter}
        disabled={inputDisabled}
        onChange={onChangeInput}
        className={classes.CounterNumber}
      />

      <Button
        primary
        icon="plus"
        disabled={isDisabled}
        onClick={increaseCounter}
        className={classes.DeacrementButton}
      />
    </div>
  )
}
