import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

export default ({ size = 'large', label = 'Ładowanie' }) => (
  <Dimmer active inverted>
    <Loader size={size} inverted>
      {label}
    </Loader>
  </Dimmer>
)
