import React, { useState, useEffect } from "react"
import { Modal } from "src/components"
import { Button } from "semantic-ui-react"
import styles from "./SwapModal.module.scss"
import Select from "src/components/common/Select"
import { useSelector } from "react-redux"

const SwapRow = ({ name, onChange, initValue }) => {
  console.log(initValue)
  const { swaps: s } = useSelector((state) => state)
  const [swaps, setSwaps] = useState({
    1: {},
    2: {},
    3: {},
  })
  console.log(swaps)
  useEffect(() => {
    if (
      typeof initValue === "object" &&
      "replacementID" in initValue &&
      initValue.replacementID.length !== 0
    ) {
      let obj = {
        1: { originalId: null, newId: null, originalName: null, newName: null },
        2: { originalId: null, newId: null, originalName: null, newName: null },
        3: { originalId: null, newId: null, originalName: null, newName: null },
      }
      initValue.replacementID.forEach((_, i) => {
        obj = { ...obj, [i + 1]: _ }
      })
      setSwaps(obj)
    }
  }, [])

  useEffect(() => {
    onChange(
      Object.values(swaps).filter(
        (_) => typeof _.originalId !== "undefined" && _.originalId !== null,
      ),
    )
  }, [swaps["1"], swaps["2"], swaps["3"]])

  return (
    <div className={styles.SwapWrapper}>
      <h1>{name}</h1>
      <div className={styles.DropDownsWrapper}>
        <div>
          <h2>Wymiana 1</h2>
          <Select
            name="Wymiana 1"
            onChange={(e) => {
              console.log(e.currentTarget.value)
              setSwaps({ ...swaps, 1: JSON.parse(e.currentTarget.value) })
            }}
            selectedOption={
              s.find((_) => {
                return (
                  JSON.parse(_.value).originalId === swaps[1].originalId &&
                  JSON.parse(_.value).newId == swaps[1].newId
                )
              })?.value  ?? s[0].value
            }
            placeholder={"Wymiana 1"}
            options={s}
          />
        </div>
        <div>
          <h2>Wymiana 2</h2>
          <Select
            name="Wymiana 2"
            onChange={(e) =>
              setSwaps({ ...swaps, 2: JSON.parse(e.currentTarget.value) })
            }
            selectedOption={
              s.find((_) => {
                return (
                  JSON.parse(_.value).originalId === swaps[2].originalId &&
                  JSON.parse(_.value).newId == swaps[2].newId
                )
              })?.value ?? s[0].value
            }
            placeholder={"Wymiana 2"}
            options={s}
          />
        </div>
        <div>
          <h2>Wymiana 3</h2>
          <Select
            name="Wymiana 3"
            onChange={(e) =>
              setSwaps({ ...swaps, 3: JSON.parse(e.currentTarget.value) })
            }
            selectedOption={
              s.find((_) => {
                return (
                  JSON.parse(_.value).originalId === swaps[3].originalId &&
                  JSON.parse(_.value).newId === swaps[3].newId
                )
              })?.value ?? s[0].value
            }
            placeholder={"Wymiana 3"}
            options={s}
          />
        </div>
      </div>
    </div>
  )
}

const SwapModal = ({ isActive, onClose, onHide, onSubmit, initialValue }) => {
  const { items } = useSelector((state) => state.order)
  useEffect(() => console.log({ initialValue }), [initialValue])
  console.log({ i: initialValue })

  const [swaps, setSwaps] = useState({})
  useEffect(() => {
    if (initialValue.length !== 0) {
      let obj = {}
      initialValue.forEach((_, i) => {
        obj = {
          ...obj,
          [i]: { SETId: _.SETId, replacementID: _.replacementID },
        }
      })
      setSwaps(obj)
    } else {
      setSwaps([])
    }
  }, [initialValue])

  const findSets = items.filter((_) => _.id.toString().startsWith("3"))
  const handleSubmit = () => {
    const replacements = Object.values(swaps).filter(
      (_) => _.replacementID.length !== 0,
    )
    onSubmit(replacements)
    console.log(replacements)
    onClose()
  }
  return (
    <Modal
      className={styles.ModalWrapper}
      title="Zarządzaj Wymianami"
      show={isActive}
      onHide={onClose}
      description={
        <>
          Wykonaj zmiany w zestawach wybranych dla tego zamówienia. Dla każdego
          z zestawów można wykonać maksymalnie 3 zmiany.
        </>
      }
      footer={[
        <Button onClick={handleSubmit} primary>
          Zatwierdź
        </Button>,
      ]}
    >
      <div>
        {findSets.map((set, index) => (
          <SwapRow
            name={set.name}
            key={index}
            initValue={swaps[index]}
            onChange={(e) =>
              setSwaps({
                ...swaps,
                [index]: { SETId: set.id, replacementID: e },
              })
            }
          />
        ))}
      </div>
    </Modal>
  )
}
export default SwapModal
