import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "semantic-ui-react"
import { Modal, Switch } from "src/components"
import { setPaymentDate, setPlaces } from "src/redux/order/reducer"

import styles from "./deliveryModal.module.scss"

const DeliveryModal = ({ isActive, onClose, onSubmit, initialValue }) => {
  console.log({ initialValue })
  const handleSubmit = () => {
    onSubmit({ ["productionPlace"]: productionPlace, ["salePlace"]: salePlace })

    onClose()
  }

  const { salePlace, productionPlace, paymentDate } = useSelector(
    (state) => state.order,
  )
  const dispatch = useDispatch()

  // const [salePlace, setSalePlace] = useState(initialValue.salePlace)
  // const [productionPlace, setProductionPlace] = useState(
  //   initialValue.productionPlace,
  // )
  console.log({ initialValue }, { salePlace }, salePlace === "factory")

  return (
    <Modal
      className={styles.ModalWrapper}
      title="Edytuj informacje"
      show={isActive}
      onHide={onClose}
      description={
        <>
          Jeśli zamówienie zostało opłacone lub sprzedane w innej lokalizacji
          wybierz odpowiednią opcję.
        </>
      }
      footer={
        <Button onClick={handleSubmit} primary>
          Zatwierdź
        </Button>
      }
    >
      <div>
        <div className={styles.gridWrapper}>
          <div></div>
          <div>Fabryka</div>
          <div>Restauracja</div>
          <div>Produkcja zamówienia</div>
          <div>
            <Switch
              checked={productionPlace === "factory"}
              onChange={() => {
                // setProductionPlace("factory")
                dispatch(
                  setPlaces({
                    productionPlace:
                      productionPlace === "factory" ? "restaurant" : "factory",
                    salePlace: salePlace,
                  }),
                )
              }}
            />
          </div>
          <div>
            <Switch
              checked={productionPlace === "restaurant"}
              onChange={() => {
                dispatch(
                  setPlaces({
                    productionPlace:
                      productionPlace === "factory" ? "restaurant" : "factory",
                    salePlace: salePlace,
                  }),
                )
              }}
            />
          </div>
          <div>
            <span style={{ marginRight: 10 }}>Sprzedaz zamówienia</span>
            <input
              type="date"
              style={{ borderRadius: 10 }}
              value={paymentDate}
              onChange={({ target: { value } }) =>
                dispatch(setPaymentDate({ date: value }))
              }
            />
          </div>
          <div>
            <Switch
              checked={salePlace === "factory"}
              onChange={() => {
                dispatch(
                  setPlaces({
                    salePlace:
                      salePlace === "factory" ? "restaurant" : "factory",
                    productionPlace: productionPlace,
                  }),
                )
              }}
            />
          </div>
          <div>
            <Switch
              checked={salePlace === "restaurant"}
              onChange={() => {
                dispatch(
                  setPlaces({
                    salePlace:
                      salePlace === "factory" ? "restaurant" : "factory",
                    productionPlace: productionPlace,
                  }),
                )
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default DeliveryModal
