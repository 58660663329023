import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from 'semantic-ui-react'
import DeleteOfferModal from 'src/components/DeleteOfferModal'
import classes from '../../User/Card/UserCard.module.scss'
export default function OfferCard(props) {
  const {
    index,
    name,
    id,
    dateStart,
    dateEnd,
    setIsEdittingOffer,
    setEdittingData,
    cycle,
    menu,
    dateFull,
    setOfferList,
    status,
  } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleDelete = () => {
    setIsModalOpen(true)
  }
  const handleEdit = () => {
    setIsEdittingOffer(true)
    setEdittingData({
      name,
      id,
      dateStart,
      dateEnd,
      cycle,
      menu,
      dateFull,
    })
  }
  return (
    <div className={classes.Card}>
      <Col xs={1}>{index + 1}.</Col>
      <Col xs={3}>{name}</Col>
      <Col xs={4}>
        <span style={{ color: `${status ? 'black' : 'red'}` }}>{dateFull}</span>
      </Col>
      <Col xs={1}></Col>
      <Col xs={1}></Col>
      <Col xs={2} style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
        <Icon
          onClick={() => handleEdit(id)}
          className={classes.CardIcon + ' ' + classes.PenIcon}
          name="edit"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faTimes}
          onClick={() => handleDelete(id)}
          className={classes.CardIcon + ' ' + classes.CrossIcon}
        />
      </Col>
      <DeleteOfferModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={id}
        setOfferList={setOfferList}
      />
    </div>
  )
}
