import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import userIsAdmin from 'src/utils/isAdmin'
import { LoadingIcon } from 'src/components'
import { notifyError } from 'src/notification'

const withAdminAccess = (WrapperComponent) => (props) => {
  const history = useHistory()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const username = useSelector((state) => state.auth.userData.username)
  const REDIRECT_DELAY = 2000

  useEffect(() => {
    const checkIfAdmin = async () => {
      setIsLoading(true)
      if (!username) return

      try {
        const isAdmin = await userIsAdmin(username)
        if (!isAdmin) history.push('/')
        else setIsAdmin(true)
      } catch (error) {
        setTimeout(() => history.push('/'), REDIRECT_DELAY)
        notifyError(
          'Error while verifying admin access',
          'Błąd podczas weryfikacji administratora.',
          error,
        )
      }
      setIsLoading(false)
    }
    checkIfAdmin()
  }, [])

  return (
    <>
      {isLoading || !isAdmin ? (
        <LoadingIcon />
      ) : (
        <WrapperComponent {...props} />
      )}
    </>
  )
}

export default withAdminAccess
