import orderStatus from 'src/constants/types/orderStatus'

//status options are the ones which can be picked by the dysza employee
export const statusOptions = [
  // { value: orderStatus.new.EN, label: orderStatus.new.PL },
  { value: orderStatus.confirmed.EN, label: orderStatus.confirmed.PL },
  { value: orderStatus.canceled.EN, label: orderStatus.canceled.PL },
  {
    value: orderStatus.waitingForPayment.EN,
    label: orderStatus.waitingForPayment.PL,
  },
]

export const allStatusOptions = [
  { value: orderStatus.paid.EN, label: orderStatus.paid.PL },
  // { value: orderStatus.new.EN, label: orderStatus.new.PL },
  { value: orderStatus.confirmed.EN, label: orderStatus.confirmed.PL },
  { value: orderStatus.canceled.EN, label: orderStatus.canceled.PL },
  {
    value: orderStatus.waitingForConfirmation.EN,
    label: orderStatus.waitingForConfirmation.PL,
  },
  {
    value: orderStatus.waitingForPayment.EN,
    label: orderStatus.waitingForPayment.PL,
  },
]
