import React from 'react'
import OfferCard from './Card'
import { Breadcrumb, Button, Dimmer, Loader } from 'semantic-ui-react'
import styles from './Breadcrumb.module.scss'
export default function OfferList({
  offers,
  setActiveMenu,
  setEdittingData,
  setIsEdittingOffer,
  setOfferList,
  loading,
}) {
  const offersToRender = offers.map((offer, index) => (
    <OfferCard
      key={offer.id}
      name={offer.name}
      index={index}
      dateStart={offer.from}
      dateEnd={offer.till}
      id={offer.id}
      cycle={offer.cycle}
      menu={offer.menu}
      setIsEdittingOffer={setIsEdittingOffer}
      setEdittingData={setEdittingData}
      dateFull={offer.dateForHuman}
      status={offer.status}
      setOfferList={setOfferList}
    />
  ))
  const handleAddOffer = () => {
    setEdittingData({})

    setIsEdittingOffer(true)
  }
  return (
    <div style={{ paddingLeft: '30px' }}>
      <Breadcrumb className={styles.Breadcrumb}>
        <Breadcrumb.Section link onClick={() => setActiveMenu('Menu')}>
          Menu Administracyjne
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section link>Oferty specjalne</Breadcrumb.Section>
      </Breadcrumb>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <div style={{ width: '50%' }}>{offersToRender}</div>
      )}
      <div
        style={{ width: '50%', display: 'flex', flexDirection: 'row-reverse' }}
      >
        <Button
          primary
          onClick={() => handleAddOffer()}
          style={{ padding: '10px 30px' }}
        >
          Dodaj
        </Button>
      </div>
    </div>
  )
}
