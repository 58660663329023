import React, { useState } from 'react'
import { UnderlineNav } from 'src/components'
import NewOrder from './New'
import ShopOrders from './ShopOrders'
import UserHistory from './UserHistory'
import OrdersHistory from './OrdersHistory'

import { orderViews } from 'src/constants/views'
import * as classes from './Order.module.scss'

export default function () {
  // const [currentTab, setCurrentTab] = useState(orderViews.newOrder)
  const [currentTab, setCurrentTab] = useState(orderViews.orderHistory)

  const Tab = () => {
    const options = {
      [orderViews.newOrder]: <NewOrder />,
      [orderViews.userHistory]: <UserHistory />,
      [orderViews.orderHistory]: (
        <OrdersHistory
          showOrderWindow={() => setCurrentTab(orderViews.newOrder)}
        />
      ),
      [orderViews.shopOrders]: (
        <ShopOrders
          showOrderWindow={() => setCurrentTab(orderViews.newOrder)}
        />
      ),
    }
    return options[currentTab]
  }

  return (
    <div className={classes.Container}>
      <UnderlineNav
        tabs={[
          { title: 'Zamówienie', value: orderViews.newOrder },
          { title: 'Lista', value: orderViews.orderHistory },
          // { title: 'Historia', value: orderViews.userHistory },
          {
            title: 'Online',
            value: orderViews.shopOrders,
          },
        ]}
        activeTab={currentTab}
        setWindow={setCurrentTab}
      />

      <div className={classes.Section}>
        <Tab />
      </div>
    </div>
  )
}
