//@ts-check
import { API } from 'aws-amplify'
import api, { apiName } from './constants'

export async function getRoute(hour, date, location) {
  try {
    const request = {
      body: {
        hour,
        date,
        location,
      },
    }

    return await API.post(apiName, api.route, request)
  } catch (error) {
    throw error
  }
}
