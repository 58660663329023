//@ts-check
import { API } from 'aws-amplify'
import api, { apiName } from './constants'

export async function getDeliveryPrices() {
  try {
    return await API.get(apiName, api.deliveryPrice, {})
  } catch (error) {
    console.error('Something went wrong when getting products', error)
  }
}
export async function updateDeliveryPrices(zoneA, zoneB) {
  const params = {
    body: {
      zoneA,
      zoneB,
    },
  }
  try {
    return await API.put(apiName, api.deliveryPrice, params)
  } catch (err) {
    throw err
  }
}
