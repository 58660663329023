// @ts-check
import { API } from 'aws-amplify'
import api, { apiName } from './constants'

/**
 * Get BWT data for a specific date and location
 * @param {string} date - The timetable date to check, date format should be YYYY-MM-DD
 * @param {BWTLocation} location - The location of the table, its either restaurant or factory.
 * @return {Promise} - The table data of the location and date selected
 */

export async function getBWT(date, location) {
  try {
    const params = {
      body: { location },
    }
    return await API.post(apiName, `${api.bwt}/freeDate/${date}`, params)
  } catch (error) {
    throw error
  }
}

/**
 * Update BWT table
 * @param {BWT} tableData - The table data to update, tableData object must consist of
 * @return {Promise} -  BWT table data
 */
export async function putBWT(tableData) {
  try {
    const params = {
      body: {
        id: tableData.id,
        date: tableData.date,
        location: tableData.location,
        timeTable: tableData.timeTable,
        startHour: tableData.startHour,
      },
    }
    return await API.put(apiName, `${api.bwt}/freeDate/${tableData.id}`, params)
  } catch (error) {
    throw error
  }
}

/**
 * Get default BWT data
 * @param {BWTLocation} location - BWT location
 * @return {Promise} - BWT table data
 */
export async function getDefaultBWT(location) {
  try {
    const response = await API.get(apiName, `${api.bwt}/freeDate/default`, {})
    return response.find((item) => item.location === location)
  } catch (error) {
    throw error
  }
}

/**
 * Get default BWT data
 * @param {BWT} tableData - BWT location
 * @return {Promise} - needs testing
 */
export async function updateDefaultBWT(tableData) {
  try {
    //Date param should be YYYY-MM-DD
    const params = {
      body: {
        location: tableData.location,
        timeTable: tableData.timeTable,
        startHour: tableData.startHour,
      },
    }
    return await API.put(apiName, `${api.bwt}/freeDate/default`, params)
  } catch (error) {
    throw error
  }
}

/**
 * Check if the system is off or on for the entered date.
 * @param {Date} date - The timetable date to check, date format should be DD-MM-YYYY
 * @param {BWTLocation} location - The location of the table, its either restaurant or factory.
 * @param {number} hour - Hour of the day.
 * @param {number} numberOfBlocks - Number of blocks to check.
 * @return {Promise} - returns back an object with attribute 'status' with value of boolean
 */
export async function checkFreeBlocks(date, location, hour, numberOfBlocks) {
  try {
    const params = {
      body: {
        date,
        hour,
        location,
        numberOfBlocks,
      },
    }
    return await API.post(apiName, `${api.bwt}/freeDate/check`, params)
  } catch (error) {
    throw error
  }
}

export async function rollBackBWT(
  orderID,
  workerID,
  location,
  hour,
  date,
  orderType,
) {
  try {
    const params = {
      body: {
        orderID,
        workerID,
        location,
        selectedHour: hour,
        date,
        orderType,
      },
    }

    return await API.post(apiName, `${api.rollback}/rollBack`, params)
  } catch (error) {
    throw error
  }
}

/**
 * Confirm BWT block is avaiable or not
 * @param {Date} orderID - Order ID
 * @param {BWTLocation} workerID - Employee Unique ID
 * @param {BWTLocation} location - BWT location
 * @param {number} hour - Number of blocks to check.
 * @param {string} date - Date to be confirmed, format needs to be YYYY-MM-DD
 * @param {string} orderType - Date to be confirmed, format needs to be YYYY-MM-DD
 * @return {Promise} - returns back an object with attribute 'status' with value of boolean
 */
export async function confirmBWTBlock(
  orderID,
  workerID,
  location,
  hour,
  date,
  orderType,
) {
  try {
    const params = {
      body: {
        orderID,
        workerID,
        location,
        selectedHour: hour,
        date,
        orderType,
      },
    }
    return await API.post(apiName, `${api.bwt}/freeDate/addOrder`, params)
  } catch (error) {
    throw error
  }
}

/**
 * Reserves bwt block (put the block in the pending for confirmation stage
 * @param {string} bwtID - BWT ID
 * @param {number} numberOfBlocks - Block quanitites to check
 * @param {number} hour - BWT hour
 * @param {string} workerID - Employee unique ID
 * @param {OrderType} orderType - where was order originally added from
 * @param {string} orderID - Order ID
 * @return {Promise} - returns back an object with attribute 'status' with value of boolean
 */
export async function reserveBWTBlock(
  bwtID,
  numberOfBlocks,
  hour,
  workerID,
  orderType,
  orderID,
) {
  try {
    const params = {
      body: {
        bwtID,
        hour,
        numberOfBlocks,
        workerID,
        orderType,
        ...(!!orderID && { orderID }), // Add order id conditionally, this is used for shop and dsyza orders
      },
    }
    return await API.post(apiName, `${api.bwt}/reserved`, params)
  } catch (error) {
    throw error
  }
}

//Cancels the already added and confirmed order, this can happen after making the status to cancel
export async function cancelBWTOrder(orderID) {
  console.log('orderID in endpoint: ')
  console.log(orderID)
  try {
    return await API.get(apiName, `${api.bwt}/freeDate/cancel/${orderID}`, {})
  } catch (error) {
    throw error
  }
}

//Release the bwt blocks from pending stage
export async function releaseBWTBlock(
  bwtID,
  hour,
  orderID,
  orderType,
  workerID,
) {
  try {
    const params = {
      body: {
        bwtID,
        hour,
        orderID,
        orderType,
        workerID,
      },
    }
    return await API.del(apiName, `${api.bwt}/reserved`, params)
  } catch (error) {
    throw error
  }
}
