import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import Amplify from 'aws-amplify'
import awsConfig from './config'
import { Provider } from 'react-redux'
import store from './redux/store'
import { initFirebase } from './firebase'
import * as Sentry from '@sentry/browser'
import { __prod__ } from 'src/constants/index'
Amplify.configure(awsConfig)
initFirebase()
if (__prod__) {
  Sentry.init({
    dsn: 'https://096ffb2513134fc9a7c0d13fd8e0fd57@o483848.ingest.sentry.io/6117635',
  })
}
ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root'),
)
