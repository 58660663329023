import React from 'react'
import { Container } from 'semantic-ui-react'

import classes from './MenuList.module.scss'

export default ({ children }) => {
  return (
    <Container fluid>
      <div className={classes.Wrapper}>{children}</div>
    </Container>
  )
}
