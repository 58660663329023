import React, { useState, useEffect } from "react"
import { API, Auth, graphqlOperation } from "aws-amplify"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

import { fetchDeliveryPrices } from "./redux/order/actions"
import Routes from "./Routes"
import { Navbar } from "src/components"

//API
import { currentAuthenticatedUser } from "src/api/rest/auth"
import emailIsVerified from "src/utils/emailIsVerified"
import { onCreateShopOrder } from "src/api/graphql/order/subscription"

//Redux
import {
  setIsFetchingMenu,
  getSets,
  getProducts,
  getIngredients,
} from "src/redux/menu"
import { getBoxPrices, } from "./redux/box"
import {getSwaps} from './redux/swaps'

import {
  getShopOrders,
  subscribeToShopOrders,
} from "src/redux/shopOrders/actions"
import { signIn, signOut } from "src/redux/auth"
//Libraries
import "moment/locale/pl"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "react-toastify/dist/ReactToastify.css"
import "react-datetime/css/react-datetime.css"
import "semantic-ui-css/semantic.min.css"
import "antd/dist/antd.css"
import classes from "./App.module.scss"

function App({ history }) {
  toast.configure()
  const dispatch = useDispatch()
  const isVerified = emailIsVerified()
  const [isAuthenticating, setIsAuthenticating] = useState(true)

  useEffect(() => {
    autoLogin()
    if (isVerified) {
      fetchItems()
    }
  }, [isVerified])

  function onNewShopOrder(data) {
    dispatch(subscribeToShopOrders(data))
  }

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateShopOrder),
    ).subscribe({
      next: onNewShopOrder,
    })

    return () => subscription.unsubscribe()
  }, [])

  async function fetchItems() {
    dispatch(setIsFetchingMenu(true))
    try {
      await dispatch(getSets())
      await dispatch(getProducts())
      await dispatch(getIngredients())
      await dispatch(getShopOrders())
      await dispatch(fetchDeliveryPrices())
      await dispatch(getBoxPrices())
      await dispatch(getSwaps())
    } catch (error) {
      console.error("Fetch menu error", error)
    }

    dispatch(setIsFetchingMenu(false))
  }

  async function autoLogin() {
    setIsAuthenticating(true)
    try {
      const userData = await currentAuthenticatedUser()
      if (userData) dispatch(signIn(userData))
    } catch (error) {
      // No authenticated user found
    }
    setIsAuthenticating(false)
  }

  async function handleLogout() {
    await Auth.signOut()
    dispatch(signOut())
    history.push("/login")
  }

  return (
    !isAuthenticating && (
      <div className={classes.App}>
        <Navbar handleLogout={handleLogout} />
        <Routes />
      </div>
    )
  )
}
export default withRouter(App)
