import React from 'react'
import { Col } from 'react-bootstrap'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Switch from 'react-switch'

import classes from './UserCard.module.scss'
export default function UserCard(props) {
  const {
    index,
    Groups,
    Attributes,
    onChangeRole,
    deleteUsername,
    Username: username,
  } = props

  const isAdmin =
    Groups.findIndex((item) => item === 'admins') === -1 ? false : true
  const email = Attributes.find((attribute) => attribute.Name === 'email').Value

  return (
    <div className={classes.Card}>
      <Col xs={1}>{index}.</Col>
      <Col xs={2}>{username}</Col>
      <Col xs={4}>{email}</Col>
      <Col xs={2}>
        {/* () => onChangeRole(username, !isAdmin) */}
        <Switch
          checked={isAdmin}
          onColor={'#46b8da'}
          onChange={() => onChangeRole(username, !isAdmin)}
        />
      </Col>
      <Col xs={1}></Col>
      <Col xs={1}></Col>
      <Col xs={1}>
        <FontAwesomeIcon
          size="lg"
          icon={faTimes}
          onClick={() => deleteUsername(username)}
          className={classes.CardIcon + ' ' + classes.CrossIcon}
        />
      </Col>
    </div>
  )
}
