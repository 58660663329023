import { fetchShopOrders } from 'src/api/rest/order'

import {
  SET_UNCONFRIMED_ORDERS,
  ADD_ORDER,
  SET_LOADING,
  SET_WAITING_SHOP_ORDERS,
  SET_CANCELED_SHOP_ORDERS,
} from './types'

/**
 * Shop orders action object
 * @param {Array<OrderItem>} orders - Shop orders list
 * @return {Object} -
 */
export const setUnconfirmedOrders = (orders) => ({
  type: SET_UNCONFRIMED_ORDERS,
  payload: orders,
})

export const setWaitingShopOrders = (orders) => ({
  type: SET_WAITING_SHOP_ORDERS,
  payload: orders,
})

export const setCanceledShopOrders = (orders) => ({
  type: SET_CANCELED_SHOP_ORDERS,
  payload: orders,
})

/**
 * Add order action object
 * @param {OrderItem} order - An order
 * @return {Object} -
 */
export const addShopOrder = (order) => ({
  type: ADD_ORDER,
  payload: order,
})

/**
 * Shop orders loading action object
 * @param {boolean} isLoading - Loading status
 * @return {Object} -
 */
export const setIsLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
})

/**
 * Shop orders action object
 * @return {Object} -
 */
export function getShopOrders() {
  return async (dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const {
        new: unconfirmedOrders,
        waiting,
        canceled,
      } = await fetchShopOrders()
      dispatch(setUnconfirmedOrders(unconfirmedOrders))
      dispatch(setCanceledShopOrders(canceled))
      dispatch(setWaitingShopOrders(waiting))
    } catch (error) {
      console.error('error')
    }
    dispatch(setIsLoading(false))
  }
}

export function subscribeToShopOrders(eventData) {
  return async (dispatch) => {
    try {
      const order = eventData.value.data.onCreatePSushiOrderShopDev

      //Parse arrays to json, because it is sent as string
      const parsedItems = order.orders.map((item) => JSON.parse(item))
      const parsedStatus = order.status.map((status) => JSON.parse(status))
      const parsedComment = JSON.parse(order.clientComment)

      order.orders = parsedItems
      order.status = parsedStatus
      order.clientComment = parsedComment
      dispatch(addShopOrder(order))
    } catch (error) {
      console.error(error)
    }
  }
}
