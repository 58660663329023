//@ts-check
import { orderModes, orderStatus } from "src/constants"
import {
  setOrderMode,
  setItemsData,
  setOrderID,
  setUserData,
  getUpdatedOrder,
  setDiscount,
} from "../order/actions"

const SET_ORDER_COPY = "SET_ORDER_COPY"

const initReservation = {
  hour: "",
  date: "",
  location: "",
  numberOfBlocks: 0,
  isEditable: true, // stands for if user can change the reserved number of blocks of the current BWT row
  isReserved: false, // stands for if the user can reserve or cancel blocks
}

const initialUserData = {
  reservation: initReservation,
  nip: "",
  name: "",
  phone: "",
  email: "",
  clientComment: "",
  productionComment: "",
  address: "",
  paymentType: "",
  deliveryType: "",
  isPriority: false,
}

const initState = {
  id: "",
  discounts: "",
  items: [],
  status: [],
  userData: initialUserData,
  mode: orderModes.add,
  itemsPrice: 0,
  deliveryPrice: 0,
  salePlace: "",
  productionPlace: "",
  paymentDate: "",
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SET_ORDER_COPY]: () => ({
      ...state,
      ...payload,
    }),
  }

  return cases[type] ? cases[type]() : state
}

const setOrder = (orderData) => ({
  type: SET_ORDER_COPY,
  payload: orderData,
})

//Actions
export function setCopyOfOrder() {
  return (dispatch, getState) => {
    const { order } = getState()
    const clone = { ...order }
    clone.id = ""
    clone.clientComment = ""
    clone.productionComment = ""
    dispatch(setOrder(clone))
  }
}

export function copyOrderToOriginal() {
  return (dispatch, getState) => {
    const { orderCopy } = getState()
    const { status, items, id, userData, discounts } = orderCopy

    //Setting cancelled and copied order always from dysza, even from if its from shop
    dispatch(setOrderMode(orderModes.add))

    // const index = status.findIndex(
    //   (item) => item.status === orderStatus.waitingForConfirmation.EN,
    // )

    // const isOrderFromShop = index !== -1

    // dispatch(
    //   setOrderMode(isOrderFromShop ? orderModes.confirm : orderModes.add),
    // )

    userData.reservation = {
      hour: "",
      date: "",
      location: "",
      numberOfBlocks: 0,
      isEditable: true, // stands for if user can change the reserved number of blocks of the current BWT row
      isReserved: false, // stands for if the user can reserve or cancel blocks
    }

    dispatch(setItemsData(items))
    dispatch(setOrderID(id))
    dispatch(setUserData(userData))
    dispatch(setDiscount(discounts))
  }
}
