import { __prod__ } from 'src/constants'

export const apiName = 'przytomni-api'

export const firebaseCollection = {
  mail: 'mail',
}

// export default {
//   user: '/admin/user',
//   route: '/route',
//   bwt: '/bwt',
//   order: '/order/order',
//   shop: '/order/shop',
//   product: '/order/product',
//   sushiSet: '/order/sushiSet',
//   ingredient: '/order/ingredient',
//   administration: '/admin/systemClose',
//   singlePrintProduction: '/printer/kitchen',
//   singlePrintDelivery: '/printer/delivery',
//   hourPrintProduction: '/printer/kitchen/corporate',
//   hourPrintDelivery: '/printer/delivery/corporate',
//   tpay: '/order/tpay',
// }

const production = {
  user: '/admin/user',
  route: '/route',
  bwt: '/bwt',
  rollback: '/order',
  order: '/order/order',
  // product: '/order/product',
  // sushiSet: '/order/sushiSet',
  // ingredient: '/order/ingredient',
  product: '/order/product/takeaway',
  sushiSet: '/order/sushiSet/takeaway',
  ingredient: '/order/ingredient/takeaway',
  productMenu: '/order/product',
  sushiSetMenu: '/order/sushiSet',
  ingredientMenu: '/order/ingredient',
  administration: '/admin/systemClose',
  //------- Stare endpointy do druku ---------

  // singlePrintProduction: '/printer/create/onDemend/oneOrder',
  // singlePrintDelivery: '/printer/oneOrder/naTorbe',
  // hourPrintProduction: '/printer/create/onDemend/perHour',
  // hourPrintDelivery: '/printer/create/onDemend/perHour/naTorbe',
  //
  singlePrintProduction: '/printer/kitchen',
  singlePrintDelivery: '/printer/delivery',
  hourPrintProduction: '/printer/kitchen/corporate',
  hourPrintDelivery: '/printer/delivery/corporate',
  shop: '/order/shop',
  tpay: '/order/tpay',
  welcomeText: '/admin/welcomeText',
  storage: '/admin/storage',
  summaryRestaurant: '/admin/summaryRestaurantOrder',
  deliveryPrice: '/admin/deliveryPrice',
  carousel: '/admin/carousel',
  box: '/admin/box',
  swaps:'/admin/replacement'
}

const test = {
  user: '/dev-admin/user',
  route: '/route-dev',
  bwt: '/dev-bwt',
  rollback: '/order-dev',
  order: '/order-dev/order',
  product: '/order-dev/product/takeaway',
  sushiSet: '/order-dev/sushiSet/takeaway',
  ingredient: '/order-dev/ingredient/takeaway',
  productMenu: '/order-dev/product',
  sushiSetMenu: '/order-dev/sushiSet',
  ingredientMenu: '/order-dev/ingredient',
  // product: '/order-dev/product',
  // sushiSet: '/order-dev/sushiSet',
  // ingredient: '/order-dev/ingredient',
  administration: '/dev-admin/systemClose',
  singlePrintProduction: '/printer-dev/kitchen',
  singlePrintDelivery: '/printer-dev/delivery',
  hourPrintProduction: '/printer-dev/kitchen/corporate',
  hourPrintDelivery: '/printer-dev/delivery/corporate',
  shop: '/dev-order/shop',
  tpay: '/dev-order/tpay',
  welcomeText: '/dev-admin/welcomeText',
  storage: '/dev-admin/storage',
  summaryRestaurant: '/dev-admin/summaryRestaurantOrder',
  deliveryPrice: '/dev-admin/deliveryPrice',
  carousel: '/dev-admin/carousel',
  box: '/dev-admin/box',
  swaps:'/dev-admin/replacement'
}

export default __prod__ ? production : test
