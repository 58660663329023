import React from 'react'
import classes from './Nav.module.scss'

export default function Nav({ tabs, activeTab, setWindow }) {
  function handleChangeWindow(e, activeWindow) {
    //If already the active window
    if (activeTab === activeWindow) return

    const elem = e.currentTarget
    const siblings = elem.parentNode.children

    for (let i = 0; i < siblings.length; i++) {
      if (
        siblings[i].classList &&
        siblings[i].classList.contains(classes.Active)
      )
        siblings[i].classList.remove(classes.Active)
    }

    elem.className = classes.Active
    setWindow(activeWindow)
  }

  return (
    <div className={classes.Container}>
      <ul className={classes.List}>
        {tabs &&
          tabs.map((tab, index) => (
            <li
              key={`nav-item-${index}`}
              data-test-id={`nav-item-${tab.value}`}
              className={
                activeTab === tab.value ? classes.ActiveTab : classes.Tab
              }
              onClick={(e) => handleChangeWindow(e, tab.value)}
            >
              {tab.title}
              {tab.label && <span className={classes.Label}>{tab.label}</span>}
            </li>
          ))}
      </ul>
    </div>
  )
}
