import menuTypes from 'src/constants/types/menu'

export default function (id) {
  if (!id) return undefined

  const options = {
    1: menuTypes.ingredient,
    2: menuTypes.product,
    3: menuTypes.sushiSet,
  }

  const firstChar = id.toString()[0]
  return options[firstChar]
}
