import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

import classes from '../EditItem.module.scss'

export default function EditIngredient({ ingredient, onEdit, onDelete }) {
  const { name, id } = ingredient

  return (
    <article className={classes.ItemContainer}>
      <Row className={classes.Description}>
        <Col xs={2}>
          <span>ID{id}</span>
        </Col>
        <Col xs={6}>
          <span>{name}</span>
        </Col>
        <Col xs={2}>
          <Button onClick={() => onEdit(ingredient)} primary>
            Edytuj
          </Button>
        </Col>
        <Col xs={2}>
          <Button onClick={() => onDelete(ingredient)} color="red">
            Usuń
          </Button>
        </Col>
      </Row>
    </article>
  )
}
