import React from 'react'
import SignUp from './Signup'
import { Modal } from 'src/components'

export default function ({ isActive, onHide }) {
  return (
    <Modal
      title="Dodaj nowego użytkownika"
      show={isActive}
      description={[<SignUp onClose={onHide} />]}
      onHide={onHide}
    />
  )
}
