//ORDER
export const ADD_ITEM = "order/ADD_ITEM"
export const SET_ORDER_ITEMS = "order/SET_ORDER_ITEMS"
export const REMOVE_ITEM = "order/REMOVE_ITEM"
export const CLEAR_ORDER = "order/CLEAR_ORDER"
export const SET_ORDER_ID = "order/SET_ORDER_ID"
export const SET_ORDER_MODE = "order/SET_ORDER_MODE"
export const IS_LOADING = "order/IS_LOADING"
export const SET_DELIVERY_PRICES = "order/SET_DELIVERY_PRICES"
export const SET_DISCOUNT = "order/SET_DISCOUNT"
export const SET_PLACES = "order/SET_PLACES"
export const SET_PAYMENT_DATE = "order/SET_PAYMENT_DATE"

//STATUS
export const SET_STATUS = "status/SET_STATUS"
export const CLEAR_STATUS = "status/CLEAR_STATUS"
export const ADD_STATUS = "status/ADD_STATUS"

//FORM
export const SET_USER_DATA = "userData/SET_USER_DATA"
export const SET_NUMBER_OF_BLOCKS = "userData/SET_NUMBER_OF_BLOCKS"
export const SET_RESERVATION = "userData/SET_RESERVATION"
export const CLEAR_RESERVATION = "userData/CLEAR_RESERVATION"
export const ADD_CANCELING_REASON = "userData/ADD_CANCELING_REASON"
