import {
  ADD_ORDER,
  SET_UNCONFRIMED_ORDERS,
  SET_WAITING_SHOP_ORDERS,
  SET_CANCELED_SHOP_ORDERS,
  SET_LOADING,
} from './types'

const initState = {
  unconfirmedOrders: [],
  waitingOrders: [],
  canceledOrders: [],
  isLoading: false,
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SET_UNCONFRIMED_ORDERS]: () => ({ ...state, unconfirmedOrders: payload }),
    [SET_WAITING_SHOP_ORDERS]: () => ({ ...state, waitingOrders: payload }),
    [SET_CANCELED_SHOP_ORDERS]: () => ({ ...state, canceledOrders: payload }),
    [SET_LOADING]: () => ({ ...state, isLoading: payload }),
    [ADD_ORDER]: () => ({
      ...state,
      unconfirmedOrders: [payload, ...state.unconfirmedOrders],
    }),
  }

  return cases[type] ? cases[type]() : state
}
