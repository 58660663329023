import React, { useCallback, useState } from "react"
import { useSelector } from "react-redux"

import { Input, LoadingIcon } from "src/components"
import Menubar from "./Menubar"

import Card from "./ItemCards"
import ingredientTypes from "src/constants/types/ingredient"

import classes from "./MenuList.module.scss"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { lower, debounce } from "src/utils"
import escapeRegExp from "src/utils/escapeRegex"

export default function MenuList({ setActiveProducts, activeProducts }) {
  const DEBOUNCE_TIMEOUT = 300
  const [searchFilter, setSearchFilter] = useState("")
  const menu = useSelector((state) => state.menu)

  const filterProduct = (product) => product

  const filterIngredient = (ingredient) =>
    ingredient.category !== ingredientTypes.ingredient &&
    ingredient.category !== "Alcohol"

  const searchName = (items, filter) =>
    lower(items.name).search(escapeRegExp(lower(filter))) !== -1

  function filterMenu(menuItems) {
    const { ingredients, products, sets } = menuItems

    //Filter products that are not visible to the user
    const filteredProducts = products.filter(filterProduct)

    //Filter ingredients that are ingredient type.
    const filteredIngredients = ingredients.filter(filterIngredient)
    return [...filteredIngredients, ...filteredProducts, ...sets]
  }

  function searchMenu(menuItems, keywords) {
    return menuItems.filter((item) => searchName(item, keywords))
  }

  function getProductInfo(product) {
    const { ingredients } = product
    const { products } = menu
    const { ingredients: allIngredients } = menu
    let filteredIngredients = allIngredients.filter(
      (ingredient) => ingredient.category !== "Alcohol",
    )

    return ingredients.map((ingredientID) => {
      // console.log(ingredientID)
      if (~~(ingredientID.id / 1000) !== 2) {
        return (
          filteredIngredients.find((item) => item.id === ingredientID.id)
            ?.name || "NO DATA"
        )
      } else {
        console.log(products.find((item) => item.id === ingredientID.id))
        return (
          products.find((item) => item.id === ingredientID.id)?.name ||
          "NO DATA"
        )
      }
    })
  }
  function getTranslation(label) {
    const translations = {
      Beverages: "Napoje",
      Addons: "Dodatki",
    }

    return translations[label] || label
  }
  function getSetsInfo(set) {
    const { products } = set
    return products.map(
      (product) => `${product.name}x${product.numberOfPieces}`,
    )
  }

  function getItemDescription(item) {
    const firstLetter = parseInt(`${item.id}`[0])
    if (firstLetter === 1) return undefined
    else if (firstLetter === 2) return getProductInfo(item)
    else if (firstLetter === 3) return getSetsInfo(item)
  }

  function getCategories(menuList) {
    let categories = []
    menuList.forEach(
      ({ category }) =>
        categories.indexOf(category) === -1 &&
        category !== "Alcohol" &&
        categories.push(category),
    )
    return categories
  }

  const filteredAndSearchedMenu = searchMenu(
    filterMenu(menu),
    searchFilter,
  ).filter((item) => {
    if (
      item.categoru !== "Addons" &&
      getTranslation(item.category) !== "Dodatki" &&
      item.category !== "Gratisy" &&
      item.category !== "Beverages"
    ) {
      return item
    }
  })

  const debounceOnChange = useCallback(
    debounce(setSearchFilter, DEBOUNCE_TIMEOUT),
    [],
  )
  return (
    <div>
      <Input
        type="text"
        icon={faSearch}
        placeholder="Wpisz nazwę produktu…"
        onChange={(e) => debounceOnChange(e.currentTarget.value)}
      />

      <Menubar categories={getCategories(filteredAndSearchedMenu)} />

      <div id="itemsContainer" className={classes.MenuList}>
        {menu.isFetchingMenu && <LoadingIcon size="small" />}

        {!menu.isFetchingMenu &&
          filteredAndSearchedMenu &&
          filteredAndSearchedMenu.map((item) => (
            <Card
              id={item.id}
              key={item.id}
              name={item.name}
              category={item.category}
              price={item.price}
              extraPieces={item.numOfExtraPieces}
              ingredients={getItemDescription(item)}
              setActiveProducts={setActiveProducts}
              activeProducts={activeProducts}
            />
          ))}
      </div>
    </div>
  )
}
