import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'

export default ({ title, description, isActive, onClose, onDelete }) => {
  return (
    <Modal show={isActive} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}> Anuluj </Button>
        <Button color="red" icon="trash" content="Usun" onClick={onDelete} />
      </Modal.Footer>
    </Modal>
  )
}
