import React from 'react'
import { Modal } from 'src/components'
import { Button } from 'react-bootstrap'

export default function ({ isActive, onDeleteUser, onHide, username }) {
  return (
    <Modal
      show={isActive}
      onHide={onHide}
      title={'Czy jesteś pewien, że chcesz usunąć tego użytkownika?'}
      footer={[
        <Button bsStyle="danger" onClick={onDeleteUser}>
          Usuń
        </Button>,
      ]}
      description={[
        <p>
          Użytkownik <b> {username} </b> zostanie trwale usunięty z Dyszy. Czy
          na pewno chcesz go usunąć?
        </p>,
      ]}
    />
  )
}
