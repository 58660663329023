import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { orderTypes } from 'src/constants'

import * as classes from './Row.module.scss'

export default function ({
  data,
  index,
  onSelectRow,
  isSelected,
  startHour,
  removeAble,
  onRemove,
}) {
  const { totalTaken, totalReserved, totalBlocks, reservedBlocks } = data
  const totalAvailable = totalBlocks - totalTaken - totalReserved

  const isGreaterThanZero = (value) => value > 0
  const getPercentage = (amount, max) => `${Math.floor((amount / max) * 100)}%`

  function getTotalReservedOfType(blocks, orderType) {
    return blocks.reduce((total, block) => {
      if (block.orderType === orderType) {
        total += block.numberOfBlocks
      }
      return total
    }, 0)
  }

  const totalShopReserved = getTotalReservedOfType(
    reservedBlocks,
    orderTypes.shop,
  )

  const totalDyszaReserved = getTotalReservedOfType(
    reservedBlocks,
    orderTypes.dysza,
  )

  return (
    <div className={classes.Container}>
      <div
        key={`row-${index}`}
        onClick={() => onSelectRow(index)}
        className={`${classes.Row} ${isSelected && classes.ActiveRow}`}
      >
        <span className={classes.Time}>{startHour + index}</span>

        <div
          className={classes.Empty}
          style={{ width: getPercentage(totalAvailable, totalBlocks) }}
        >
          {isGreaterThanZero(totalAvailable) && totalAvailable}
        </div>

        <div
          className={classes.ShopOrder}
          style={{ width: getPercentage(totalShopReserved, totalBlocks) }}
        >
          {isGreaterThanZero(totalShopReserved) && totalShopReserved}
        </div>

        <div
          className={classes.Reserved}
          style={{ width: getPercentage(totalDyszaReserved, totalBlocks) }}
        >
          {isGreaterThanZero(totalDyszaReserved) && totalDyszaReserved}
        </div>

        <div
          className={classes.Taken}
          style={{ width: getPercentage(totalTaken, totalBlocks) }}
        >
          {isGreaterThanZero(totalTaken) && totalTaken}
        </div>
      </div>
      {removeAble && (
        <span className={classes.Cross} onClick={() => onRemove(index)}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}
    </div>
  )
}
