import React from 'react'

import Ingredients from './Ingredient'
import Products from './Product'
import Sets from './Set'

import { Container, Tab } from 'semantic-ui-react'
import withAdminAccess from 'src/utils/withAdminAccess'

function AdminPanel() {
  const panes = [
    {
      menuItem: 'Składnik',
      render: () => <Ingredients />,
    },
    {
      menuItem: 'Produkt',
      render: () => <Products />,
    },
    {
      menuItem: 'Zestaw',
      render: () => <Sets />,
    },
  ]

  return (
    <Container fluid>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  )
}
export default withAdminAccess(AdminPanel)
