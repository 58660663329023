import paymentTypes from 'src/constants/types/payment'
import Papa from 'papaparse'

export function exportCSV(ordersList) {
  if (!ordersList || ordersList.length < 0) return

  const columns = [
    'ID',
    'Address',
    'Gotówka',
    'Karta',
    'Online',
    'From',
    'To',
    'Duration',
    'Load',
    'Phone',
    'Type',
    'Notes',
    'Notes 2',
    'Rozlinczono',
    'Kierowca',
  ]

  const rows = []
  for (let i = 0; i < ordersList.length; i++) {
    const data = getRowData(ordersList[i])
    rows.push(data)
  }

  const data = {
    fields: columns,
    data: rows,
  }

  var csv = Papa.unparse(data, {
    delimiter: ';',
    newline: '\r\n',
  })
  let csvData = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' })
  var csvURL = window.URL.createObjectURL(csvData)

  const date = new Date(ordersList[0].BWT.date)
    .toLocaleString()
    .substring(0, 10)
  const hour = ordersList[0].BWT.selectedHour
  const name = `FABRYKA-${date}-${hour}`

  var tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${name}.csv`)
  tempLink.click()
}

function getRowData(row) {
  const {
    id,
    price,
    priority,
    clientAddr,
    clientPhone,
    clientEmail,
    paymentType,
    clientComment,
    BWT,
  } = row

  const hour = BWT.selectedHour

  return new Array(
    id,
    clientAddr,
    getPaymentValue(price, paymentType, paymentTypes.cash),
    getPaymentValue(price, paymentType, paymentTypes.card),
    getPaymentValue(price, paymentType, paymentTypes.online),
    hour + ':00',
    hour + 1 + ':00',
    '',
    '',
    clientPhone,
    '',
    `${getPaymentTypePrefix(paymentType)}-${price}`,
    clientComment.client,
    '',
    '',
  )
}

function getPaymentValue(price, paymentType, rowType) {
  return paymentType === rowType ? `${price}`.replace('.', ',') : ''
}

function getPaymentTypePrefix(type) {
  const options = {
    [paymentTypes.card]: 'K',
    [paymentTypes.cash]: 'G',
    [paymentTypes.online]: 'Online',
  }
  return options[type]
}
