import React, { useState } from 'react'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

import { Button, Select, Dropdown } from 'semantic-ui-react'
import { Modal } from 'src/components'
import { doublizeNumber } from 'src/utils/formatDate'

import { getRoute } from 'src/api/rest/route'
import { notifyError, notifySuccess } from 'src/notification'

const RoutimoModal = ({ isActive, onClose }) => {
  const [isGeneratingRoutimo, setIsGenerativeRoutimo] = useState(false)
  const [location, setLocation] = useState(undefined)
  const [hour, setHour] = useState(undefined)
  const [date, setDate] = useState(moment())

  function getHours() {
    let hours = []

    for (let i = 1; i < 24; i++) {
      let hour = doublizeNumber(i) + ':00'
      hours.push({ text: hour, key: hour, value: i })
    }

    return hours
  }

  async function onGenerateRoutimo() {
    setIsGenerativeRoutimo(true)

    try {
      await getRoute(hour, date.format('DD-MM-YYYY'), location)
      notifySuccess('Wygenerowano trasy w Routific')
    } catch (error) {
      notifyError('Error generating routimo', 'Błąd generowania trasy.', error)
    }
    setIsGenerativeRoutimo(false)
  }

  return (
    <Modal
      title="Generacja tras w Routific"
      show={isActive}
      onHide={onClose}
      footer={[
        <Button
          disabled={isGeneratingRoutimo}
          loading={isGeneratingRoutimo}
          onClick={onGenerateRoutimo}
          primary
        >
          Wygeneruj trasy w Routific
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 500 }}>
        <div style={{ display: 'grid', gridGap: 10, gridAutoFlow: 'column' }}>
          <Dropdown
            placeholder="Lokalizacja"
            search
            onChange={(e, { value }) => setLocation(value)}
            value={location}
            selection
            options={[
              { text: 'Restauracja', key: 'restaurant', value: 'restaurant' },
              { text: 'Fabryka', key: 'factory', value: 'factory' },
            ]}
          />

          <Select
            onChange={(e, { value }) => setHour(value)}
            value={hour}
            placeholder="Wybierz godzinę"
            options={getHours()}
          />
        </div>

        <br />

        <SingleDatePicker
          focused
          firstDayOfWeek={1}
          numberOfMonths={1}
          date={moment(date)}
          displayFormat={() => 'DD/MM/YYYY'}
          onFocusChange={() => {}}
          onDateChange={setDate}
          isOutsideRange={(date) =>
            !date.isSameOrAfter(moment().startOf('day'))
          }
        />
      </div>
    </Modal>
  )
}

export default RoutimoModal
