
import { API } from 'aws-amplify'
import api, { apiName } from './constants'


export async function getSwapOptions() {
  try {
    return await API.get(apiName, `${api.swaps}`, {})
  } catch (error) {
    console.error('Something went wrong when getting special offers', error)
  }
}
