import Papa from 'papaparse'

export function exportRaportCSV(storageList, dateFrom, dateTo, fileName) {
  if (!storageList || storageList.length < 0) return

  const columns = [
    'ID',
    'Nazwa',
    'Ilość',
    'Jednostka',
    'Przeliczona_Ilość',
    'Przeliczona_Jednostka',
  ]

  const rows = []
  for (let i = 0; i < storageList.length; i++) {
    const data = getRowData(storageList[i])
    rows.push(data)
  }

  const data = {
    fields: columns,
    data: rows,
  }

  var csv = Papa.unparse(data, {
    delimiter: ';',
    newline: '\r\n',
  })
  let csvData = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' })
  var csvURL = window.URL.createObjectURL(csvData)

  const name = `${fileName}-${dateFrom}-${dateTo}`

  var tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${name}.csv`)
  tempLink.click()
}

function getRowData(row) {
  const { id, name, quantity, unit, new_quantity, new_unit } = row

  return new Array(id, name, quantity, unit, new_quantity, new_unit)
}
