import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'

import userIsAdmin from 'src/utils/isAdmin'
import emailIsVerified from 'src/utils/emailIsVerified'

export default function ({ handleLogout }) {
  const isVerified = emailIsVerified()
  const userData = useSelector((state) => state.auth.userData)

  useEffect(() => {
    const checkIfAdmin = async () => {
      await userIsAdmin(userData.username)
    }
    checkIfAdmin()
  }, [userData, isVerified])

  return (
    <Navbar
      style={{ marginBottom: '8px', paddingLeft: '15px' }}
      fluid
      collapseOnSelect
    >
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/home">PRZYTOMNI</Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>

      <Navbar.Collapse>
        {isVerified ? (
          <Nav pullRight>
            <NavItem>
              <p>Cześć, {userData.username}</p>
            </NavItem>

            <NavDropdown
              noCaret
              id="nav-dropdown"
              title={<FontAwesomeIcon icon={faCog} size="1x" />}
            >
              <LinkContainer to="/admin">
                <MenuItem> Menu </MenuItem>
              </LinkContainer>

              <LinkContainer to="/system">
                <MenuItem> System </MenuItem>
              </LinkContainer>
            </NavDropdown>

            <NavItem onClick={handleLogout}>
              <FontAwesomeIcon icon={faPowerOff} size="1x" />
            </NavItem>
          </Nav>
        ) : (
          <Nav pullRight>
            <NavItem>
              <Link to="/login"> Logowanie </Link>
            </NavItem>

            <NavItem>
              <Link to="/login/reset"> Reset hasła </Link>
            </NavItem>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  )
}
