import React, { useCallback, useState } from 'react'
import { Input } from 'semantic-ui-react'

import HistoryCard from './HistoryCard'
import { LoadingIcon } from 'src/components'

import { debounce } from 'src/utils'
import { getUserOrderHistory } from 'src/api/rest/order'

import * as classes from './UserHistory.module.scss'

export default function UserHistory() {
  const [orders, setOrder] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const DEBOUNCE_TIMEOUT = 500

  async function fetchOrder(phone) {
    if (phone && phone.trim()) {
      setIsLoading(true)
      const orders = await getUserOrderHistory(phone)
      setOrder(orders)
      setIsLoading(false)
    }
  }

  const debounceOnChange = useCallback(
    debounce(fetchOrder, DEBOUNCE_TIMEOUT),
    [],
  )

  return (
    <div className={classes.Container}>
      <Input
        fluid
        icon="search"
        iconPosition="left"
        placeholder="Wyszukaj zamówienia po numerze telefonu…"
        onChange={(e) => debounceOnChange(e.target.value)}
      />
      {isLoading && <LoadingIcon size={'small'} />}

      {Array.isArray(orders) && orders.length > 0 && (
        <div className={classes.HistoryContainer}>
          {orders.map((order) => (
            <HistoryCard key={order.id} {...order} />
          ))}
        </div>
      )}
    </div>
  )
}
