import React, { useState, useEffect } from 'react'
import OfferList from './OfferList'
import EditOffer from './EditOffer'
import { getOffers } from 'src/api/rest/carousel'
export default function SpecialOffer({ setActiveMenu }) {
  const [loading, setLoading] = useState(false)
  const [offerList, setOfferList] = useState([])
  const fetchOffers = async () => {
    setLoading(true)
    try {
      const data = await getOffers()
      setOfferList(data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchOffers()
  }, [])

  const [isEdittingOffer, setIsEdittingOffer] = useState(false)
  const [edittingData, setEdittingData] = useState()
  return (
    <div>
      {isEdittingOffer ? (
        <EditOffer
          setOfferList={setOfferList}
          edittingData={edittingData}
          isEdittingOffer={isEdittingOffer}
          setIsEdittingOffer={setIsEdittingOffer}
          setEdittingData={setEdittingData}
          setActiveMenu={setActiveMenu}
        />
      ) : (
        <OfferList
          loading={loading}
          setOfferList={setOfferList}
          offers={offerList}
          setActiveMenu={setActiveMenu}
          setIsEdittingOffer={setIsEdittingOffer}
          setEdittingData={setEdittingData}
        />
      )}
    </div>
  )
}
