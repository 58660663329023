import React from 'react'
import { Counter } from 'src/components'
import { Button } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'

import * as classes from './BottomBar.module.scss'
import {
  increaseCounter,
  decreaseCounter,
  reserveBWTBlock,
  releaseBWTBlock,
  setLoading,
} from 'src/redux/bwt/actions'

export default function BottomBar() {
  const dispatch = useDispatch()
  const { count, selectedIndexes } = useSelector((state) => state.bwt)
  const { isLoading } = useSelector((state) => state.order)
  const reservation = useSelector((state) => state.order.userData.reservation)
  const { numberOfBlocks } = reservation

  function onReserve() {
    dispatch(reserveBWTBlock())
  }

  function onReservationCancel() {
    dispatch(releaseBWTBlock())
  }

  return (
    <div className={classes.Container}>
      <div className={classes.Counter}>
        <h3>Ilość slotów</h3>
        <Counter
          inputDisabled
          counter={count}
          increaseCounter={() => dispatch(increaseCounter())}
          deacreaseCounter={() => dispatch(decreaseCounter())}
          isDisabled={selectedIndexes.length === 0 || !reservation.isEditable}
        />
      </div>

      <div className={classes.ButtonContainer}>
        <Button
          fluid
          onClick={onReservationCancel}
          disabled={
            !(reservation.isEditable && numberOfBlocks > 0) || isLoading
          }
        >
          Anuluj
        </Button>

        <Button
          primary
          fluid
          onClick={onReserve}
          disabled={
            count === 0 ||
            !reservation.isEditable ||
            selectedIndexes.length === 0 ||
            isLoading
          }
        >
          Rezerwuj
        </Button>
      </div>
    </div>
  )
}
