import {
  SET_COUNT,
  SET_CURRENT_PAST_ORDER_COUNT,
  SET_FUTURE_ORDERS,
  SET_FUTURE_PAGE,
  SET_LOADING,
  SET_LOCATION,
  SET_PAST_ORDERS,
  SET_PAST_PAGE,
} from './types'
import bwtLocations from 'src/constants/views/bwtLocations'

const initState = {
  pastOrders: [],
  futureOrders: [],
  isLoading: false,
  location: bwtLocations.factory,
  currentPastOrderCount: 100,
  totalPastOrdersCount: 0,
  futurePage: 1,
  pastPage: 1,
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [SET_FUTURE_ORDERS]: () => ({ ...state, futureOrders: payload }),

    [SET_PAST_ORDERS]: () => ({ ...state, pastOrders: payload }),

    [SET_LOADING]: () => ({ ...state, isLoading: payload }),

    [SET_COUNT]: () => ({ ...state, totalPastOrdersCount: payload }),

    [SET_LOCATION]: () => ({ ...state, location: payload }),

    [SET_CURRENT_PAST_ORDER_COUNT]: () => ({
      ...state,
      currentPastOrderCount: payload,
    }),

    [SET_PAST_PAGE]: () => ({ ...state, pastPage: payload }),

    [SET_FUTURE_PAGE]: () => ({ ...state, futurePage: payload }),
  }

  return cases[type] ? cases[type]() : state
}
