import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'
import styles from './Modal.module.scss'
import { deleteOffer } from 'src/api/rest/carousel'
import { notifyError } from 'src/notification'
export default function DeleteOfferModal({
  isModalOpen,
  setIsModalOpen,
  id,
  setOfferList,
}) {
  const handleDeleteOffer = async () => {
    try {
      await deleteOffer(id)
      setOfferList((prev) =>
        prev.filter((item) => {
          if (item.id !== id) {
            return item
          }
        }),
      )
    } catch (err) {
      notifyError('error', 'Usuwanie oferty nie powiodło się', err)
    }
    setIsModalOpen(false)
  }

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => setIsModalOpen(false)}
      className={styles.Modal}
      size="mini"
      open={isModalOpen}
      style={{ left: 'auto', top: 'auto' }}
    >
      <Modal.Content>
        <p>Czy napewno usunąć Ofertę</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setIsModalOpen(false)}>
          <Icon name="remove" /> Anuluj
        </Button>
        <Button primary onClick={() => handleDeleteOffer()}>
          <Icon name="checkmark" /> Usuń
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
