import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Row, Col } from "react-bootstrap"
import { Button } from "semantic-ui-react"
import { fetchProductsToMenuEdit } from "src/api/rest/product"
import classes from "../EditItem.module.scss"
import { fetchIngredientsToMenuEdit } from "src/api/rest/ingredient"

export default function EditProduct({
  product,
  onEdit,
  onDelete,
  ingredientsMenu,
}) {
  const { name, id, ingredients: ingredientIDs } = product
  // const allIngredients = useSelector((state) => state.menu.ingredients)
  // const [ingredientsMenu, setIngredientsMenu] = useState([])

  // useEffect(() => {
  //   fetchIngredients()
  // }, [])

  // const fetchIngredients = async () => {
  //   const responseIngr = await fetchIngredientsToMenuEdit()
  //   const responseProd = await fetchProductsToMenuEdit()
  //   setIngredientsMenu([
  //     ...responseIngr,
  //     ...responseProd.filter((prod) => {
  //       if (prod.category === 'Sosy') {
  //         return prod
  //       }
  //     }),
  //   ])
  // }

  const ingredients = ingredientIDs.map(
    ({ id }) => ingredientsMenu.find((ingredient) => id === ingredient.id),
    // allIngredients.find((ingredient) => id === ingredient.id),
  )
  return (
    <article className={classes.ItemContainer}>
      <Row className={classes.Description}>
        <Col xs={2}>
          <span>ID{id}</span>
        </Col>
        <Col xs={6}>
          <span> {name} </span>
        </Col>
        <Col xs={2} cellPadding={0}>
          <Button
            onClick={() => {
              onEdit(product)
              console.log({ product })
            }}
            primary
          >
            Edytuj
          </Button>
        </Col>
        <Col xs={2} cellPadding={0}>
          <Button onClick={() => onDelete(product)} color="red">
            Usuń
          </Button>
        </Col>
      </Row>
      <div className={classes.IngredientContainer}>
        {/* {!ingredients || !ingredients.length || !allIngredients.length ? ( */}
        {!ingredientsMenu || !ingredients.length || !ingredientsMenu.length ? (
          <div className={classes.Ingredient}>
            <p> Brak przypisanych składników </p>
          </div>
        ) : (
          <>
            {/* {ingredients.length > 0 &&
              ingredients.map((ingredient) => (
                <div key={ingredient.name} className={classes.Ingredient}>
                  {ingredient.name}
                </div>
              ))} */}
            {ingredients.length > 0 &&
              ingredients.map((ingredient) => (
                <div key={ingredient.name} className={classes.Ingredient}>
                  {ingredient.name}
                </div>
              ))}
          </>
        )}
      </div>
    </article>
  )
}
