// export default {
//   maki: "Maki",
//   nigiri: "Nigiri",
//   sashimi: "Sashimi",
//   zestaw: "Zestaw",
//   zupy: "Zupy",
//   desery: "Desery",
//   sushi: "Sushi",
//   przystawki: "Przystawki",
//   gratisy: "Gratisy",
//   sosy: "Sosy",
//   polprodukty: "Półprodukty",
//   dodatki: "Dodatki",
//   napojeBezalkoholowe: "Napoje bezalkoholowe",
//   napojeAlkoholowe: "Napoje alkoholowe",
//   daniaCieple: "Dania ciepłe",
// }

export default {
  zestaw: "Zestaw",
  sushi: "Sushi",
  przystawki: "Przystawki",
  sashimi: "Sashimi",
  sosy: "Sosy",
  dodatki: "Dodatki",
  desery: "Desery",
  daniaCieple: "Dania ciepłe",
  napojeBezalkoholowe: "Napoje bezalkoholowe",
  napojeAlkoholowe: "Napoje alkoholowe",
  nigiri: "Nigiri",
  polprodukty: "Półprodukty",
  zupy: "Zupy",
  gratisy: "Gratisy",
}
