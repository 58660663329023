import {
  SET_DELIVERY_PRICES,
  ADD_ITEM,
  CLEAR_ORDER,
  REMOVE_ITEM,
  SET_ORDER_ITEMS,
  SET_ORDER_ID,
  SET_ORDER_MODE,
  ADD_STATUS,
  SET_STATUS,
  CLEAR_STATUS,
  SET_NUMBER_OF_BLOCKS,
  SET_USER_DATA,
  SET_RESERVATION,
  CLEAR_RESERVATION,
  IS_LOADING,
  ADD_CANCELING_REASON,
  SET_DISCOUNT,
  SET_PLACES,
  SET_PAYMENT_DATE,
} from "./constant"
import {
  //  getDeliveryPrice
  orderModes,
} from "src/constants"
import { getTotalPrice } from "./actions"
import { priceCalculator } from "src/utils"
export * from "./actions"

export const initReservation = {
  hour: "",
  date: "",
  location: "",
  numberOfBlocks: 0,
  isEditable: true, // stands for if user can change the reserved number of blocks of the current BWT row
  isReserved: false, // stands for if the user can reserve or cancel blocks
}

export const initialUserData = {
  reservation: initReservation,
  nip: "",
  name: "",
  phone: "",
  email: "",
  clientComment: "",
  productionComment: "",
  address: "",
  paymentType: "",
  deliveryType: "",
  isPriority: false,
  boxValue: JSON.stringify([{ type: "B", count: 0, price: 0 }]),
  replacement: [],
}

const initState = {
  deliveryPrices: {
    zonea: 10.99,
    zoneb: 20,
  },
  id: "",
  discounts: "",
  items: [],
  status: [],
  userData: initialUserData,
  mode: orderModes.add,
  isLoading: false,
  itemsPrice: 0,
  deliveryPrice: 0,
  addedStatus: false,
  cancelingOrder: false,
  productionPlace: "",
  salePlace: "",
  paymentDate: "",
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  const cases = {
    [CLEAR_ORDER]: () => ({
      ...initState,
      deliveryPrices: state.deliveryPrices,
    }),

    [IS_LOADING]: () => ({ ...state, isLoading: payload }),

    [ADD_ITEM]: () => ({
      ...state,
      items: [...state.items, payload],
      itemsPrice: priceCalculator([...state.items, payload]),
    }),

    [SET_DISCOUNT]: () => ({ ...state, discounts: payload }),
    [SET_DELIVERY_PRICES]: () => ({
      ...state,
      deliveryPrices: { zonea: payload.zoneA, zoneb: payload.zoneB },
    }),
    [REMOVE_ITEM]: () => ({
      ...state,
      items: removeOrder(state.items, payload),
      itemsPrice: priceCalculator(removeOrder(state.items, payload)),
    }),

    [SET_ORDER_ID]: () => ({ ...state, id: payload }),

    [SET_ORDER_ITEMS]: () => ({
      ...state,
      items: payload,
      itemsPrice: getTotalPrice(
        payload,
        state.userData.deliveryType,
        0,
        state.userData.deliveryType == "Zone A"
          ? state.deliveryPrices.zonea
          : state.userData.deliveryType == "Zone B"
          ? state.deliveryPrices.zoneb
          : 0,
        state.userData.boxValue,
      ),
    }),

    [SET_ORDER_MODE]: () => ({ ...state, mode: payload }),

    //Status
    [CLEAR_STATUS]: () => ({ ...state, status: [] }),
    [SET_STATUS]: () => ({ ...state, status: payload }),
    [ADD_STATUS]: () => ({
      ...state,
      status: [payload, ...state.status],
      addedStatus: true,
    }),

    //Form
    [SET_USER_DATA]: () => {
      return {
        ...state,
        userData: { ...state.userData, ...payload },
        deliveryPrice: payload.deliveryType
          ? payload.deliveryType.toLowerCase().includes("zone a")
            ? state.deliveryPrices.zonea
            : payload.deliveryType.toLowerCase().includes("zone b")
            ? state.deliveryPrices.zoneb
            : 0
          : state.deliveryPrice,
      }
    },

    [SET_PLACES]: () => ({
      ...state,
      productionPlace: payload.productionPlace,
      salePlace: payload.salePlace,
    }),

    [SET_PAYMENT_DATE]: () => ({
      ...state,
      paymentDate: payload.date,
    }),

    [SET_NUMBER_OF_BLOCKS]: () => ({
      ...state,
      userData: {
        ...state.userData,
        reservation: {
          ...state.userData.reservation,
          numberOfBlocks: payload,
        },
      },
    }),

    [SET_RESERVATION]: () => ({
      ...state,
      userData: {
        ...state.userData,
        reservation: payload,
      },
    }),

    [CLEAR_RESERVATION]: () => ({
      ...state,
      userData: {
        ...state.userData,
        reservation: initReservation,
      },
    }),

    [ADD_CANCELING_REASON]: () => {
      const newClientComment =
        state.userData.clientComment + "\nANULOWANE: " + payload

      const newProductionComment =
        state.userData.productionComment + "\nANULOWANE: " + payload

      return {
        ...state,
        userData: {
          ...state.userData,
          clientComment: newClientComment,
          productionComment: newProductionComment,
        },
      }
    },
  }

  return cases[type] ? cases[type]() : state
}

// helper functions
function removeOrder(orders, index) {
  if (orders.length <= index) return orders
  return [...orders.slice(0, index), ...orders.slice(index + 1, orders.length)]
}
