//@ts-check
import api, { apiName } from './constants'
import { API } from 'aws-amplify'

export async function addUserToAdminGroup(username) {
  try {
    const request = {
      body: { group: 'admins' },
    }
    return await API.put(apiName, `${api.user}/group/add/${username}`, request)
  } catch (error) {
    throw error
  }
}

export async function removeUserFromAdminGroup(username) {
  try {
    const params = {
      body: { group: 'admins' },
    }
    return await API.put(
      apiName,
      `${api.user}/group/remove/${username}`,
      params,
    )
  } catch (error) {
    throw error
  }
}

export async function getUsersList() {
  try {
    return await API.get(apiName, `${api.user}`, {})
  } catch (error) {
    throw error
  }
}

export async function deleteUser(username) {
  try {
    return await API.del(apiName, `${api.user}/${username}`, {})
  } catch (error) {
    throw error
  }
}
