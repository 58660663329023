import { getSwapOptions } from "src/api/rest/swaps"
import { notifyError } from "src/notification/index"

const SET_SWAPS = "swaps/SET_SWAPS"

const initState = {}

export default function reducer(state = initState, action) {
  const { type, payload } = action
  const cases = {
    [SET_SWAPS]: () => [
      {
        value: JSON.stringify({ originalId: null, newId: null }),
        label: "Brak",
      },
      ...payload.map((_) => ({
        value: JSON.stringify({
          originalId: _.originalId,
          newId: _.newId,
          originalName: _.originalName,
          newName: _.newName,
        }),
        label: _.originalName ? _.originalName + " -> " + _.newName : _.newName,
      })),
    ],
  }

  return cases[type] ? cases[type]() : state
}

//Actions

export const setSwaps = (_) => ({
  type: SET_SWAPS,
  payload: _,
})
export function getSwaps() {
  return async (dispatch) => {
    try {
      const swaps = await getSwapOptions()
      console.log(swaps.replacement)
      dispatch(setSwaps(swaps.replacement))
    } catch (error) {
      notifyError("Error fetching sets", "Error fetching sets", error)
    }
  }
}
