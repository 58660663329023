import React from 'react'
import * as classes from './Start.module.scss'

export default () => (
  <div className={classes.Lander}>
    <h1>PRZYTOMNI</h1>
    <p>Panel pracownika do pracy z DYSZA</p>
    <small className={classes.BottomVersion}>v 1.0.4</small>
  </div>
)
