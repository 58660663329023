import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Container } from 'semantic-ui-react'
import withAdminAccess from 'src/utils/withAdminAccess'
import { useDispatch } from 'react-redux'
import UserList from './User'
import SpecialOffer from './SpecialOffer'
import SystemMenu from './Menu'
import { fetchDeliveryPrices } from 'src/redux/order/reducer'
function SystemPanel() {
  const [activeMenu, setActiveMenu] = useState('Menu')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchDeliveryPrices())
  })
  return (
    <Container fluid>
      {activeMenu === 'Offer' ? (
        <SpecialOffer setActiveMenu={setActiveMenu} />
      ) : (
        <></>
      )}
      {activeMenu === 'Users' ? (
        <Row>
          <Col xs={8}>
            <UserList setActiveMenu={setActiveMenu} />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {activeMenu === 'Menu' ? (
        <SystemMenu setActiveMenu={setActiveMenu} />
      ) : (
        <></>
      )}
    </Container>
  )
}
export default withAdminAccess(SystemPanel)
