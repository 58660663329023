import React, { useState } from 'react'
import BWT from './BWT'
import MenuList from './MenuList'
import { UnderlineNav } from 'src/components'

import { orderViews } from './constants'

export default function () {
  const [activeTab, setActiveTab] = useState(orderViews.Menu)

  function Tab({ activeTab }) {
    const options = {
      [orderViews.BWT]: <BWT />,
      [orderViews.Menu]: <MenuList />,
    }
    return options[activeTab]
  }

  return (
    <>
      <UnderlineNav
        tabs={[
          { title: 'Menu', value: orderViews.Menu },
          { title: 'Baza Terminów', value: orderViews.BWT },
        ]}
        activeTab={activeTab}
        setWindow={setActiveTab}
      />
      <Tab activeTab={activeTab} />
    </>
  )
}
