import React from 'react'
import { Grid, Col } from 'react-bootstrap'

import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'

import classes from './Home.module.scss'

export default function Home() {
  return (
    <Grid className={classes.Layout}>
      <Col xs={6}>
        <LeftPanel />
      </Col>
      <Col xs={6}>
        <RightPanel />
      </Col>
    </Grid>
  )
}
