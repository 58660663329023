import React, { useState, useEffect } from 'react'
import { Input, Modal } from 'src/components'
import { Button, Checkbox } from 'semantic-ui-react'
import { getWelcomeText, setWelcomeText } from 'src/api/rest/administration'

const GreetingsModal = ({ isActive, onClose, onHide }) => {
  const [welcomeMessage, setWelcomeMessage] = useState('')
  const [currentWelcomeMessage, setCurrentWelcomeMessage] = useState('')
  const [isWelcomeMessageVisible, setIsWelcomeMessageVisible] = useState(false)
  const [isOrderingActive, setIsOrderingActive] = useState(false)

  useEffect(() => {
    fetchCurrentMessage()
  }, [])

  async function fetchCurrentMessage() {
    const message = await getWelcomeText()
    console.log(message)
    setCurrentWelcomeMessage(message.text)
    setIsWelcomeMessageVisible(message.isVisible)
    setIsOrderingActive(!message.isSystemClose)
  }

  function handleChange(e) {
    setWelcomeMessage(e.currentTarget.value)
  }

  async function welcomeText(message) {
    try {
      setWelcomeText(message, isWelcomeMessageVisible, !isOrderingActive)
      onHide()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      title="Ustawianie wiadomości powitalnej na stronie psushi.pl"
      show={isActive}
      onHide={onClose}
      description={
        <>
          <Input
            name="welcome"
            placeholder={`Aktualny komunikat na stronie: ${currentWelcomeMessage}`}
            onChange={handleChange}
            value={welcomeMessage}
          />
          <div>
            <Checkbox
              onClick={() =>
                setIsWelcomeMessageVisible(!isWelcomeMessageVisible)
              }
              checked={isWelcomeMessageVisible}
              toggle
              label={
                isWelcomeMessageVisible
                  ? 'Wiadomość Powitalna włączona'
                  : 'Wiadomość Powitalna wyłączona'
              }
            />
          </div>
          <div>
            <Checkbox
              onClick={() => setIsOrderingActive(!isOrderingActive)}
              checked={isOrderingActive}
              toggle
              label={
                isOrderingActive
                  ? 'Zamawianie włączone'
                  : 'Zamawianie wyłączone'
              }
            />
          </div>
        </>
      }
      footer={[
        <Button onClick={() => welcomeText(welcomeMessage)} primary>
          Ustaw wiadomość powitalną
        </Button>,
      ]}
    ></Modal>
  )
}

export default GreetingsModal
