import React, { useState, useEffect } from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { Form, Dropdown, Radio, Button } from 'semantic-ui-react'
import BcStyles from '../Breadcrumb.module.scss'
import ProductCard from '../ProductCard'
import classes from './EditForm.module.scss'
import { addOffer } from 'src/api/rest/carousel'
import { getOffers } from 'src/api/rest/carousel'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import moment from 'moment'
import { notifyError, notifySuccess } from 'src/notification'
import CollisionModal from 'src/components/CollisionModal'

export default function EditForm({
  setIsEdittingOffer,
  setEdittingData,
  setActiveMenu,
  activeProducts,
  setActiveProducts,
  edittingData,
  setOfferList,
}) {
  const [editMode, setEditMode] = useState()
  const [offerName, setOfferName] = useState()
  const [dayArray, setDayArray] = useState()
  const [repeating, setRepeating] = useState('notrepeating')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dates, setDates] = useState([new Date(), new Date()])
  const [modalOpen, setModalOpen] = useState(false)
  const [nameList, setNameList] = useState([])
  useEffect(() => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }, [dates])

  useEffect(() => {
    if (edittingData.hasOwnProperty('name')) {
      setEditMode(true)
      setActiveProducts([...edittingData.menu])
      setDayArray([...edittingData.cycle])
      setOfferName(edittingData.name)
      setStartDate(new Date(edittingData.dateStart))
      setEndDate(new Date(edittingData.dateEnd))
      setDates([
        new Date(edittingData.dateStart),
        new Date(edittingData.dateEnd),
      ])
      if (edittingData.cycle.length == 0) {
        setRepeating('notrepeating')
      } else {
        setRepeating('repeating')
      }
    } else {
      setEditMode(false)
    }
  }, [])
  const handleGoBackToMenu = () => {
    setEdittingData({})
    setActiveMenu('Menu')
    setIsEdittingOffer(false)
  }
  const handleGoBackToOfferList = () => {
    setEdittingData({})
    setIsEdittingOffer(false)
  }
  const handleCancel = () => {
    setEdittingData({})
    setIsEdittingOffer(false)
  }
  const handleSubmit = async () => {
    let menu = activeProducts.map((item) => item.id)
    let cycle = []
    if (repeating === 'repeating') {
      cycle = dayArray
    } else {
      cycle = []
    }
    if (offerName) {
      if (activeProducts.length !== 0) {
        if (editMode) {
          try {
            await addOffer(
              edittingData.id,
              offerName,
              moment(startDate).format('DD-MM-YYYY'),
              moment(endDate).format('DD-MM-YYYY'),
              cycle,
              menu,
            )
            const data = await getOffers()
            setOfferList(data)
            setEdittingData({})
            notifySuccess('Pomyślnie Edytowano Ofertę')
          } catch (error) {
            const { msg, name } = error.response.data
            if (msg === 'Collision') {
              setModalOpen(true)
              setNameList([...name])
            } else {
              notifyError('error', 'Edycja Oferty nie powiodło się', error)
            }
          }
        } else {
          try {
            await addOffer(
              '',
              offerName,
              moment(startDate).format('DD-MM-YYYY'),
              moment(endDate).format('DD-MM-YYYY'),
              cycle,
              menu,
            )
            const data = await getOffers()
            setOfferList(data)
            setEdittingData({})
            notifySuccess('Pomyślnie dodano ofertę')
            setOfferName('')
            setDayArray([])
            setDates([new Date(), new Date()])
            setActiveProducts([])
          } catch (error) {
            const { msg, name } = error.response.data
            if (msg === 'Collision') {
              setModalOpen(true)
              setNameList([...name])
            } else {
              notifyError('error', 'Dodanie Oferty nie powiodło się', error)
            }
          }
        }
      } else {
        notifyError('errror', 'Oferta musi zawierać produkty', 'error')
      }
    } else {
      notifyError('error', 'Nazwa oferty nie może być pusta', 'error')
    }
  }

  const options = [
    {
      key: 'Poniedziałek',
      text: 'Poniedziałek',
      value: 0,
    },
    {
      key: 'Wtorek',
      text: 'Wtorek',
      value: 1,
    },
    {
      key: 'Środa',
      text: 'Środa',
      value: 2,
    },
    {
      key: 'Czwartek',
      text: 'Czwartek',
      value: 3,
    },
    {
      key: 'Piątek',
      text: 'Piątek',
      value: 4,
    },
    {
      key: 'Sobota',
      text: 'Sobota',
      value: 5,
    },
    {
      key: 'Niedziela',
      text: 'Niedziela',
      value: 6,
    },
  ]
  const productsToRender = activeProducts.map((product, index) => (
    <ProductCard
      index={index}
      name={product.name}
      id={product.id}
      price={product.price}
      setActiveProducts={setActiveProducts}
    />
  ))
  return (
    <div>
      <Breadcrumb className={BcStyles.Breadcrumb}>
        <Breadcrumb.Section link onClick={() => handleGoBackToMenu()}>
          Menu Administracyjne
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section link onClick={() => handleGoBackToOfferList()}>
          Oferty specjalne
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section link>
          {editMode ? 'Szczegóły oferty' : 'Nowa Oferta'}
        </Breadcrumb.Section>
      </Breadcrumb>
      <div>
        <h2 className={classes.Header}>Informacje</h2>
        <Form>
          <Form.Group className={classes.FormGroup}>
            <Form.Input
              label="Nazwa oferty"
              width={8}
              onChange={(e) => setOfferName(e.target.value)}
              value={offerName}
            />

            <Form.Input
              label="Zakres dat"
              width={8}
              className={classes.DatePickerWrapper}
            >
              <DateRangePicker
                className={classes.DatePicker}
                onChange={setDates}
                value={dates}
                locale="pl-PL"
                format="dd\MM\y"
              />
            </Form.Input>
          </Form.Group>
          <Form.Group className={classes.FormGroup}>
            <Form.Input label="Cykliczność" width={8}>
              <Form.Field witdh={8}>
                <Radio
                  label="Jednorazowo"
                  name="radioGroup"
                  value="notrepeating"
                  onChange={(e, { value }) => setRepeating(value)}
                  checked={repeating === 'notrepeating'}
                />
              </Form.Field>
              <Form.Field width={8}>
                <Radio
                  label="Co tydzień"
                  name="radioGroup"
                  value="repeating"
                  onChange={(e, { value }) => setRepeating(value)}
                  checked={repeating === 'repeating'}
                />
              </Form.Field>
            </Form.Input>
            <Form.Input label="Wybierz dni oferty" width={8}>
              <Dropdown
                disabled={repeating === 'notrepeating'}
                fluid
                multiple
                selection
                options={options}
                onChange={(e, data) => {
                  setDayArray(data.value)
                }}
                value={repeating === 'notrepeating' ? [] : dayArray}
              />
            </Form.Input>
          </Form.Group>
        </Form>
        <h2 className={classes.SecondHeader}>Produkty</h2>
        <div>{productsToRender}</div>
        <div className={classes.BtnContainer}>
          <Button
            primary
            className={classes.Btn}
            onClick={() => handleSubmit()}
          >
            {editMode ? 'Edytuj' : 'Dodaj'}
          </Button>
          <Button className={classes.Btn} onClick={() => handleCancel()}>
            Anuluj
          </Button>
        </div>
      </div>
      <CollisionModal
        nameList={nameList}
        isModalOpen={modalOpen}
        setIsModalOpen={setModalOpen}
      />
    </div>
  )
}
