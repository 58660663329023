import React, { useState, useEffect } from "react"
import { Input, Modal } from "src/components"
import { Button } from "semantic-ui-react"
import { useSelector, useDispatch } from "react-redux"
import { fetchDeliveryPrices } from "src/redux/order/reducer"
import { notifyError, notifySuccess } from "src/notification"
import { updateDeliveryPrices } from "src/api/rest/deliveryPrices"
import { putBox } from "src/api/rest/box"
import { getBoxPrices } from "src/redux/box"
const DeliveryPricesModal = ({ isActive, onClose, onHide }) => {
  const dispatch = useDispatch()
  const { S, M, L } = useSelector((state) => state.box)
  const [boxS, setBoxS] = useState(0)
  const [boxM, setBoxM] = useState(0)
  const [boxL, setBoxL] = useState(0)
  useEffect(() => {
    setBoxS(S)
    setBoxM(M)
    setBoxL(L)
  }, [S, M, L])
  const handleBoxPriceChange = async () => {
    try {
      //Api call
      await putBox({ S: boxS, M: boxM, L: boxL })
      dispatch(getBoxPrices())
      notifySuccess("Pomyślnie zmieniono ceny zestawów pakujących")
      onClose()
    } catch (err) {
      notifyError("Error", "Zmiana cen nie powiodła się", err)
    }
  }
  return (
    <Modal
      title="Ceny Zestawów pakujących"
      show={isActive}
      onHide={onClose}
      description={
        <>
          <label htmlFor="S">Mały</label>
          <Input
            name="S"
            onChange={(e) => setBoxS(e.target.value)}
            value={boxS}
          />
          <label htmlFor="M">Średni</label>
          <Input
            name="M"
            onChange={(e) => setBoxM(e.target.value)}
            value={boxM}
          />
          <label htmlFor="M">Duży</label>
          <Input
            name="L"
            onChange={(e) => setBoxL(e.target.value)}
            value={boxL}
          />
        </>
      }
      footer={[
        <Button onClick={() => handleBoxPriceChange()} primary>
          Zatwierdż
        </Button>,
      ]}
    ></Modal>
  )
}

export default DeliveryPricesModal
