import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FormControl,
  HelpBlock,
  FormGroup,
  ControlLabel,
} from "react-bootstrap"

import classes from "./Select.module.scss"

export default function Select({
  label,
  options,
  error,
  placeholder,
  selectedOption,
  icon,
  help,
  ...rest
}) {
  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      <div className={classes.FormGroup}>
        <FormControl
          className={`${classes.Dropdown} ${error && classes.Error}`}
          componentClass="select"
          {...rest}
        >
          <option selected={!selectedOption} disabled>
            {placeholder}
          </option>

          {options.map(({ value, label, disabled }) => {
            return (
              <option
                key={value}
                value={value}
                selected={selectedOption === value}
                disabled={disabled}
              >
                {label}
              </option>
            )
          })}
        </FormControl>
        {icon && <FontAwesomeIcon className={classes.Icon} icon={icon} />}
      </div>
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  )
}
