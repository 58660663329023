//@ts-check
import { API } from "aws-amplify"
import api, { apiName } from "./constants"

export async function fetchSets() {
  try {
    return await API.get(apiName, api.sushiSetMenu, {})
  } catch (error) {
    throw error
  }
}

export async function fetchSetsToMenuEdit() {
  try {
    return await API.get(apiName, api.sushiSetMenu, {})
  } catch (error) {
    throw error
  }
}

export async function postSet(set) {
  try {
    let request = {
      body: {
        name: set.name,
        price: set.price,
        products: set.products,
        category: set.category,
        isTakeAway: set.isTakeAway,
        isRestaurant: set.isRestaurant,
        numberOfPieces: set.numberOfPieces,
        numOfExtraPieces: set.numOfExtraPieces,
        imageURL: set.imageURL || "",
        type: set.type,
        isPin: set.isPin,
        description: set.description,
      },
    }
    return await API.post(apiName, api.sushiSetMenu, request)
    // return await API.post(apiName, api.sushiSet, request)
  } catch (error) {
    throw error
  }
}

export async function putSet(set, id) {
  try {
    const request = {
      body: {
        name: set.name,
        price: set.price,
        products: set.products,
        category: set.category,
        isTakeAway: set.isTakeAway,
        isRestaurant: set.isRestaurant,
        numberOfPieces: set.numberOfPieces,
        numOfExtraPieces: set.numOfExtraPieces,
        imageURL: set.imageURL || "",
        type: set.type,
        isPin: set.isPin,
        description: set.description,
      },
    }
    return await API.put(apiName, `${api.sushiSetMenu}/${id}`, request)
    // return await API.put(apiName, `${api.sushiSet}/${id}`, request)
  } catch (error) {
    throw error
  }
}

export async function deleteSet(id, approve) {
  try {
    const request = {
      body: { approve },
    }
    return await API.del(apiName, `${api.sushiSetMenu}/${id}`, request)
    // return await API.del(apiName, `${api.sushiSet}/${id}`, request)
  } catch (error) {
    throw error
  }
}
