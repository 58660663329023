import React from 'react'
import { Icon, Pagination, Table, Header } from 'semantic-ui-react'
import formatDate from 'src/utils/formatDate'
import classes from './OrdersHistory.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { setPastPage } from 'src/redux/ordersHistory/actions'
import { getRowClassName } from 'src/pages/Home/Helpers/index'
import { Input } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'

const PastOrder = ({
  orders,
  getStatus,
  onSinglePrint,
  editOrder,
  fetchMorePastHistory,
  searchWord,
  pastSearch,
  onPastSearch,
  searchDatabase
}) => {
  const TOTAL_PER_PAGE = 100
  const totalPages = Math.ceil(orders.length / TOTAL_PER_PAGE)
  const { pastPage: page } = useSelector((state) => state.ordersHistory)
  const dispatch = useDispatch()

  const getOrdersOfPage = (page, orders) => {
    if (searchWord.length > 0) {
      page = 1
    }

    const start = (page - 1) * TOTAL_PER_PAGE
    const end = start + TOTAL_PER_PAGE
    return orders.slice(start, end)
  }

  function onChangePage(newPage) {
    if (newPage === totalPages) {
      fetchMorePastHistory()
    }
    dispatch(setPastPage(newPage))
  }

  return (
    <>
      <Header as="h4">Zakończone</Header>

      <div className={classes.ActionBackendWrapper}>
        <Input
          fluid
          style={{ width: '70%' }}
          icon="users"
          iconPosition="left"
          placeholder="Szukaj..."
          size="small"
          onChange={(e, { value }) => onPastSearch(value)}
        />
        <div >
          <Button onClick={() => searchDatabase(pastSearch)} primary>Przeszukaj całą bazę</Button>
        </div>
      </div>


      <Table fixed className={classes.Table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={classes.IdItem}>ID </Table.HeaderCell>
            <Table.HeaderCell>Telefon</Table.HeaderCell>
            <Table.HeaderCell colSpan={2} className={classes.DataItem}>
              Data
            </Table.HeaderCell>
            <Table.HeaderCell>Kwota</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Wielkość</Table.HeaderCell>
            <Table.HeaderCell>Akcje</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orders &&
            getOrdersOfPage(page, orders).map((order) => (
              <Table.Row key={order.id} className={getRowClassName(order.status)}>
                <Table.Cell className={classes.IdItem}>{order.id}</Table.Cell>
                <Table.Cell>{order.clientPhone}</Table.Cell>
                <Table.Cell colSpan={2} className={classes.DataItem}>
                  {formatDate(new Date(order.BWT.date))}{' '}
                  {order.BWT.selectedHour}:00
                </Table.Cell>
                <Table.Cell>{order.price}zł</Table.Cell>
                <Table.Cell>{getStatus(order.status)}</Table.Cell>
                <Table.Cell>
                  {order.BWT.numberOfBlocks}
                  {order.BWT.numberOfBlocks > 1 ? 'sloty' : 'slot'}
                  {order.priority && <Icon name="lightning" />}
                </Table.Cell>
                <Table.Cell>
                  <Icon
                    className={classes.ActionIcon}
                    onClick={() => onSinglePrint(order.id)}
                    name="print"
                  />

                  <Icon
                    onClick={() => editOrder(order)}
                    className={classes.ActionIcon}
                    name="edit"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>

        {totalPages > 1 && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Pagination
                  size="mini"
                  boundaryRange={0}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  activePage={page}
                  totalPages={totalPages}
                  onPageChange={(e, { activePage }) => onChangePage(activePage)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </>
  )
}

export default PastOrder
