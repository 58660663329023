import { getBox } from "src/api/rest/box"
import { notifyError } from "src/notification/index"

const SET_BOX_PRICES = "box/SET_PRICE"

const initState = {
  S: 0,
  M: 0,
  L: 0,
}

export default function reducer(state = initState, action) {
  const { type, payload } = action
  const cases = {
    [SET_BOX_PRICES]: () => ({
      S: Number(payload.S),
      M: Number(payload.M),
      L: Number(payload.L),
    }),
  }

  return cases[type] ? cases[type]() : state
}

//Actions
export const setBoxPrices = (boxPrices) => ({
  type: SET_BOX_PRICES,
  payload: boxPrices,
})

export function getBoxPrices() {
  return async (dispatch) => {
    try {
      const boxPrices = await getBox()
      console.log(boxPrices)
      dispatch(setBoxPrices(boxPrices))
    } catch (error) {
      notifyError("Error fetching sets", "Error fetching sets", error)
    }
  }
}
