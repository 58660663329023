import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import {
  HelpBlock,
  FormGroup,
  Glyphicon,
  FormControl,
  ControlLabel,
} from 'react-bootstrap'
import classes from './ResetPassword.module.scss'
import { notifyError } from 'src/notification'

export default function ResetPassword() {
  const [code, setCode] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)

  function validateCodeForm() {
    return email.length > 0
  }

  function validateResetForm() {
    return (
      code.length > 0 && password.length > 0 && password === confirmPassword
    )
  }

  async function handleSendCodeClick(e) {
    e.preventDefault()
    setIsSendingCode(true)
    try {
      await Auth.forgotPassword(email)
      setCodeSent(true)
    } catch (error) {
      notifyError(
        'Error sending code',
        'Coś poszło nie tak podczas weryfikacji kodu.',
        error,
      )
      setIsSendingCode(false)
    }
  }

  async function handleConfirmClick(e) {
    e.preventDefault()
    setIsConfirming(true)
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      setConfirmed(true)
    } catch (error) {
      notifyError(error.message)
      setIsConfirming(false)
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick}>
        <FormGroup bsSize="large" controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </FormGroup>
        <Button
          primary
          fluid
          type="submit"
          loading={isSendingCode}
          disabled={!validateCodeForm()}
        >
          Prześlij link do resetun
        </Button>
      </form>
    )
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <FormGroup bsSize="large" controlId="code">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={code}
            onChange={(e) => setCode(e.currentTarget.value)}
          />

          <HelpBlock>
            Please check your email ({email}) for the confirmation code.
          </HelpBlock>
        </FormGroup>
        <hr />
        <FormGroup bsSize="large" controlId="password">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </FormGroup>

        <FormGroup bsSize="large" controlId="confirmPassword">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            value={confirmPassword}
          />
        </FormGroup>

        <Button
          primary
          fluid
          type="submit"
          loading={isConfirming}
          disabled={!validateResetForm()}
        >
          Potwierdzać
        </Button>
      </form>
    )
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <Glyphicon glyph="ok" />
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    )
  }

  return (
    <div className={classes.ResetPassword}>
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  )
}
