import React from 'react'
import { Link } from 'react-scroll'
import classes from './Menubar.module.scss'

export default function Menubar({ categories }) {
  return (
    <div className={classes.Menubar}>
      {categories &&
        categories.map((category, index) => (
          <ContainerLink
            key={`menu-item-${index}`}
            to={category}
            label={category}
          />
        ))}
    </div>
  )
}

const ContainerLink = ({ to, label }) => (
  <Link
    to={to}
    spy={true}
    offset={-15}
    smooth={true}
    duration={500}
    containerId="itemsContainer"
    activeClass={classes.activeCategory}
  >
    {getTranslation(label)}
  </Link>
)

function getTranslation(label) {
  const translations = {
    Beverages: 'Napoje',
    Addons: 'Dodatki',
  }

  return translations[label] || label
}
