//@ts-check
import { Auth } from 'aws-amplify'

export async function signUp(username, password, email) {
  try {
    return await Auth.signUp({ username, password, attributes: { email } })
  } catch (error) {
    throw error.message
  }
}

export async function signIn(username, password) {
  try {
    return await Auth.signIn(username, password)
  } catch (error) {
    throw error.message
  }
}

export async function currentAuthenticatedUser() {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch (error) {
    throw error.message
  }
}
