import React, { useState } from 'react'
import Switch from 'react-switch'
import { Modal } from 'src/components'
import { printStatus } from './constants'
import { Button } from 'semantic-ui-react'
import { ReactComponent as ChefIcon } from 'src/img/chef.svg'
import { ReactComponent as UserIcon } from 'src/img/user.svg'

import * as classes from './OrdersHistory.module.scss'
import { notifySuccess, notifyError } from 'src/notification'

import {
  printSingleOrderDelivery,
  printSingleOrderProduction,
} from 'src/api/rest/printer'

const GroupPrint = ({ orderId, onClose }) => {
  const [printMode, setPrintMode] = useState(printStatus.production)
  const [isLoading, setIsLoading] = useState(false)
  const isActive = (status) => (status !== printMode ? classes.active : '')

  function onChangePrintMode() {
    printMode === printStatus.production
      ? setPrintMode(printStatus.delivery)
      : setPrintMode(printStatus.production)
  }

  async function onPrint() {
    setIsLoading(true)

    try {
      printMode === printStatus.production
        ? await printSingleOrderProduction(orderId)
        : await printSingleOrderDelivery(orderId)

      notifySuccess('Pomyślnie wydrukowano.')
    } catch (error) {
      notifyError(
        'Error on print',
        'Coś poszło nie tak z wydrukiem zamówienia',
        error,
      )
    }

    setIsLoading(false)
  }

  return (
    <Modal
      show={true}
      title="Wydruk pojedynczego zamówieńia"
      footer={
        <Button loading={isLoading} primary onClick={onPrint}>
          Drukuj
        </Button>
      }
      onHide={onClose}
    >
      <p>
        Wybierz rodzaj wydruku. Zamówienie zostanie wydrukowane ty jeśli keho
        status to "Potwierdzone"
      </p>

      <div className={classes.Switch}>
        <div className={`${classes.icon} ${isActive(printStatus.production)}`}>
          Wydruk dla klienta <UserIcon />
        </div>

        <Switch
          checkedIcon={false}
          onColor={'#46b8da'}
          offColor={'#46b8da'}
          uncheckedIcon={false}
          onChange={onChangePrintMode}
          checked={printStatus.production === printMode}
        />

        <div className={`${classes.icon} ${isActive(printStatus.delivery)} `}>
          <ChefIcon /> Wydruk dla kuchni
        </div>
      </div>
    </Modal>
  )
}

export default GroupPrint
