export const orderViews = {
  newOrder: 'NewOrder',
  userHistory: 'UserHistory',
  orderHistory: 'OrderHistory',
  shopOrders: 'ShopOrders',
}

export const tableViews = {
  custom: 'Custom',
  default: 'Default',
}
