//@ts-check
import api, { apiName } from './constants'
import { API } from 'aws-amplify'

/**
 * Check if the system is off or on for the entered date.
 * @param {Date} id - ID of the order to be printed
 */
export async function printSingleOrderProduction(id) {
  try {
    return await API.get(apiName, `${api.singlePrintProduction}/${id}`, {})
  } catch (error) {
    throw error
  }
}

export async function printSingleOrderDelivery(id) {
  try {
    return await API.get(apiName, `${api.singlePrintDelivery}/${id}`, {})
  } catch (error) {
    throw error
  }
}

/**
 * Print the single hour of orders for production.
 * @param {Date} hour - Start hour of the order to be printed
 * @param {Date} date - The date of the orders to be printed
 * @param {Date} location - Location of the orders to be printed
 */
export async function printSingleHourOrdersProduction(hour, date, location) {
  try {
    const request = {
      body: { hour, date, location },
    }
    return await API.post(apiName, `${api.hourPrintProduction}`, request)
  } catch (error) {
    throw error
  }
}

/**
 * Print the single hour of orders for delivery.
 * @param {Date} hour - Start hour of the order to be printed
 * @param {Date} date - The date of the orders to be printed
 * @param {Date} location - Location of the orders to be printed
 */
export async function printSingleHourOrdersDelivery(hour, date, location) {
  try {
    const request = {
      body: { hour, date, location },
    }
    return await API.post(apiName, `${api.hourPrintDelivery}`, request)
  } catch (error) {
    throw error
  }
}
